import { USER } from '../constants/actions';
import { userService } from '../services/users';


export const fetchUsers = options => dispatch => {
    return new Promise((resolve, reject) => {
        userService
            .getUsers(options)
            .then(response => {
                dispatch({
                    type: USER.FETCH_USER_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {});
    });
};

export const createUser = payload => dispatch => {
    return new Promise((resolve, reject) => {
        userService
            .createUser(payload)
            .then(response => {
                dispatch({
                    type: USER.CREATE_USER_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {});
    });
};

export const updateUser = payload => dispatch => {
    return new Promise((resolve, reject) => {
        userService
            .updateUser(payload)
            .then(response => {
                dispatch({
                    type: USER.UPDATE_USER_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {});
    });
};
