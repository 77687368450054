import React from "react";
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";

export default class ServiceLine extends React.Component {
    static propTypes = {
        lineNumber: PropTypes.string.isRequired,
        serviceDate: PropTypes.string.isRequired,
        serviceDateEnd: PropTypes.string.isRequired,
        cpt: PropTypes.string.isRequired,
        mod1: PropTypes.string.isRequired,
        mod2: PropTypes.string.isRequired,
        mod3: PropTypes.string.isRequired,
        mod4: PropTypes.string.isRequired,
        units: PropTypes.string.isRequired,
        total: PropTypes.string.isRequired,
        discount: PropTypes.string.isRequired,
        cptDesc: PropTypes.string.isRequired,
        pod: PropTypes.string.isRequired,
        revenueCode: PropTypes.string.isRequired,
        removeServiceLine: PropTypes.func.isRequired,
        setServiceLineAttribute: PropTypes.func.isRequired,
    };

    render() {
        const { lineNumber, setServiceLineAttribute } = this.props;
        return (
            <>
                <div className="col-md-2 mrgn-btm10">
                    <label>Service Date Start</label>
                    <br/>
                    <DatePicker
                        isClearable
                        selected={this.props.serviceDate}
                        onChange={date =>  setServiceLineAttribute(lineNumber, 'line_start_date', date)}
                        className="date-picker"
                    />
                </div>
                <div className="col-md-2 mrgn-btm10">
                    <label>Service Date End</label>
                    <br/>
                    <DatePicker
                        isClearable
                        selected={this.props.serviceDateEnd}
                        onChange={date =>  setServiceLineAttribute(lineNumber, 'line_end_date', date)}
                        className="date-picker"
                    />
                </div>
                <div className="col-md-2 mrgn-btm10">
                    <label>CPT</label>
                    <input
                        onChange={event => setServiceLineAttribute(lineNumber, 'hcpcs_code', event.target.value)}
                        type="text"
                        className="code-input"
                        value={this.props.cpt}
                    />
                </div>
                <div className="col-md-2 mrgn-btm10">
                    <label>Mod 1</label>
                    <input
                        onChange={event => setServiceLineAttribute(lineNumber, 'modifier1', event.target.value)}
                        type="text"
                        className="code-input"
                        value={this.props.mod1}
                    />
                </div>
                <div className="col-md-2 mrgn-btm10">
                    <label>Mod 2</label>
                    <input
                        onChange={event => setServiceLineAttribute(lineNumber, 'modifier2', event.target.value)}
                        type="text"
                        className="code-input"
                        value={this.props.mod2}
                    />
                </div>
                <div className="col-md-2 mrgn-btm10">
                    <label>Mod 3</label>
                    <input
                        onChange={event => setServiceLineAttribute(lineNumber, 'modifier3', event.target.value)}
                        type="text"
                        className="code-input"
                        value={this.props.mod3}
                    />
                </div>
                <div className="col-md-2 mrgn-btm10">
                    <label>Mod 4</label>
                    <input
                        onChange={event => setServiceLineAttribute(lineNumber, 'modifier4', event.target.value)}
                        type="text"
                        className="code-input"
                        value={this.props.mod4}
                    />
                </div>
                <div className="col-md-2 mrgn-btm10">
                    <label>Units</label>
                    <input
                        onChange={event => setServiceLineAttribute(lineNumber, 'units', event.target.value)}
                        type="text"
                        className="code-input"
                        value={this.props.units}
                    />
                </div>
                <div className="col-md-2 mrgn-btm10">
                    <label>Total</label>
                    <input
                        disabled
                        type="text"
                        className="code-input"
                        value={this.props.total}
                    />
                </div>
                <div className="col-md-2 mrgn-btm10">
                    <label>Discount</label>
                    <input
                        disabled
                        type="text"
                        className="code-input"
                        value={this.props.discount}
                    />
                </div>
                <div className="col-md-4 mrgn-btm10">
                    <label>CPT Description</label>
                    <input
                        onChange={event => setServiceLineAttribute(lineNumber, 'code_description', event.target.value)}
                        type="text"
                        className="code-input"
                        value={this.props.cptDesc}
                    />
                </div>
                <div className="col-md-3 mrgn-btm10">
                    <label>Point of Diagnosis</label>
                    <input
                        onChange={event => setServiceLineAttribute(lineNumber, 'points_to_diagnosis', event.target.value)}
                        type="text"
                        className="code-input"
                        value={this.props.pod}
                    />
                </div>
                <div className="col-md-3 mrgn-btm10">
                    <label>Revenue Code</label>
                    <input
                        onChange={event => setServiceLineAttribute(lineNumber, 'revenue_code', event.target.value)}
                        type="text"
                        className="code-input"
                        value={this.props.revenueCode}
                    />
                </div>
                <div className="filter-footer">
                    <button
                        onClick={() => { this.props.removeServiceLine(lineNumber) }}
                        className="filter-cancel-button">
                        Remove (X)
                    </button>
                </div>
            </>
        );
    }
}