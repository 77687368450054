export const API_CONSTANTS = {
    AUTHENTICATE: 'login',
    LIST_ACTIVITIES: 'get-activities',
    SUBMIT_ACTIVITIES: 'activity/create',
    UPDATE_ACTIVITY: 'activity/update',
    ACTIVITY_DETAIL: 'activity/',
    DELETE_ACTIVITY: 'activity/',
    DELETE_ACTIVITY_FACESHEET: 'facesheet/',
    EXPORT_ACTIVITIES: 'export-activities',
    UPDATE_THEME: 'user-theme',
    LIST_PATIENTS: 'get-patients',
    SEARCH_PATIENT: 'search-patient',
    CREATE_PATIENT: 'patient/create',
    UPDATE_PATIENT: 'patient/update',
    DELETE_PATIENT: 'patient/',
    PATIENT_DETAIL: 'patient/',
    PATIENT_ACTIVITY: 'get-patient-activity/',
    LIST_SURGEONS: 'get-surgeons',
    CREATE_SURGEON: 'surgeon/create',
    UPDATE_SURGEON: 'surgeon/update',
    SURGEON_DETAIL: 'surgeon/',
    DELETE_SURGEON: 'surgeon/',
    LIST_PROCEDURES: 'get-procedures',
    PROCEDURE_DETAIL: 'procedure/',
    CREATE_PROCEDURE: 'procedure/create',
    UPDATE_PROCEDURE: 'procedure/update',
    DELETE_PROCEDURE: 'procedure/',
    LIST_FACILITIES: 'get-facility',
    DELETE_FACILITY: 'facility/',
    FACILITY_DETAIL: 'facility/',
    CREATE_FACILITY: 'facility/create',
    UPDATE_FACILITY: 'facility/update',
    LIST_ASSISTANTS: 'get-assistants',
    LIST_REGION_ASSISTANTS: 'region-assistants/',
    CREATE_ASSISTANT: 'assistant/create',
    UPDATE_ASSISTANT: 'assistant/update',
    LIST_EVENT_FLAGS: 'get-event-flags',
    LIST_EVENT_TYPES: 'get-event-type',
    CREATE_INSURANCE: 'insurance/create',
    UPDATE_INSURANCE: 'insurance/update',
    LIST_INSURANCES: 'get-insurances',
    INSURANCE_DETAIL: 'insurance/',
    DELETE_INSURANCE:'insurance/',
    LIST_SPECIALITY: 'get-speciality',
    LIST_PRACTICE: 'get-practice',
    PRACTICE_DETAIL:'practice/',
    CREATE_PRACTICE:'practice/create',
    UPDATE_PRACTICE:'practice/update',
    DELETE_PRACTICE:'practice/',
    LIST_REGIONS: 'get-regions',
    ADD_REGION: 'region/create',
    UPDATE_REGION: 'region/update',
    REGION_DETAIL:'region/',
    LIST_MODULES: 'get-modules',
    LIST_USERS: 'users',
    CREATE_USER: 'user/create',
    UPDATE_USER: 'user/update',
    USER_DETAIL: 'user/',
    DELETE_USER: 'user/',
    LIST_CASELOG: 'get-case-logs',
    CREATE_CASELOG: 'user/create',
    UPDATE_CASELOG: 'user/create',
    CASELOG_DETAIL: 'user/create',
    DELETE_CASELOG: 'user/create',
    LIST_SCANFLOW: 'scan-flow',
    SCANFLOW_DETAIL: 'scan-flow/',
    UPDATE_SCANFLOW: 'scan-flow/update',
    CREATE_BILL: 'event-bill/create',
    UPDATE_BILL: 'event-bill/update',
    LIST_EVENT_BILL: 'event-bill/',
    UPLOAD_DOCUMENT: 'upload-document',
    LIST_CLAIMS: 'get-claims-collections-data',
    SEND_INVOICE: 'send-email-facility',
    GET_CPT_FEE: 'get-cpt-fee/'
}