import { billingService } from "../services/billing";
import { BILL } from "../constants/actions";

export const createBill = payload => dispatch => {
    return new Promise((resolve, reject) => {
        billingService
            .createBill(payload)
            .then(response => {
                dispatch({
                    type: BILL.CREATE_BILL_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {});
    });
};

export const updateBill = payload => dispatch => {
    return new Promise((resolve, reject) => {
        billingService
            .updateBill(payload)
            .then(response => {
                dispatch({
                    type: BILL.UPDATE_BILL_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {});
    });
};