import React from "react";
import {bindActionCreators} from "redux";
import {createRegion, updateRegion} from "../../actions/regions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import {APP_ROUTES, NOTIFICATION_TYPES} from "../../constants/common";
import {createNotification} from "../../utils/notificationManager";
import {regionsService} from "../../services/regions";


const REQUIRED_FIELDS = {
    NAME: 'name',
    EMAIL: 'email',
};

class ManageRegions extends React.Component {
    state = {
        name: '',
        email: '',
        errors: [],
    };

    componentDidMount() {
        const regionId = this.props.location.state ? this.props.location.state.regionId : '';
        if (regionId) {
            regionsService
                .getRegions()
                .then(response => {
                    response.data.map(region => {
                        if (region.id === regionId) {
                            this.setState({
                                name: region.name,
                                email: region.email,
                            });
                        }
                    })
                });
        }
    }

    submitRegion = () => {
        if (!this.validateRegion()) {
            return;
        }
        const { createRegion, updateRegion } = this.props;
        const {
            name,
            email,
        } = this.state;

        const request = {
            name,
            email,
            type: 'company',
        };

        const regionId = this.props.location.state ? this.props.location.state.regionId : '';

        if (regionId) {
            updateRegion({...request, id: regionId})
                .then(res => {
                    if (res.response.data.success) {
                        this.props.history.push(APP_ROUTES.REGIONS);
                    } else {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                        this.props.history.push(APP_ROUTES.REGIONS);
                    }
                })
                .catch(err => {
                    createNotification(NOTIFICATION_TYPES.ERROR, `Unable to update region ${err.message}`);
                });
        } else {
            createRegion(request)
                .then(res => {
                    if (res.response.data.success) {
                        this.props.history.push(APP_ROUTES.REGIONS);
                    } else {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                        this.props.history.push(APP_ROUTES.REGIONS);
                    }
                })
                .catch(err => {
                    createNotification(NOTIFICATION_TYPES.ERROR, `Unable to create region ${err.message}`);
                });
        }
    };

    hasErrors = (field) => {
        return this.state.errors.includes(field);
    };

    validateRegion = () => {
        const {
            email,
            name,
            errors,
        } = this.state;

        let isValid = true;

        if (name.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.NAME] });
            isValid = false;
        } else if (email.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.EMAIL] });
            isValid = false;
        }

        if (!isValid) {
            createNotification(NOTIFICATION_TYPES.ERROR, 'Please select required values');
        }

        return isValid;
    };

    resetErrors = (field) => {
        this.setState({
            errors: this.state.errors.filter(fieldName => fieldName !== field)
        });
    }

    render() {
        const activePage = this.props.location.state ? this.props.location.state.activePage : '';
        const regionId = this.props.location.state ? this.props.location.state.regionId : '';
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar activePage={activePage}/>
                </div>
                <TopNavbar />
                <div className="manage-activity-right-col">
                    <div className="activity-row">
                        <div className="page-title">
                            {regionId &&
                                <h2 className="heading-custom">Edit Region</h2>
                            }
                            {!regionId &&
                                <h2 className="heading-custom">Add Region</h2>
                            }
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Region Name <span className="required">*</span></label>
                            <input
                                className={this.hasErrors(REQUIRED_FIELDS.NAME) ? 'code-input error' : 'code-input'}
                                type="text"
                                value={this.state.name}
                                onChange={event => {
                                    this.resetErrors(REQUIRED_FIELDS.NAME);
                                    this.setState({ name: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Region Email <span className="required">*</span></label>
                            <input
                                className={this.hasErrors(REQUIRED_FIELDS.EMAIL) ? 'code-input error' : 'code-input'}
                                type="text"
                                value={this.state.email}
                                onChange={event => {
                                    this.resetErrors(REQUIRED_FIELDS.EMAIL);
                                    this.setState({ email: event.target.value })
                                }}
                            />
                        </div>
                        <div className="filter-footer">
                            <button
                                onClick={this.submitRegion}
                                className="filter-save-button">
                                Submit
                            </button>
                            <button
                                onClick={() => { this.props.history.push(APP_ROUTES.REGIONS); }}
                                className="filter-cancel-button">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createRegion,
            updateRegion,
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(ManageRegions));