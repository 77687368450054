import { combineReducers } from 'redux';
import authenticationReducer  from './authenticationReducer';
import activityReducer from "./activityReducer";
import patientReducer from "./patientReducer";
import surgeonReducer from "./surgeonReducer";
import procedureReducer from "./procedureReducer";
import assistantReducer from "./assistantReducer";
import facilityReducer from "./facilityReducer";
import userReducer from "./userReducer";
import claimsReducer from "./claimsReducer";
import regionReducer from "./regionReducer";
import caseLogReducer from "./caseLogReducer";
import billingReducer from "./billingReducer";
import insuranceReducer from "./insuranceReducer";
import practiceReducer from "./practiceReducer";

export default combineReducers({
    authenticationReducer,
    assistantReducer,
    activityReducer,
    patientReducer,
    surgeonReducer,
    procedureReducer,
    facilityReducer,
    userReducer,
    claimsReducer,
    caseLogReducer,
    billingReducer,
    insuranceReducer,
    practiceReducer,
    regionReducer,
});