export const AUTH = {
    OFFICE_365_AUTHENTICATION: 'OFFICE_365_AUTHENTICATION_SUCCESS',
};

export const ACTIVITY = {
    FETCH_ACTIVITIES_SUCCESS: 'FETCH_ACTIVITIES_SUCCESS',
    SUBMIT_ACTIVITY_SUCCESS: 'SUBMIT_ACTIVITY_SUCCESS',
    UPDATE_ACTIVITY_SUCCESS: 'UPDATE_ACTIVITY_SUCCESS',
    DELETE_ACTIVITY_SUCCESS: 'DELETE_ACTIVITY_SUCCESS',
};

export const PATIENT = {
    FETCH_PATIENT_SUCCESS: 'FETCH_PATIENT_SUCCESS',
    CREATE_PATIENT_SUCCESS: 'CREATE_PATIENT_SUCCESS',
    UPDATE_PATIENT_SUCCESS: 'UPDATE_PATIENT_SUCCESS',
};

export const SURGEON = {
    FETCH_SURGEON_SUCCESS: 'FETCH_SURGEON_SUCCESS',
    CREATE_SURGEON_SUCCESS: 'CREATE_SURGEON_SUCCESS',
    UPDATE_SURGEON_SUCCESS: 'UPDATE_SURGEON_SUCCESS',
};

export const PROCEDURE = {
    FETCH_PROCEDURES_SUCCESS: 'FETCH_PROCEDURES_SUCCESS',
    CREATE_PROCEDURE_SUCCESS: 'CREATE_PROCEDURE_SUCCESS',
    UPDATE_PROCEDURE_SUCCESS: 'UPDATE_PROCEDURE_SUCCESS',
};

export const FACILITY = {
    FETCH_FACILITY_SUCCESS: 'FETCH_FACILITY_SUCCESS',
    CREATE_FACILITY_SUCCESS: 'CREATE_FACILITY_SUCCESS',
    UPDATE_FACILITY_SUCCESS: 'UPDATE_FACILITY_SUCCESS',
};

export const USER = {
    FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
};

export const CASElOG = {
    FETCH_CASElOG_SUCCESS: 'FETCH_CASElOG_SUCCESS',
    CREATE_CASElOG_SUCCESS: 'CREATE_CASElOG_SUCCESS',
    UPDATE_CASElOG_SUCCESS: 'UPDATE_CASElOG_SUCCESS',
};

export const ASSISTANT = {
    FETCH_ASSISTANT_SUCCESS: 'FETCH_ASSISTANT_SUCCESS',
    CREATE_ASSISTANT_SUCCESS: 'CREATE_ASSISTANT_SUCCESS',
    UPDATE_ASSISTANT_SUCCESS: 'UPDATE_ASSISTANT_SUCCESS',
};

export const BILLING = {
    FETCH_BILLING_SUCCESS: 'FETCH_BILLING_SUCCESS',
    UPDATE_BILLING_SUCCESS: 'UPDATE_BILLING_SUCCESS',
};

export const BILL = {
    CREATE_BILL_SUCCESS: 'CREATE_BILL_SUCCESS',
    UPDATE_BILL_SUCCESS: 'UPDATE_BILL_SUCCESS',
};

export const INSURANCE = {
    FETCH_INSURANCE_SUCCESS: 'FETCH_INSURANCE_SUCCESS',
    CREATE_INSURANCE_SUCCESS: 'CREATE_INSURANCE_SUCCESS',
    UPDATE_INSURANCE_SUCCESS: 'UPDATE_INSURANCE_SUCCESS',
};

export const PRACTICE = {
    FETCH_PRACTICE_SUCCESS: 'FETCH_PRACTICE_SUCCESS',
    CREATE_PRACTICE_SUCCESS: 'CREATE_PRACTICE_SUCCESS',
    UPDATE_PRACTICE_SUCCESS: 'UPDATE_PRACTICE_SUCCESS',
};

export const CLAIM = {
    FETCH_CLAIMS_SUCCESS: 'FETCH_CLAIMS_SUCCESS',
    CLEAN_SEARCH: 'CLEAN_SEARCH',
};

export const REGIONS = {
    FETCH_REGIONS_SUCCESS: 'FETCH_REGIONS_SUCCESS',
    CREATE_REGION_SUCCESS: 'CREATE_REGION_SUCCESS',
    UPDATE_REGION_SUCCESS: 'UPDATE_REGION_SUCCESS',
};