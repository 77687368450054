import React from 'react';
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import {Link, withRouter} from "react-router-dom";
import {APP_ROUTES} from "../../constants/common";

class NotFound extends React.Component {
    render() {
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar />
                </div>
                <TopNavbar />
                <div
                    className="right-col"
                    style={{
                        minHeight: '723px',
                    }}
                >
                    <div className="row">
                        <div className="clearfix" />
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="text-center">
                                <div style={{
                                    fontSize: '90px',
                                    fontWeight: '900',
                                    color: '#2a3f54',
                                    marginTop: '50px',
                                }}>
                                    404
                                </div>
                                <h2 style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    color: '#2a3f54',
                                    fontSize: '24px',
                                    fontWeight: '600',
                                }}>
                                    The requested page does not exist.
                                </h2>

                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    outline: '0',

                                }}>
                                    <Link
                                        style={{
                                            color: '#2a3f54',
                                            fontSize: '16px',
                                            fontWeight: '600',
                                            letterSpacing: '0',
                                        }}
                                        to={{
                                            pathname: APP_ROUTES.DASHBOARD,
                                            state: {
                                                activePage: APP_ROUTES.DASHBOARD,
                                            }
                                        }}>
                                        Please click here to go to the homepage.
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(NotFound);