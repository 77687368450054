import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";

import { moduleService } from '../../services/modules';
import { practiceService } from '../../services/practices';
import {APP_ROUTES, NOTIFICATION_TYPES, RECORDS_PER_PAGE} from "../../constants/common";
import {AsyncPaginate} from "react-select-async-paginate";
import {createNotification} from "../../utils/notificationManager";

//import { createCaseLog, updateCaseLog } from '../../actions/caseLog';
import { submitActivity, updateActivity } from "../../actions/activity";

import {caseLogService} from "../../services/caseLog";
import { activityService } from '../../services/activities';
import AddPatient from "../atom/AddPatient";
import {patientService} from "../../services/patients";
import DatePicker from "react-datepicker";
import {surgeonService} from "../../services/surgeons";
import {facilityService} from "../../services/facilities";
import {assistantService} from "../../services/assistants";
import {formatDate} from "../../utils/date";
import {eventTypesService} from "../../services/eventTypes";
import PropTypes from "prop-types";

const REQUIRED_FIELDS = {
    PATIENT: 'patient',
    EVENT_TYPE: 'eventType',
    SURGEON: 'surgeon',
    FACILITY: 'facility',
    ASSISTANT: 'assistant',
};

class ManageCaseLogs extends React.Component {
    static propTypes = {
        submitActivity: PropTypes.func.isRequired,
        updateActivity: PropTypes.func.isRequired,
    };

    state = {
        showAddPatient: false,
        patient: {},
        surgeon: {},
        facility: {},
        assistant: {},
        startDate: Date.now(),
        eventType: {},
        diagnosisCodes: '',
        fields: {},
        errors: [],
    };

    componentDidMount() {
        const caseId = this.props.location.state ? this.props.location.state.caseId : '';
        if (caseId) {
            activityService
                .getActivityDetail(caseId)
                .then(response => {

                    this.setState({
                        patient: {
                            label: response.data.patient ? `${response.data.patient.first_name} ${response.data.patient.last_name}` : '',
                            value: response.data.patient ? parseInt(response.data.patient.id) : '',
                        },
                        eventType: {
                            label: response.data.event_type.event_type_name,
                            value: parseInt(response.data.event_type.id)
                        },
                        surgeon: {
                            label: `${response.data.surgeon.first_name} ${response.data.surgeon.last_name}`,
                            value: parseInt(response.data.surgeon.id)
                        },
                        facility: {
                            label: response.data.facility.facility_name,
                            value: parseInt(response.data.facility.id)
                        },
                        assistant: {
                            label: `${response.data.assistant.name} ${response.data.assistant.last_name}`,
                            value: parseInt(response.data.assistant.id)
                        },
                        startDate: Date.parse(response.data.event_start_date),
                        diagnosisCodes: response.data.diagnosis_codes,

                    });
                })
        }
    }

    hasErrors = (field) => {
        return this.state.errors.includes(field);
    };

    resetErrors = (field) => {
        this.setState({
            errors: this.state.errors.filter(fieldName => fieldName !== field)
        });
    }

    formatPhone = (str) => {
        if (str) {
            let phone = '';
            str = str.replace(/\D/g, '').substring(0,10);
            if (str.length < 3) {
                phone += str;
            } else if (str.length < 6) {
                phone += str.substring(0, 3) + '-';
                if (str.length > 3) {
                    phone += str.substring(3, str.length);
                }
            } else {
                phone += str.substring(0, 3) + '-' + str.substring(3, 6) + '-' + str.substring(6, 10);
            }
            return phone;
        }
        return str;
    };

    loadPatient = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        return patientService
            .getPatients({ params: options })
            .then(response => {
                const options = response.data.map(patient => (
                    {
                        value: patient.id,
                        label: `${patient.first_name} ${patient.last_name}`,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    loadSurgeon = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        return surgeonService
            .getSurgeons({ params: options })
            .then(response => {
                const options = response.data.map(surgeon => (
                    {
                        value: surgeon.id,
                        label: `${surgeon.first_name} ${surgeon.last_name}`,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    loadFacility = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        return facilityService
            .getFacilities({ params: options })
            .then(response => {
                const options = response.data.map(facility => (
                    {
                        value: facility.id,
                        label: facility.facility_name,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    loadAssistant = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        return assistantService
            .getAssistants({ params: options })
            .then(response => {
                const options = response.data.map(assistant => (
                    {
                        value: assistant.assistant_id,
                        label: `${assistant.NAME} ${assistant.last_name}`,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    loadEventTypes = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        return eventTypesService
            .getEventTypes({ params: options })
            .then(response => {
                const options = response.data.map(eventType => (
                    {
                        value: eventType.id,
                        label: eventType.event_type_name,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    onChangePatient = (value) => {
        this.resetErrors(REQUIRED_FIELDS.PATIENT);
        this.setState({ patient: value });
    };

    onChangeSurgeon = (value) => {
        this.resetErrors(REQUIRED_FIELDS.SURGEON);
        this.setState({ surgeon: value });
    };

    onChangeFacility = (value) => {
        this.resetErrors(REQUIRED_FIELDS.FACILITY);
        this.setState({ facility: value });
    };

    onChangeAssistant = (value) => {
        this.resetErrors(REQUIRED_FIELDS.ASSISTANT);
        this.setState({ assistant: value });
    };

    onChangeEventType = (value) => {
        this.resetErrors(REQUIRED_FIELDS.EVENT_TYPE);
        this.setState({ eventType: value });
    };

    toggleAddPatient = (flag) => {
        this.setState({showAddPatient: flag});
    };

    setPatient = (patient) => {
        this.resetErrors(REQUIRED_FIELDS.PATIENT);
        this.setState({ patient: {
                label: patient.label,
                value: patient.value,
        }});
    };

    validateCaseLog = () => {
        const {
            patient,
            eventType,
            surgeon,
            facility,
            assistant,
            errors,
        } = this.state;
        let isValid = true;

        if (Object.keys(patient).length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.PATIENT] });
            isValid = false;
        } else if (Object.keys(eventType).length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.EVENT_TYPE] });
            isValid = false;
        } else if( Object.keys(surgeon).length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.SURGEON] });
            isValid = false;
        } else if(Object.keys(facility).length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.FACILITY] });
            isValid = false;
        } else if(Object.keys(assistant).length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.ASSISTANT] });
            isValid = false;
        }

        if (!isValid) {
            createNotification(NOTIFICATION_TYPES.ERROR, 'Please select required values');
        }

        return isValid;
    };

    submitCaseLog = () => {
        if (!this.validateCaseLog()) {
            return;
        }

        const { submitActivity, updateActivity  } = this.props;
        const {
            patient,
            surgeon,
            facility,
            assistant,
            startDate,
            eventType,
            diagnosisCodes,
        } = this.state;


        const caseId        = this.props.location.state ? this.props.location.state.caseId : '';
        const request = {
            patient_id: patient.value,
            event_facility: facility.value,
            event_surgical_assitant:  assistant.value,
            event_surgeon: surgeon.value,
            event_start_date: formatDate(startDate),
            event_type: eventType.value,
            diagnosis_codes: diagnosisCodes,
            caseLog:true
        };
        if (caseId) {

            const payload = {...request, 'id': caseId};
            updateActivity(payload)
                .then(res => {
                    if (res.response.success) {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                        this.props.history.push(APP_ROUTES.CASELOG_DASHBOARD);
                    } else {
                        createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
                        //this.props.history.push(APP_ROUTES.USER_DASHBOARD);
                    }
                })
                .catch(err => {
                    createNotification(NOTIFICATION_TYPES.ERROR, `Unable to update user ${err.message}`);
                });
        } else {
            //console.log(request);
            submitActivity(request)
                .then(res => {
                    if (res.response.success) {
                        this.props.history.push(APP_ROUTES.CASELOG_DASHBOARD);
                    } else {
                        createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
                        //this.props.history.push(APP_ROUTES.USER_DASHBOARD);
                    }
                })
                .catch(err => {
                    createNotification(NOTIFICATION_TYPES.ERROR, `Unable to create user ${err.message}`);
                });
        }
    };

    setStartDate = (value) => {
        this.setState({ startDate: value });
    };

    render() {
        const caseId = this.props.location.state ? this.props.location.state.caseId : '';
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar />
                </div>
                <TopNavbar />
                <div className="manage-activity-right-col">
                    <div className="activity-row">
                        <div className="page-title">
                            {caseId &&
                                <h2 className="heading-custom">Edit Caselog</h2>
                            }
                            {!caseId &&
                                <h2 className="heading-custom">Add Caselog</h2>
                            }
                        </div>
                        <div className="col-md-12 mrgn-btm10">
                            <label>Patient <span className="required">*</span></label>
                            { !this.state.showAddPatient && (
                                <AsyncPaginate
                                    placeholder="Select Patient..."
                                    className={this.hasErrors(REQUIRED_FIELDS.PATIENT) ? 'error' : ''}
                                    value={this.state.patient}
                                    loadOptions={this.loadPatient}
                                    onChange={this.onChangePatient}
                                />
                            )}
                            { !this.state.showAddPatient && (
                                <div style={{paddingTop: "10px"}}>
                                    <button
                                        onClick={() => this.toggleAddPatient(true)}
                                        className="filter-save-button add-new-button">
                                        Add New
                                    </button>
                                </div>
                            )}
                        </div>
                        { this.state.showAddPatient && (
                            <AddPatient
                                toggle={this.toggleAddPatient}
                                setPatient={this.setPatient}
                            />
                        )}

                        <div className="col-md-12 mrgn-btm10">
                            <label>Event Type <span className="required">*</span></label>
                            <AsyncPaginate
                                placeholder="Select Event Type..."
                                className={this.hasErrors(REQUIRED_FIELDS.EVENT_TYPE) ? 'error' : ''}
                                value={this.state.eventType}
                                loadOptions={this.loadEventTypes}
                                onChange={this.onChangeEventType}
                            />
                        </div>

                        <div className="col-md-4 mrgn-btm10">
                            <label>Surgeon <span className="required">*</span></label>
                            <AsyncPaginate
                                placeholder="Select Surgeon..."
                                value={this.state.surgeon}
                                loadOptions={this.loadSurgeon}
                                onChange={this.onChangeSurgeon}
                                className={this.hasErrors(REQUIRED_FIELDS.SURGEON) ? 'error' : ''}
                            />
                        </div>
                        <div className="col-md-4 mrgn-btm10">
                            <label>Facility <span className="required">*</span></label>
                            <AsyncPaginate
                                placeholder="Select Facility..."
                                value={this.state.facility}
                                loadOptions={this.loadFacility}
                                onChange={this.onChangeFacility}
                                className={this.hasErrors(REQUIRED_FIELDS.FACILITY) ? 'error' : ''}
                            />
                        </div>
                        <div className="col-md-4 mrgn-btm10">
                            <label>Assistant <span className="required">*</span></label>
                            <AsyncPaginate
                                placeholder="Select Assistant..."
                                value={this.state.assistant}
                                loadOptions={this.loadAssistant}
                                onChange={this.onChangeAssistant}
                                className={this.hasErrors(REQUIRED_FIELDS.ASSISTANT) ? 'error' : ''}
                            />
                        </div>

                        <div className="col-md-6 mrgn-btm10">
                            <label>Diagnosis Codes</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.diagnosisCodes}
                                onChange={event => this.setState({ diagnosisCodes: event.target.value })}
                            />
                        </div>

                        <div className="col-md-6 mrgn-btm10">
                            <label>DOS <span className="required">*</span></label>
                            <br/>
                            <DatePicker
                                isClearable
                                selected={this.state.startDate}
                                onChange={date => this.setStartDate(date)}
                                className="date-picker"
                            />
                        </div>


                    </div>
                    <div className="filter-footer">
                        {caseId &&
                        <button
                            onClick={this.submitCaseLog}
                            className="filter-save-button">
                            Update
                        </button>
                        }
                        {!caseId &&
                        <button
                            onClick={this.submitCaseLog}
                            className="filter-save-button">
                            Submit
                        </button>
                        }
                        <button
                            onClick={() => { this.props.history.push(APP_ROUTES.CASELOG_DASHBOARD); }}
                            className="filter-cancel-button">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            submitActivity,
            updateActivity,
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(ManageCaseLogs));