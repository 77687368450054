import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import {scanFlowService} from "../../services/scanFlow";
import {APP_ROUTES, NOTIFICATION_TYPES} from "../../constants/common";
import {createNotification} from "../../utils/notificationManager";
import {withRouter} from "react-router-dom";

const UploadButton = props => {
    return (
        <Button
            color="primary"
            onClick={() => props.upload(props.type)}>
            Upload New File
        </Button>
    );
};

class UploadDocument extends React.Component{
    static propTypes = {
        title: PropTypes.string.isRequired,
        eventId: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        isOpen: PropTypes.bool.isRequired,
        setModal: PropTypes.func.isRequired,
        goToPage: PropTypes.func.isRequired,
    };

    state = {
      patientId: '',
      file: '',
    };

    componentDidMount() {
        if (this.props.eventId) {
            scanFlowService
                .getBillingDetail(this.props.eventId)
                .then(response => {
                    this.setState({ patientId: response.data.patient ? response.data.patient.id : '' });

                    let file = '';
                    if (this.props.type === 'facesheet') {
                        file = response.data.facesheets && response.data.facesheets.length > 0 ? response.data.facesheets[0].facesheet_file : '';
                    } else if (this.props.type === 'op') {
                        file = response.data.op && response.data.op.length > 0 ? response.data.op[0].op_file : '';
                    } else if (this.props.type === 'insurance') {
                        file = response.data.insurace_file && response.data.insurace_file.length > 0 ? response.data.insurace_file[0].insurance_file : '';
                    }

                    this.setState({ file: file });
                });
        }
    }

    handleClick = () => {
        this.refs.hiddenFileInput.click();
    };

    parseFile = (file) => {
        const fileUrl = file.split('/');
        return fileUrl[fileUrl.length - 1];
    };

    upload = (event, eventId, patientId, type) => {
        const fileUploaded = event.target.files[0];

        const formData = new FormData();
        formData.append('event_id', eventId);
        formData.append('patient_id', patientId);
        formData.append('file', fileUploaded);
        formData.append('type', type);
        formData.append('api_type', 'react');

        scanFlowService
            .uploadDocument(formData)
            .then(res => {
                if (res.data.success) {
                    this.props.history.push(APP_ROUTES.SCAN_FLOW_DASHBOARD);
                } else {
                    this.props.setModal(false);
                    createNotification(NOTIFICATION_TYPES.SUCCESS, res.message);
                    this.props.goToPage(1);
                }
            })
            .catch(err => {
                createNotification(NOTIFICATION_TYPES.ERROR, `Unable to upload document ${err.message}`);
            });
    };

    render() {
        const {
            eventId,
            title,
            isOpen,
            setModal,
            type,
        } = this.props;

        const {
            patientId,
            file,
        } = this.state;
        return (
            <div>
                <Modal isOpen={isOpen} toggle={() => setModal(!isOpen)}>
                    <ModalHeader toggle={() => setModal(!isOpen)}>{title}</ModalHeader>
                    <ModalBody>
                        { file && (
                            <div>
                                <label style={{
                                    paddingRight: '5px',
                                }}>
                                    File Name:
                                </label>
                                <a style={{
                                    paddingRight: '10px',
                                }}
                                   href={file}
                                   target = "_blank">
                                    {this.parseFile(file)}
                                </a>
                            </div>
                        )}
                        <UploadButton
                            upload={this.handleClick}
                            type={type}
                        />
                        <input type="file"
                               accept='application/pdf'
                               ref='hiddenFileInput'
                               onChange={event => {
                                   this.upload(event, eventId, patientId, type)
                               }}
                               style={{display:'none'}}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => setModal(!isOpen)}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default withRouter(UploadDocument);
