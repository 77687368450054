import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { LOCAL_STORAGE_KEYS, MENU_LIST } from "../../constants/common";

import '../../../sass/sidebar.scss';
import companyLogo from "../../../assets/images/coremaxlogo-trans.png";

class Sidebar extends React.Component {
    static propTypes = {
        authInfo: PropTypes.object.isRequired,
        activePage: PropTypes.string.isRequired,
    };

    state = {
        toggleNestedMenu: false,
        menuName: '',
    };

    renderNestedMenu = (nestedMenu, subMenuHead) => {
        return nestedMenu.map((menu, index) => {
            return index !== nestedMenu.length - 1 && (
                <Link
                    style={{
                        color: '#E7E7E7',
                        textDecoration: 'none',
                    }}
                    to={{
                        pathname: menu.slug,
                        state: {
                            activePage: menu.slug,
                            toggleMenu: true,
                            subMenuHead,
                        }
                    }}>

                    <li className={`sidebar-submenu-list-item ${menu.slug === this.props.activePage ? 'active' : ''}`}>
                        <div className='submenu-circle'/>
                        <div className='submenu'/>
                        <div style={{
                            fontSize: '12px',
                            paddingLeft: '35px',
                        }}>
                            {menu.module_name}
                        </div>
                    </li>
                </Link>
            );
        });
    };

    handleClick = (menuHead) => {
        this.setState(prevState =>
            ({
                toggleNestedMenu: prevState.menuName === menuHead ? !prevState.toggleNestedMenu : true,
                menuName: menuHead,
            }));
    };

    hasAccess = (module) => {
        const loggedInUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER));
        let menuExists = false;
        loggedInUser.modules_users.map(userModule => {
            if(userModule.slug === module) {
                menuExists = true;
            }
        });
        return menuExists;
    };

    renderMenuListItem = () => {
        const {
            toggleNestedMenu,
            menuName,
        } = this.state;
        const toggleMenu = this.props.toggleMenu || this.props.location.state ? this.props.location.state.toggleMenu : false;
        const subMenuHead = this.props.subMenuHead || this.props.location.state ? this.props.location.state.subMenuHead : '';
        return MENU_LIST.map(menuItem => {
            if (menuItem.module_name && this.hasAccess(menuItem.slug)) {
                return (
                    <Link
                        style={{
                            color: '#E7E7E7',
                            textDecoration: 'none'
                        }}
                        to={{
                            pathname: menuItem.slug,
                            state: {
                                activePage: menuItem.slug,
                            }
                        }}>
                        <div className={`${menuItem.slug === this.props.activePage ? 'active' : ''}`}>
                            <div style={{
                                paddingLeft: '20px',
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <FontAwesomeIcon
                                    icon={menuItem.icon}
                                />
                                <li
                                    style={{
                                        paddingLeft: menuItem.padding,
                                    }}
                                    className='sidebar-menu-list-item'>
                                    {menuItem.module_name}
                                </li>
                            </div>
                        </div>
                    </Link>
                );
            } else {
                const menuHead = Object.keys(menuItem)[0];
                const menuLength = Object.values(menuItem)[0].length;
                if (this.hasAccess(menuHead)) {
                    return (
                        <>
                            <div className={`${menuItem.slug === this.props.activePage ? 'active' : ''}`}>
                                <div style={{
                                    paddingLeft: '20px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <FontAwesomeIcon
                                        style={{
                                            color: '#E7E7E7',
                                        }}
                                        icon={Object.values(menuItem)[0][menuLength - 1].icon}
                                    />
                                    <li
                                        style={{
                                            paddingLeft: Object.values(menuItem)[0][menuLength - 1].padding,
                                            color: '#E7E7E7',
                                            textDecoration: 'none'
                                        }}
                                        className='sidebar-menu-list-item'
                                        onClick={() => {this.handleClick(menuHead)}}>
                                        {menuHead}
                                        <span className="main_menu">
                                         <FontAwesomeIcon
                                             style={{
                                                 color: '#E7E7E7',
                                             }}
                                             icon={faChevronDown}
                                         />
                                    </span>
                                    </li>
                                </div>
                            </div>
                            {(toggleNestedMenu || toggleMenu) && (menuName === menuHead || subMenuHead === menuHead) &&
                            this.renderNestedMenu(Object.values(menuItem)[0], menuHead)}
                        </>
                    );
                }
            }
        });
    };

    render() {
        return(
            <div className="sidebar-title">
                <a href="https://usp.coremaxcloud.com/login" className="sidebar-logo-link">
                    <img
                        className="sidebar-logo"
                        src={companyLogo}
                        alt="coremax"
                    />
                </a>
                <div className="sidebar-menu">
                    <ul className="sidebar-menu-list">
                        {this.renderMenuListItem()}
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authInfo: state.authenticationReducer.authInfo,
    }
};

export default connect(
    mapStateToProps,
    null,
)(withRouter(Sidebar));