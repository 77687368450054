import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { API_CONSTANTS } from "../constants/api";

function getUsers(options) {
    return apiClient()
        .get(API_CONSTANTS.LIST_USERS, options)
        .then(handleResponse)
        .catch(handleError);
}

function createUser(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_USER, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateUser(payload) {
    return apiClient()
        .post(API_CONSTANTS.UPDATE_USER, payload)
        .then(handleResponse)
        .catch(handleError);
}

function deleteUser(user) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_USER + user)
        .then(handleResponse)
        .catch(handleError);
}

function getUserDetail(user) {
    return apiClient()
        .get(API_CONSTANTS.USER_DETAIL + user)
        .then(handleResponse)
        .catch(handleError);
}

export const userService = {
    getUsers,
    createUser,
    updateUser,
    deleteUser,
    getUserDetail,
}