import {SURGEON} from '../constants/actions';

const initialState = {
    surgeons: [],
    totalSurgeon: 0,
};

export default function(state = initialState, action) {

    switch (action.type) {
        case SURGEON.FETCH_SURGEON_SUCCESS:
            return {
                ...state,
                surgeons: action.data.data,
                totalSurgeon: action.data.records,
            };
        case SURGEON.CREATE_SURGEON_SUCCESS:
            const surgeons = [ ...state.surgeons, action.data.data ];
            return {
                ...state,
                surgeons: surgeons,
            };
        case SURGEON.UPDATE_SURGEON_SUCCESS:
            const updateSurgeons = [ ...state.surgeons, action.data.data ];
            return {
                ...state,
                patients: updateSurgeons,
            }
        default:
            return state;
    }
}