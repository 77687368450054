import { apiClient } from "./apiClients";
import { API_CONSTANTS } from "../constants/api";
import { handleError, handleResponse } from "./GenericResponseHandler";

function getProcedures(options) {
    return apiClient()
        .get(API_CONSTANTS.LIST_PROCEDURES, options)
        .then(handleResponse)
        .catch(handleError);
}

function createProcedure(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_PROCEDURE, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateProcedure(payload) {
    return apiClient()
        .put(API_CONSTANTS.UPDATE_PROCEDURE, payload)
        .then(handleResponse)
        .catch(handleError);
}

function deleteProcedure(payload) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_PROCEDURE + payload)
        .then(handleResponse)
        .catch(handleError);
}

function getProcedureDetail(insurance) {
    return apiClient()
        .get(API_CONSTANTS.PROCEDURE_DETAIL + insurance)
        .then(handleResponse)
        .catch(handleError);
}


export const procedureService = {
    getProcedures,
    createProcedure,
    updateProcedure,
    deleteProcedure,
    getProcedureDetail
}