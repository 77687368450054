import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { API_CONSTANTS } from "../constants/api";

function getAssistants(options) {
    return apiClient()
        .get(API_CONSTANTS.LIST_ASSISTANTS, options)
        .then(handleResponse)
        .catch(handleError);
}

function getRegionAssistants(region, options) {
    return apiClient()
        .get(API_CONSTANTS.LIST_REGION_ASSISTANTS + region, options)
        .then(handleResponse)
        .catch(handleError);
}

function createAssistant(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_ASSISTANT, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateAssistant(payload) {
    return apiClient()
        .post(API_CONSTANTS.UPDATE_ASSISTANT, payload)
        .then(handleResponse)
        .catch(handleError);
}

export const assistantService = {
    getAssistants,
    getRegionAssistants,
    createAssistant,
    updateAssistant,
};
