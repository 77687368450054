import { ASSISTANT } from '../constants/actions';

const initialState = {
    assistants: [],
    totalAssistants: 0,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case ASSISTANT.FETCH_ASSISTANT_SUCCESS :
            return {
                ...state,
                assistants: action.data.data,
                totalAssistants: action.data.records,
            };
        default:
            return state;
    }
}