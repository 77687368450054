import React from 'react';
import '../../../sass/topnavbar.scss';

import profileImg from "../../../assets/images/img.jpg";
import Switch from "react-switch";
import {APP_ROUTES, LOCAL_STORAGE_KEYS, NOTIFICATION_TYPES} from "../../constants/common";
import {bindActionCreators} from "redux";
import {authenticate} from "../../actions/authentication";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {createNotification} from "../../utils/notificationManager";

import { authenticationService } from "../../services/authentication";

const loggedInUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER));

class TopNavbar extends React.Component {

    state = {
        isOpen: false,
        checked: false,
        theme: (loggedInUser && loggedInUser.theme) || 'dark',
    };

    componentDidMount() {
        this.setState({checked: (!(loggedInUser && loggedInUser.theme === 'dark'))})
    }

    logout = () => {
        localStorage.clear();
        window.location.href = '/';
    };

    toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

    handleChange = checked => {
        this.setState({ checked: checked }, () => {
            this.setState({theme: checked ? 'light' : 'dark'}, () => {
                const { theme } = this.state;
                const loggedInUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER));
                if (loggedInUser) {
                    const request = {
                        id: loggedInUser.id,
                        theme,
                    };
                    authenticationService
                        .updateTheme(request)
                        .then(response => {
                            loggedInUser.theme = theme;
                            localStorage.setItem(
                                LOCAL_STORAGE_KEYS.LOGGED_IN_USER,
                                JSON.stringify(loggedInUser),
                            );
                            window.location.reload();
                        });
                }
            });
        });
    };

    revertImpersonate = () => {
        const { authenticate } = this.props;
        const impersonateEmail = localStorage.getItem(LOCAL_STORAGE_KEYS.IMPERSONATE_EMAIL);
        const request = {"email": impersonateEmail};

        authenticate(request)
            .then(res => {
                if (res.response.data.token) {
                    this.props.history.push(APP_ROUTES.USER_DASHBOARD);
                } else {
                    createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
                }
            })
            .catch(err => { });
    };

    render() {
        const loggedInUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER));
        const impersonateEmail = localStorage.getItem(LOCAL_STORAGE_KEYS.IMPERSONATE_EMAIL);
        const menuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;
        return (
            <div className="col-12" style={{
                float: 'right'
            }}>
            <div className="top-nav">
                <div style={{
                    background: impersonateEmail ? '#343a40' : '#EDEDED' ,
                }}
                    className="nav_menu">
                    <div className="dropdown pull-right" onClick={this.toggleOpen}>
                        <a className="user-profile" data-toggle="dropdown">
                            <img src={profileImg} className="user-profile-image"/>
                            {`${loggedInUser.name} ${loggedInUser.last_name}`}
                        </a>
                        <div className={menuClass} aria-labelledby="dropdownMenuButton" style={{ 'min-width': '0px','width': '100%'}}>
                            <a className="dropdown-item" href="#nogo">
                                Profile
                            </a>
                            <a className="dropdown-item" href="/users">
                                Switch User
                            </a>
                            <a className="dropdown-item" href="#nogo" onClick={this.logout}>
                                Logout
                            </a>
                        </div>
                    </div>
                    <div
                        style={{
                            paddingTop: '18px',
                            paddingRight: '15px',
                            alignItems: 'center',
                            display: 'flex',
                        }}
                        className="pull-right">
                        <span style={{
                            color: '#57b1b1',
                            paddingRight: '5px',
                            fontWeight: '500',
                        }}>
                            Dark
                        </span>
                        <Switch
                            checked={this.state.checked}
                            onChange={this.handleChange}
                            onColor="#475f87"
                            onHandleColor="#FFFFF"
                            offColor="#FFFFF"
                            offHandleColor="#475f87"
                            handleDiameter={20}
                            uncheckedIcon={true}
                            checkedIcon={true}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                        />
                        <span style={{
                            paddingLeft: '5px',
                            color: '#57b1b1',
                            fontWeight: '500',
                        }}>
                            Light
                        </span>
                    </div>
                    {impersonateEmail && (
                        <div style={{
                            paddingTop: '18px',
                            alignItems: 'center',
                            display: 'flex',
                        }}>
                            <span style={{
                                paddingLeft: '22px',
                                color: '#57b1b1',
                                fontWeight: '500',
                            }}>
                                You are Currently Impersonated as {impersonateEmail}, Click
                                <button
                                    style={{
                                        background: '#343a40',
                                        color: '#57b1b1',
                                        border: 'none',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        fontWeight: '500',
                                    }}
                                    onClick={this.revertImpersonate}>
                                    here
                                </button>
                                to undo Impersonation
                            </span>
                        </div>
                    )}
                </div>
            </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            authenticate,
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(TopNavbar));