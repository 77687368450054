import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";

import { moduleService } from '../../services/modules';
import { practiceService } from '../../services/practices';
import {APP_ROUTES, NOTIFICATION_TYPES, RECORDS_PER_PAGE} from "../../constants/common";
import {AsyncPaginate} from "react-select-async-paginate";
import {createNotification} from "../../utils/notificationManager";

import { createUser, updateUser } from '../../actions/users';
import {userService} from "../../services/users";
import {regionsService} from "../../services/regions";

const REQUIRED_FIELDS = {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    TYPE: 'TYPE',
    PRACTICE:'PRACTICE',
    MODULES: 'MODULES',
    IS_ACTIVE:'IS_ACTIVE',
    REGION:'REGION',
};

class ManageUsers extends React.Component {

    state = {
        errors: [],
        firstName: '',
        lastName: '',
        email: '',
        type: '',
        is_active:'',
        modules: [],
        practice: [],
        region:'',
        isAddAccess:false,
        isUpdateAccess:false,
        isDeleteAccess: false,
    };

    componentDidMount() {
        const userId = this.props.location.state ? this.props.location.state.userId : '';
        if (userId) {
            userService
                .getUserDetail(userId)
                .then(response => {

                    this.setState({
                        firstName: response.data.name,
                        lastName: response.data.last_name,
                        email: response.data.email,
                        type: response.data.type,
                        is_active: response.data.is_active,
                        modules: response.data.modules_users.map(module => {
                            return {
                                label: module.module_name,
                                value: module.id
                            };
                        }),

                        practice: (response.data.practice == null) ? "" : response.data.practice.split(',').map(function (value, index) {
                            var pname = '';
                            if(value == 1){
                                pname = 'BLUE STAR SURGICAL ASSISTANTS LLC';
                            }if(value == 2){
                                pname = 'UNIVERSAL SURGICAL PARTNERS';
                            }if(value == 3){
                                pname = 'XCITE SURGICAL LLC';
                            }

                            return {
                                label: pname,
                                value: value
                            };
                        }),

                        region: {
                            label: (response.data.region != null) ? response.data.region.name : '',
                            value: (response.data.region != null) ? parseInt(response.data.region.id) : ''
                        },
                        //region:response.data.company_id,
                        isAddAccess:response.data.isAddAccess,
                        isUpdateAccess:response.data.isUpdateAccess,
                        isDeleteAccess:response.data.isDeleteAccess,
                    });
                })
        }
    }

    hasErrors = (field) => {
        return this.state.errors.includes(field);
    };

    resetErrors = (field) => {
        this.setState({
            errors: this.state.errors.filter(fieldName => fieldName !== field)
        });
    }

    formatPhone = (str) => {
        if (str) {
            let phone = '';
            str = str.replace(/\D/g, '').substring(0,10);
            if (str.length < 3) {
                phone += str;
            } else if (str.length < 6) {
                phone += str.substring(0, 3) + '-';
                if (str.length > 3) {
                    phone += str.substring(3, str.length);
                }
            } else {
                phone += str.substring(0, 3) + '-' + str.substring(3, 6) + '-' + str.substring(6, 10);
            }
            return phone;
        }
        return str;
    };

    loadModules = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        return moduleService
            .getModules({ params: options })
            .then(response => {
                const options = response.data.map(module => (
                    {
                        value: module.id,
                        label: module.module_name,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    loadPractices = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        return practiceService
            .getPractices({ params: options })
            .then(response => {
                const options = response.data.map(module => (
                    {
                        value: module.id,
                        label: module.paytoname,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    loadRegions = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        return regionsService
            .getRegions({ params: options })
            .then(response => {
                const options = response.data.map(region => (
                    {
                        value: region.id,
                        label: region.name,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    onChangeModule = (value) => {
        this.resetErrors(REQUIRED_FIELDS.MODULES);
        this.setState({ modules: value });
    };

    onChangePractice = (value) => {
        this.resetErrors(REQUIRED_FIELDS.PRACTICE);
        this.setState({ practice: value });
    };

    onChangeRegion = (value) => {
        this.resetErrors(REQUIRED_FIELDS.REGION);
        this.setState({ region: value });
    };

    validateActivity = () => {
        const {
            firstName,
            lastName,
            type,
            practice,
            modules,
            is_active,
            errors,
        } = this.state;

        let isValid = true;

        if (firstName.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.FIRST_NAME] });
            isValid = false;
        } else if (lastName.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.LAST_NAME] });
            isValid = false;
        } else if (type.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.TYPE] });
            isValid = false;
        } else if (practice.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.PRACTICE] });
            isValid = false;
        }else if (modules.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.MODULES] });
            isValid = false;
        }else if (is_active.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.IS_ACTIVE] });
            isValid = false;
        }

        if (!isValid) {
            createNotification(NOTIFICATION_TYPES.ERROR, 'Please select required values');
        }

        return isValid;
    };

    submitUser = () => {
        if (!this.validateActivity()) {
            return;
        }

        const { createUser, updateUser } = this.props;
        const {
            firstName,
            lastName,
            email,
            type,
            practice,
            is_active,
            modules,
            region,
            isAddAccess,
            isUpdateAccess,
            isDeleteAccess,
        } = this.state;

        const request = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            type: type,
            practice: practice,
            is_active: is_active,
            modules: modules,
            region:region,
            isAddAccess:isAddAccess,
            isUpdateAccess:isUpdateAccess,
            isDeleteAccess:isDeleteAccess
        };

        const userId = this.props.location.state ? this.props.location.state.userId : '';
        request.modules = modules.map(module => module.value).join();
        request.practice = practice.map(practice => practice.value).join();
        request.region = region.value;

        if (userId) {
            const payload = {...request, 'id': userId};
            updateUser(payload)
                .then(res => {
                    if (res.response.success) {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                        this.props.history.push(APP_ROUTES.USER_DASHBOARD);
                    } else {
                        createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
                        //this.props.history.push(APP_ROUTES.USER_DASHBOARD);
                    }
                })
                .catch(err => {
                    createNotification(NOTIFICATION_TYPES.ERROR, `Unable to update user ${err.message}`);
                });
        } else {
            createUser(request)
                .then(res => {
                    if (res.response.success) {
                        this.props.history.push(APP_ROUTES.USER_DASHBOARD);
                    } else {
                        createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
                        //this.props.history.push(APP_ROUTES.USER_DASHBOARD);
                    }
                })
                .catch(err => {
                    createNotification(NOTIFICATION_TYPES.ERROR, `Unable to create user ${err.message}`);
                });
        }
    };

    toggleCheckbox = (name, event) =>{
        let obj = {};
        obj[name] = !this.state[name];
        this.setState(obj);
    }

    render() {
        const userId = this.props.location.state ? this.props.location.state.userId : '';
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar />
                </div>
                <TopNavbar />
                <div className="manage-activity-right-col">
                    <div className="activity-row">
                        <div className="page-title">
                            {userId &&
                                <h2 className="heading-custom">Edit User</h2>
                            }
                            {!userId &&
                                <h2 className="heading-custom">Add User</h2>
                            }
                        </div>
                        <div className="col-md-4 mrgn-btm10">
                            <label>First Name <span className="required">*</span></label>
                            <input
                                type="text"
                                className={this.hasErrors(REQUIRED_FIELDS.FIRST_NAME) ? 'error code-input' : 'code-input'}
                                value={this.state.firstName}
                                onChange={event => {
                                    this.resetErrors(REQUIRED_FIELDS.FIRST_NAME);
                                    this.setState({ firstName: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-4 mrgn-btm10">
                            <label>Last Name <span className="required">*</span></label>
                            <input
                                type="text"
                                className={this.hasErrors(REQUIRED_FIELDS.LAST_NAME) ? 'error code-input' : 'code-input'}
                                value={this.state.lastName}
                                onChange={event => {
                                    this.resetErrors(REQUIRED_FIELDS.LAST_NAME);
                                    this.setState({ lastName: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-4 mrgn-btm10">
                            <label>Email <span className="required">*</span></label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.email}
                                onChange={event => {
                                    this.setState({ email: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-4 mrgn-btm10">
                            <label>User Type <span className="required">*</span></label>
                            <select
                                className={this.hasErrors(REQUIRED_FIELDS.TYPE) ? 'error code-input' : 'code-input'}
                                onChange={event => this.setState({ type: event.target.value })}
                                value={this.state.type}>
                                <option value="">Please Select User Type</option>
                                <option value="admin">Admin</option>
                                <option value="assistant">Surgical Assistant</option>
                                <option value="biller">Biller</option>
                                <option value="data-entry">Data Entry</option>
                                <option value="manager">Manager</option>
                            </select>
                        </div>

                        { this.state.type === 'assistant' && (

                            <div className="col-md-4 mrgn-btm10">
                                <label>Region <span className="required">*</span></label>

                                <AsyncPaginate
                                    ref="region"
                                    className={this.hasErrors(REQUIRED_FIELDS.REGION) ? 'error ' : ''}
                                    placeholder="Please Select Region"
                                    value={this.state.region}
                                    loadOptions={this.loadRegions}
                                    onChange={this.onChangeRegion}
                                    autoComplete="off"
                                />

                            </div>
                        )}

                        <div className="col-md-4 mrgn-btm10">
                            <label>Practice <span className="required">*</span></label>

                            <AsyncPaginate
                                ref="practice"
                                className={this.hasErrors(REQUIRED_FIELDS.PRACTICE) ? 'error ' : ''}
                                placeholder="Please Select Practice"
                                isMulti
                                value={this.state.practice}
                                loadOptions={this.loadPractices}
                                onChange={this.onChangePractice}
                            />

                        </div>

                        <div className="col-md-4 mrgn-btm10">
                            <label>Active <span className="required">*</span></label>
                            <select
                                className={this.hasErrors(REQUIRED_FIELDS.IS_ACTIVE) ? 'error code-input' : 'code-input'}
                                onChange={event => this.setState({ is_active: event.target.value })}
                                value={this.state.is_active}>
                                <option value="">Please Select</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </select>
                        </div>

                        <div className="col-md-12 mrgn-btm10">
                            <label>Module Access <span className="required">*</span></label>
                            <AsyncPaginate
                                ref="modules"
                                className={this.hasErrors(REQUIRED_FIELDS.MODULES) ? 'error ' : ''}
                                placeholder="Select Event Modules..."
                                isMulti
                                value={this.state.modules}
                                loadOptions={this.loadModules}
                                onChange={this.onChangeModule}
                            />
                        </div>

                        <div className="col-md-12 mrgn-btm10">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>Access</th>
                                    <th>Add</th>
                                    <th>Update</th>
                                    <th>Delete</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Module</td>
                                    <td><input type="checkbox" id="isAddAccess" name="isAddAccess" value="1" checked={this.state.isAddAccess} onChange={() =>this.toggleCheckbox('isAddAccess')}/></td>
                                    <td><input type="checkbox" id="isUpdateAccess" name="isUpdateAccess" value="1" checked={this.state.isUpdateAccess} onChange={() =>this.toggleCheckbox('isUpdateAccess')} /></td>
                                    <td><input type="checkbox" id="isDeleteAccess" name="isDeleteAccess" value="1" checked={this.state.isDeleteAccess} onChange={() =>this.toggleCheckbox('isDeleteAccess')}/></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="filter-footer">
                        {userId &&
                        <button
                            onClick={this.submitUser}
                            className="filter-save-button">
                            Update
                        </button>
                        }
                        {!userId &&
                        <button
                            onClick={this.submitUser}
                            className="filter-save-button">
                            Submit
                        </button>
                        }
                        <button
                            onClick={() => { this.props.history.push(APP_ROUTES.USER_DASHBOARD); }}
                            className="filter-cancel-button">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createUser,
            updateUser,
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(ManageUsers));