import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import {API_CONSTANTS} from "../constants/api";

function getSpecialities(options) {
    return apiClient()
        .get(API_CONSTANTS.LIST_SPECIALITY, options)
        .then(handleResponse)
        .catch(handleError);
}

export const specialityService = {
    getSpecialities,
}