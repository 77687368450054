import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { API_CONSTANTS } from "../constants/api";

function getEventTypes(options) {
    return apiClient()
        .get(API_CONSTANTS.LIST_EVENT_TYPES, options)
        .then(handleResponse)
        .catch(handleError);
}

export const eventTypesService = {
    getEventTypes,
}