import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import configureStore from "./javascript/configureStore";
import App from './javascript/components/environments/App';

import { Providers } from '@microsoft/mgt-element';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';
import {LOCAL_STORAGE_KEYS} from "./javascript/constants/common";

const LightTheme = React.lazy(() => import('./lightTheme'));
const DarkTheme = React.lazy(() => import('./darkTheme'));

const ThemeSelector = ({ children }) => {
    const loggedInUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER));
    const theme = loggedInUser ? loggedInUser.theme : 'dark';
    return (
        <>
            <React.Suspense fallback={<></>}>
                {(theme === 'light') && <LightTheme />}
                {(theme === 'dark') && <DarkTheme />}
            </React.Suspense>
            {children}
        </>
    )
}

Providers.globalProvider = new Msal2Provider({
    clientId: 'dcfa1fb7-ea4c-4e0d-9ef1-a92ff9ce7569',
    authority: 'https://login.microsoftonline.com/9427176d-8fc3-4a4f-b926-7effae45d114',
});

ReactDOM.render(
    <Provider store={configureStore}>
        <ThemeSelector>
            <App />
            <NotificationContainer/>
        </ThemeSelector>
    </Provider>,
    document.getElementById("root")
);
