import React from 'react';
import PropTypes from 'prop-types';
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import {APP_ROUTES, BILLING_LISTING_COLUMNS} from '../../constants/common';
import ScanFlowListingTable from '../atom/ScanFlowListingTable';
import {bindActionCreators} from "redux";
import { fetchBilling } from "../../actions/scanFlow";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import '../../../sass/activities.scss';
import '../../../sass/dashboard.scss';
import {formatDate} from "../../utils/date";
import Loader from "../atom/Loader";

class ScanFlowDashboard extends React.Component {
    static propTypes = {
        authInfo: PropTypes.object.isRequired,
        totalRecords: PropTypes.number.isRequired,
        billings: PropTypes.array.isRequired,
        fetchBilling: PropTypes.func.isRequired,
    };


    state = {
        loading: false,
        currentPage: 1,
        checkedBillings: [],
        allChecked: false,
    };

    componentDidMount() {
        this.setState({loading: true});
        const options = {
            page: this.state.currentPage,
        };
        this.props.fetchBilling({ params: options })
            .then(res => {
                this.setState({loading: false});
            });
    }

    addCheckedItem = (activity) => {
        this.setState(prevState => ({
            checkedBillings: [...prevState.checkedBillings, activity]
        }))
    };

    addAllCheckedItem = () => {
        const { billings = [] } = this.props;
        this.setState({
            checkedBillings: billings.map(activity => activity.activity_id),
            allChecked: true,
        });
    };

    removeAllCheckedItem = () => {
        this.setState({
            checkedBillings: [],
            allChecked: false,
        });
    };

    removeCheckedItem = (activityId) => {
        this.setState(prevState => ({
            checkedBillings: prevState.checkedBillings.filter(activity => activity !== activityId)
        }));
        this.setState({allChecked: false});
    };

    goToPage = (page) => {
        this.setLoading(true);
        const options = {
            page: page < 0 ? 1 : page,
        };
        this.setCurrentPage(options.page);
        this.props.fetchBilling({ params: options })
            .then(res => {
                this.setLoading(false);
            });
    };

    setLoading = (flag) => {
        this.setState({ loading: flag });
    };

    setCurrentPage = (num) => {
        this.setState({ currentPage: num });
    };

    list = () => {
        const { billings = [] } = this.props;
        return billings.map(activity => {
            return [
                activity.activity_id,
                {
                    eventId: activity.activity_id,
                    patientId: activity.patient ? activity.patient.id : '',
                    file: activity.facesheets && activity.facesheets.length > 0 ? activity.facesheets[0].facesheet_file : '',
                },
                activity.event_start_date ? formatDate(activity.event_start_date) : '-',
                {
                    eventId: activity.activity_id,
                    patientId: activity.patient ? activity.patient.id : '',
                    opId: activity.op && activity.op.length > 0 ? activity.op[0].id : '',
                    file: activity.op && activity.op.length > 0 ? activity.op[0].op_file : '',
                },
                {
                    eventId: activity.activity_id,
                    patientId: activity.patient ? activity.patient.id : '',
                    insuranceId: activity.insurace_file && activity.insurace_file.length > 0 ? activity.insurace_file[0].id : '',
                    file: activity.insurace_file && activity.insurace_file.length > 0 ? activity.insurace_file[0].insurance_file : '',
                },
                `${activity.patient ? activity.patient.first_name.toUpperCase() : ''} ${activity.patient ? activity.patient.last_name.toUpperCase() : ''}`,
                `${activity.assistant ? activity.assistant.name.toUpperCase() : ''} ${activity.assistant ? activity.assistant.last_name.toUpperCase() : ''}`,
                activity.facility ? activity.facility.facility_name.toUpperCase() : '',
                `${activity.practice_assignor ? activity.practice_assignor.name.toUpperCase() : ''} ${activity.practice_assignor ? activity.practice_assignor.last_name.toUpperCase() : ''}`,
                activity.bill_status ? activity.bill_status.toUpperCase() : '-',
                activity.patient && activity.patient.patient_insurance_data && activity.patient.patient_insurance_data.primary ? activity.patient.patient_insurance_data.primary.description.toUpperCase() : '-',
                activity.practice ? activity.practice.paytoname.toUpperCase() : '-',
                activity.patient ? activity.patient.id : '',
                `${activity.assign_reg ? activity.assign_reg.name.toUpperCase() : ''} ${activity.assign_reg ? activity.assign_reg.last_name.toUpperCase() : ''}`,
                activity.reg_status ? activity.reg_status.toUpperCase() : '-',
                `${activity.assign_coder ? activity.assign_coder.name.toUpperCase() : ''} ${activity.assign_coder ? activity.assign_coder.last_name.toUpperCase() : ''}`,
                activity.coding_status ? activity.coding_status.toUpperCase() : '-',
                `${activity.assign_charge ? activity.assign_charge.name.toUpperCase() : ''} ${activity.assign_charge ? activity.assign_charge.last_name.toUpperCase() : ''}`,
                activity.charge_status ? activity.charge_status.toUpperCase() : '-',
                `${activity.assign_claim ? activity.assign_claim.name.toUpperCase() : ''} ${activity.assign_claim ? activity.assign_claim.last_name.toUpperCase() : ''}`,
                activity.claim_status ? activity.claim_status.toUpperCase() : '-',
                activity.hosp_contract ? activity.hosp_contract.toUpperCase() : '-',
                activity.assistant && activity.assistant.region ? activity.assistant.region.name.toUpperCase() : '-',
                formatDate(activity.created_at),
            ];
        });
    };

    edit = () => {
        this.props.history.push({
            pathname: APP_ROUTES.EDIT_SCAN_FLOW,
            state: {
                activePage: this.props.activePage || this.props.location.state.activePage,
                checkedBillings: this.state.checkedBillings,
            }
        })
    };

    render() {
        const activePage = this.props.activePage || this.props.location.state.activePage;
        return (
            <div className="main_container">
                <div
                    style={{
                        minHeight: '125vh',
                    }}
                    className="col-md-3 custom-sidebar-menu">
                    <Sidebar activePage={activePage} />
                </div>
                <TopNavbar />
                <div
                    style={{
                        minHeight: '760px'
                    }}
                    className="right-col">
                    <div className="page-title">
                        <div className="title_left" style={{
                            width: '100%',
                        }}>
                            <h3 style={{
                                fontFamily: 'Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif',
                                fontSize: '24px',
                                fontWeight: '400',
                                lineHeight: '1.471',
                            }}>Billing</h3>
                        </div>
                    </div>
                    { this.state.checkedBillings.length > 0 && (
                        <div className="filter-footer-header">
                            <button
                                onClick={this.edit}
                                className="filter-button">
                                Edit
                            </button>
                        </div>
                    )}
                    <div className="row">
                        <div className="activities-header">
                            {this.state.loading && (
                                <Loader width='100%' />
                            )}
                            {!this.state.loading && (
                                <ScanFlowListingTable
                                    checkedBillings={this.state.checkedBillings}
                                    isLoading={this.state.loading}
                                    allChecked={this.state.allChecked}
                                    currentPage={this.state.currentPage}
                                    goToPage={this.goToPage}
                                    addCheckedItem={this.addCheckedItem}
                                    removeCheckedItem={this.removeCheckedItem}
                                    addAllCheckedItem={this.addAllCheckedItem}
                                    removeAllCheckedItem={this.removeAllCheckedItem}
                                    headColumn={BILLING_LISTING_COLUMNS}
                                    listing={this.list()}
                                    totalRecords={this.props.totalRecords}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        billings: state.billingReducer.billings,
        totalRecords: state.billingReducer.totalBillings,
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchBilling,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(ScanFlowDashboard));