import React from 'react';
import PropTypes from 'prop-types';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import {ACTIVITY_LISTING_COLUMNS, APP_ROUTES, LOCAL_STORAGE_KEYS} from '../../constants/common';
import { fetchActivities, deleteActivity } from '../../actions/activity';
import { activityService } from "../../services/activities";

import '../../../sass/activities.scss';
import '../../../sass/dashboard.scss';
import Loader from '../atom/Loader';
import ListingTable from '../atom/ListingTable';
import Sidebar from '../organisms/Sidebar';
import TopNavbar from "../organisms/TopNavbar";
import ActivityFilters from '../organisms/ActivityFilters';
import {formatDate} from "../../utils/date";

class ActivityDashboard extends React.Component {
    static propTypes = {
        authInfo: PropTypes.object.isRequired,
        totalRecords: PropTypes.number.isRequired,
        activities: PropTypes.array.isRequired,
        fetchActivities: PropTypes.func.isRequired,
        deleteActivity: PropTypes.func.isRequired,
    };

    state = {
        loading: false,
        currentPage: 1,
        fromDate: '',
        toDate: '',
        region: {
            value: '',
            label: 'Select Region',
        },
        assistant: {
            value: '',
            label: 'Select Assistant',
        },
        surgeon: {
            value: '',
            label: 'Select Surgeon',
        },
        facility: {
            value: '',
            label: 'Select Facility',
        },
        patient: {
            value: '',
            label: 'Select Patient',
        },
        status: {
            value: '',
            label: 'Select Status',
        },
    };

    componentWillMount() {
        this.setState({loading: true});
        const options = {
            page: this.state.currentPage,
        };
        this.props.fetchActivities({ params: options })
            .then(res => {
                this.setState({loading: false});
            });
    }

    componentDidMount() {
        const loggedInUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER));
        if (loggedInUser.type === 'assistant') {
            this.setState({
                assistant: {
                    value: loggedInUser.id,
                    label: `${loggedInUser.name} ${loggedInUser.last_name}`,
                },
            }, () => {
                this.search();
            })
        } else if (loggedInUser.type === 'company') {
            this.setState({
                region: {
                    value: loggedInUser.company_id,
                    label: '',
                },
            }, () => {
                this.search();
            })
        }
    }

    search = () => {
        this.setState({ loading: true });
        this.setCurrentPage(1);
        const options = {
            page: this.state.currentPage,
        };
        this.props.fetchActivities({ params: this.addFilters(options) })
            .then(res => {
                this.setState({ loading: false });
            });
    };

    addFilters = (options) => {
        const {
            fromDate,
            toDate,
            region,
            assistant,
            surgeon,
            facility,
            patient,
            status,
        } = this.state;

        if (fromDate) {
            options = {...options, from_date: formatDate(fromDate)};
        }
        if (toDate) {
            options = {...options, to_date: formatDate(toDate)};
        }
        if (region.value) {
            options = {...options, company_id: region.value};
        }
        if (assistant.value) {
            options = {...options, assistant_id: assistant.value};
        }
        if (surgeon.value) {
            options = {...options, physician_id: surgeon.value};
        }
        if (facility.value) {
            options = {...options, facility_id: facility.value};
        }
        if (patient.value) {
            options = {...options, patient_id: patient.value};
        }
        if (status.value) {
            options = {...options, status: status.value};
        }
        return options;
    };

    setLoading = (flag) => {
        this.setState({ loading: flag });
    };

    setCurrentPage = (num) => {
        this.setState({ currentPage: num });
    };

    goToPage = (page) => {
        this.setLoading(true);
        const options = {
            page: page < 0 ? 1 : page,
        };
        this.setCurrentPage(options.page);
        this.props.fetchActivities({ params: this.addFilters(options) })
            .then(res => {
                this.setLoading(false);
            });
    };

    list = () => {
        const { activities = [] } = this.props;
        return activities.map(activity => {
            const procedureName = activity.procedures ? activity.procedures.map(procedure => procedure.procedure_name).join(', ') : null;
            return [
                `${activity.assistant ? activity.assistant.name : ''} ${activity.assistant ? activity.assistant.last_name : ''}`,
                `${activity.patient ? activity.patient.first_name : ''} ${activity.patient ? activity.patient.last_name : ''}`,
                `${activity.surgeon ? activity.surgeon.first_name : ''} ${activity.surgeon ? activity.surgeon.last_name : ''}`,
                activity.facility ? activity.facility.facility_name : '',
                procedureName ? procedureName : '',
                activity.event_type ? activity.event_type.event_type_name : '',
                `${activity.event_start_date} - ${activity.event_end_date}`,
                activity.activity_id,
                activity.status,
            ];
        });
    };

    showManageActivity = activityId => this.props.history.push({
        pathname: APP_ROUTES.EDIT_ACTIVITY,
        state: {
            activePage: this.props.activePage || this.props.location.state.activePage,
            activityId,
        }
    });

    deleteActivity = (item) => {
        activityService
            .deleteActivity(item)
            .then(response => {
                this.goToPage(1);
            });
    };

    setFromDate = (date) => {
        this.setState({ fromDate: date });
    };

    setToDate = (date) => {
        this.setState({ toDate: date });
    };

    setRegion = (value) => {
        this.setState({ region: value });
    };

    setAssistant = (value) => {
        this.setState({ assistant: value });
    };

    setSurgeon = (value) => {
        this.setState({ surgeon: value });
    };

    setFacility = (value) => {
        this.setState({ facility: value });
    };

    setPatient = (value) => {
        this.setState({ patient: value });
    };

    setStatus = (value, page) => {
        this.setState({ status: value }, () => {
            if (page) {
                this.goToPage(page);
            }
        });
    };

    render() {
        const activePage = this.props.activePage || this.props.location.state.activePage;
        return(
            <div className="activity-main-container">
                <div className="col-md-3 custom-sidebar-menu"
                     style={{
                         minHeight: '125vh',
                     }}>
                    <Sidebar activePage={activePage} />
                </div>
                <TopNavbar />
                <div className="activities-right-col">
                    <ActivityFilters
                        goToPage={this.goToPage}
                        search={this.search}
                        setFromDate={this.setFromDate}
                        setToDate={this.setToDate}
                        setRegion={this.setRegion}
                        setAssistant={this.setAssistant}
                        setSurgeon={this.setSurgeon}
                        setFacility={this.setFacility}
                        setPatient={this.setPatient}
                        setStatus={this.setStatus}
                        fromDate={this.state.fromDate}
                        toDate={this.state.toDate}
                        region={this.state.region}
                        assistant={this.state.assistant}
                        surgeon={this.state.surgeon}
                        facility={this.state.facility}
                        patient={this.state.patient}
                        status={this.state.status}
                    />
                    <div className="activities-row">
                        <div className="activities-header">
                            {this.state.loading && (
                                <Loader width='100%' />
                            )}
                            {!this.state.loading && (
                                <ListingTable
                                    currentPage={this.state.currentPage}
                                    goToPage={this.goToPage}
                                    edit={this.showManageActivity}
                                    delete={this.deleteActivity}
                                    headColumn={ACTIVITY_LISTING_COLUMNS}
                                    listing={this.list()}
                                    totalRecords={this.props.totalRecords}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        activities: state.activityReducer.activities,
        totalRecords: state.activityReducer.totalActivities,
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchActivities,
            deleteActivity,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(ActivityDashboard));