import { API_CONSTANTS } from "../constants/api";
import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";

function authentication(email) {
    return apiClient()
        .post(API_CONSTANTS.AUTHENTICATE, email)
        .then(handleResponse)
        .catch(handleError);
}

function updateTheme(theme) {
    return apiClient()
        .post(API_CONSTANTS.UPDATE_THEME, theme)
        .then(handleResponse)
        .catch(handleError);
}

export const authenticationService = {
    authentication,
    updateTheme,
}