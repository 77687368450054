import { apiClient } from "./apiClients";
import { API_CONSTANTS } from "../constants/api";
import { handleError, handleResponse } from "./GenericResponseHandler";

function getFacilities(options) {
    return apiClient()
        .get(API_CONSTANTS.LIST_FACILITIES, options)
        .then(handleResponse)
        .catch(handleError);
}

function deleteFacility(facility) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_FACILITY + facility)
        .then(handleResponse)
        .catch(handleError);
}

function getFacilityDetail(facility) {
    return apiClient()
        .get(API_CONSTANTS.FACILITY_DETAIL + facility)
        .then(handleResponse)
        .catch(handleError);
}

function createFacility(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_FACILITY, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateFacility(payload) {
    return apiClient()
        .post(API_CONSTANTS.UPDATE_FACILITY, payload)
        .then(handleResponse)
        .catch(handleError);
}

export const facilityService = {
    getFacilities,
    deleteFacility,
    createFacility,
    updateFacility,
    getFacilityDetail,
}