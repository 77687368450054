import React from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { submitActivity, updateActivity } from "../../actions/activity";

import { AsyncPaginate } from "react-select-async-paginate";
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';

import { patientService } from '../../services/patients';
import { activityService } from '../../services/activities';
import { procedureService } from '../../services/procedures';
import { surgeonService } from '../../services/surgeons';
import { facilityService } from '../../services/facilities';
import { assistantService } from '../../services/assistants';
import { eventFlagService } from '../../services/eventFlags';
import { eventTypesService } from '../../services/eventTypes';

import {
    APP_ROUTES,
    LOCAL_STORAGE_KEYS,
    NOTIFICATION_TYPES,
    RECORDS_PER_PAGE,
} from "../../constants/common";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import AddPatient from "../atom/AddPatient";
import AddProcedure from "../atom/AddProcedure";
import { formatDate, formatAMPM, format24Hours } from '../../utils/date';

import "react-datepicker/dist/react-datepicker.css";
import '../../../sass/manageactivities.scss';
import { createNotification } from "../../utils/notificationManager";
import deleteIcon from "../../../assets/images/delete.png";
import Swal from "sweetalert2";
import viewIcon from "../../../assets/images/view.png";

const REQUIRED_FIELDS = {
    PATIENT: 'patient',
    EVENT_TYPE: 'eventType',
    SELECTED_PROCEDURES: 'selectedProcedures',
    SURGEON: 'surgeon',
    FACILITY: 'facility',
    ASSISTANT: 'assistant',
};

class ManageActivity extends React.Component {
    static propTypes = {
        submitActivity: PropTypes.func.isRequired,
        updateActivity: PropTypes.func.isRequired,
        createProcedure: PropTypes.func.isRequired,
        screen: PropTypes.string,
    };

    state = {
        showAddPatient: false,
        showAddProcedure: false,
        patient: {},
        file: null,
        fileType: '',
        allSheets: [],
        selectedProcedures: [],
        surgeon: {},
        facility: {},
        assistant: {},
        startDate: Date.now(),
        endDate: '',
        startTime: '10:00',
        endTime: '',
        eventType: {},
        eventFlags: [],
        diagnosisCodes: '',
        cptCodes: '',
        status: {},
        notes: '',
        fields: {},
        errors: [],
    };

    hasErrors = (field) => {
        return this.state.errors.includes(field);
    };

    getStatusName = (id) => {
        if (id === 'new') {
            return 'New Schedule';
        } else if (id === 'started') {
            return 'Surgery In Progress';
        } else if (id === 'completed') {
            return 'Surgery Completed';
        } else if (id === 'cancelled') {
            return 'Cancelled Surgery';
        }
    };

    componentDidMount() {
        const activityId = this.props.location.state ? this.props.location.state.activityId : '';
        if (activityId) {
            activityService
                .getActivityDetail(activityId)
                .then(response => {
                    this.setState({
                        eventType: {
                            label: response.data.event_type.event_type_name,
                            value: parseInt(response.data.event_type.id)
                        },
                        selectedProcedures: response.data.procedures.map(procedure => {
                            return {
                                label: procedure.procedure_name,
                                value: procedure.id
                            };
                        }),
                        patient: {
                            label: response.data.patient ? `${response.data.patient.first_name} ${response.data.patient.last_name}` : '',
                            value: response.data.patient ? parseInt(response.data.patient.id) : '',
                        },
                        surgeon: {
                            label: `${response.data.surgeon.first_name} ${response.data.surgeon.last_name}`,
                            value: parseInt(response.data.surgeon.id)
                        },
                        facility: {
                            label: response.data.facility.facility_name,
                            value: parseInt(response.data.facility.id)
                        },
                        assistant: {
                            label: `${response.data.assistant.name} ${response.data.assistant.last_name}`,
                            value: parseInt(response.data.assistant.id)
                        },
                        startDate: Date.parse(formatDate(response.data.event_start_date)),
                        startTime: format24Hours(response.data.event_start_time),
                        endDate: response.data.event_end_date ? Date.parse(formatDate(response.data.event_end_date)) : '',
                        endTime: (response.data.event_end_time !== '' && response.data.event_end_time !== 'null' && response.data.event_end_time !== null)
                            ? format24Hours(response.data.event_end_time)
                            : '',
                        diagnosisCodes: response.data.diagnosis_codes || '',
                        cptCodes: response.data.cpt_codes || '',
                        status: {
                            label: this.getStatusName(response.data.status),
                            value: response.data.status
                        },
                        notes: response.data.event_notes || '',
                        eventFlags: response.data.event_flag && response.data.event_flag.map(event_flag => {
                            return {
                                label: event_flag.schedule_flag,
                                value: event_flag.id
                            };
                        }),
                        allSheets: response.data.all_sheets.length > 0 ? response.data.all_sheets : [],
                    })
                });
        }
    }

    loadProcedures = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        return procedureService
            .getProcedures({ params: options })
            .then(response => {
                const options = response.data.map(procedure => (
                    {
                        value: procedure.id,
                        label: procedure.procedure_name,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    loadSurgeon = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        return surgeonService
            .getSurgeons({ params: options })
            .then(response => {
                const options = response.data.map(surgeon => (
                    {
                        value: surgeon.id,
                        label: `${surgeon.first_name} ${surgeon.last_name}`,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    loadFacility = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        return facilityService
            .getFacilities({ params: options })
            .then(response => {
                const options = response.data.map(facility => (
                    {
                        value: facility.id,
                        label: facility.facility_name,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    loadAssistant = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        return assistantService
            .getAssistants({ params: options })
            .then(response => {
                const options = response.data.map(assistant => (
                    {
                        value: assistant.assistant_id,
                        label: `${assistant.NAME} ${assistant.last_name}`,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    loadPatient = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        return patientService
            .getPatients({ params: options })
            .then(response => {
                const options = response.data.map(patient => (
                    {
                        value: patient.id,
                        label: `${patient.first_name} ${patient.last_name}`,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    loadEventTypes = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        return eventTypesService
            .getEventTypes({ params: options })
            .then(response => {
                const options = response.data.map(eventType => (
                    {
                        value: eventType.id,
                        label: eventType.event_type_name,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    loadEventFlags = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        return eventFlagService
            .getEventFlags({ params: options })
            .then(response => {
                const options = response.data.map(eventFlag => (
                    {
                        value: eventFlag.id,
                        label: eventFlag.schedule_flag,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    onChangeProcedure = (value) => {
        this.resetErrors(REQUIRED_FIELDS.SELECTED_PROCEDURES);
        this.setState({ selectedProcedures: value });
    };

    onChangeSurgeon = (value) => {
        this.resetErrors(REQUIRED_FIELDS.SURGEON);
        this.setState({ surgeon: value });
    };

    onChangeFacility = (value) => {
        this.resetErrors(REQUIRED_FIELDS.FACILITY);
        this.setState({ facility: value });
    };

    onChangeAssistant = (value) => {
        this.resetErrors(REQUIRED_FIELDS.ASSISTANT);
        this.setState({ assistant: value });
    };

    setStartDate = (value) => {
        this.setState({ startDate: value });
    };

    setEndDate = (value) => {
        this.setState({ endDate: value });
    };

    setStartTime = (value) => {
        this.setState({ startTime: value });
    };

    setEndTime = (value) => {
        this.setState({ endTime: value });
    };

    onChangeEventType = (value) => {
        this.resetErrors(REQUIRED_FIELDS.EVENT_TYPE);
        this.setState({ eventType: value });
    };

    onChangePatient = (value) => {
        this.resetErrors(REQUIRED_FIELDS.PATIENT);
        this.setState({ patient: value });
    };

    onChangeEventFlags = (value) => {
        this.setState({ eventFlags: value });
    };

    onChangeStatus = (value) => {
        this.setState({ status: value });
    };

    validateActivity = () => {
        const {
            patient,
            eventType,
            selectedProcedures,
            surgeon,
            facility,
            assistant,
            errors,
        } = this.state;
        let isValid = true;

        if (Object.keys(patient).length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.PATIENT] });
            isValid = false;
        } else if (Object.keys(eventType).length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.EVENT_TYPE] });
            isValid = false;
        } else if (selectedProcedures.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.SELECTED_PROCEDURES] });
            isValid = false;
        } else if( Object.keys(surgeon).length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.SURGEON] });
            isValid = false;
        } else if(Object.keys(facility).length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.FACILITY] });
            isValid = false;
        } else if(Object.keys(assistant).length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.ASSISTANT] });
            isValid = false;
        }

        if (!isValid) {
            createNotification(NOTIFICATION_TYPES.ERROR, 'Please select required values');
        }

        return isValid;
    };

    resetErrors = (field) => {
        this.setState({
            errors: this.state.errors.filter(fieldName => fieldName !== field)
        });
    }

    submitActivity = () => {

        if (!this.validateActivity()) {
            return;
        }

        const { submitActivity, updateActivity } = this.props;
        const {
            patient,
            file,
            fileType,
            selectedProcedures,
            surgeon,
            facility,
            assistant,
            startDate,
            endDate,
            startTime,
            endTime,
            eventType,
            eventFlags,
            diagnosisCodes,
            cptCodes,
            notes,
            status,
        } = this.state;

        const loggedInUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER));

        const formData = new FormData();
        formData.append('user_id', loggedInUser.id);
        formData.append('event_surgeon', surgeon.value);
        formData.append('file', file);
        formData.append('file_type', fileType);
        formData.append('patient_id', patient.value);
        formData.append('procedure_id', selectedProcedures.map(procedure => procedure.value).join());
        formData.append('event_facility', facility.value);
        formData.append('event_surgical_assitant', assistant.value);
        formData.append('cpt_codes', cptCodes);
        formData.append('cpt_codes_notes', '');
        formData.append('diagnosis_codes', diagnosisCodes);
        formData.append('event_start_date', formatDate(startDate));
        formData.append('event_end_date', (endDate != '') ? formatDate(endDate) : '');
        formData.append('event_start_time', formatAMPM(startTime));
        formData.append('event_end_time', (endTime !== '') ? formatAMPM(endTime) : '');
        formData.append('event_type', eventType.value);
        formData.append('event_flags', eventFlags.map(eventFlag => eventFlag.value).join());
        formData.append('event_notes', notes);

        const activityId = this.props.location.state ? this.props.location.state.activityId : '';
        const screen = this.props.location.state ? this.props.location.state.screen : '';
        if (activityId) {
            formData.append('id', activityId);
            formData.append('status', status.value);
            updateActivity(formData)
                .then(res => {
                    if (res.response.data.success) {
                        this.props.history.push(APP_ROUTES.ACTIVITY_DASHBOARD);
                    } else {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                        if(screen) {
                            this.props.history.push(screen);
                        } else {
                            this.props.history.push(APP_ROUTES.ACTIVITY_DASHBOARD);
                        }
                    }
                })
                .catch(err => {
                    createNotification(NOTIFICATION_TYPES.ERROR, `Unable to update activity ${err.message}`);
                });
        } else {
            submitActivity(formData)
                .then(res => {
                    if (res.response.data.success) {
                        this.props.history.push(APP_ROUTES.ACTIVITY_DASHBOARD);
                    } else {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                        this.props.history.push(APP_ROUTES.ACTIVITY_DASHBOARD);
                    }
                })
                .catch(err => {
                    createNotification(NOTIFICATION_TYPES.ERROR, `Unable to create activity ${err.message}`);
                });
        }
    };

    toggleAddPatient = (flag) => {
        this.setState({showAddPatient: flag});
    };

    toggleAddProcedure = (flag) => {
        this.setState({showAddProcedure: flag});
    };

    setPatient = (value) => {
        this.resetErrors(REQUIRED_FIELDS.PATIENT);
        this.setState({ patient: value });
    };

    setProcedure = (value) => {
        this.resetErrors(REQUIRED_FIELDS.SELECTED_PROCEDURES);
        this.setState(prevstate =>
            ({
                selectedProcedures: [...prevstate.selectedProcedures, value]
            }));
    };

    loadStatus = () => {
        const options = [
            {
                value: 'new',
                label: 'New Schedule',
            },
            {
                value: 'started',
                label: 'Surgery In Progress',
            },
            {
                value: 'completed',
                label: 'Surgery Completed',
            },
            {
                value: 'cancelled',
                label: 'Cancelled Surgery',
            },
        ];

        return {
            options: options,
            hasMore: false,
        }
    };

    onFileChangeHandler = event => {
        this.setState({file: event.target.files[0]});
    };

    cancel = () => {
        const screen = this.props.location.state ? this.props.location.state.screen : '';
        if(screen) {
            this.props.history.push(screen);
        } else {
            this.props.history.push(APP_ROUTES.ACTIVITY_DASHBOARD);
        }
    };

    renderHeadColumn = () => {
        const headColumn = [
            'File Name',
            'Type',
            'Action',
        ];

        return headColumn.map(column => (
            <th className="listing-table-head-column">
                {column}
            </th>
        ));
    };

    deleteFile = (faceSheetId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                activityService
                    .deleteActivityFaceSheet(faceSheetId)
                    .then(response => {
                        this.resetErrors();
                    });
            }
        })
    };

    renderSheetList = () => {
        const { allSheets } = this.state;
        return allSheets.map(sheet => {
            const facesheetFile = sheet.facesheet_file.split('/');
            const fileName = facesheetFile.length > 0 ? facesheetFile[facesheetFile.length - 1] : '';
            const sheetDetails = [
                fileName,
                sheet.type,
                sheet.id
            ];
            const item = sheetDetails.map((item, index) => {
                if (index === 2) {
                    return (
                        <td className="listing-table-column">
                            <a
                                href={sheet.facesheet_file}
                                target = "_blank">
                                <img
                                    src={viewIcon}
                                    alt="view"
                                />
                            </a>
                            <img
                                src={deleteIcon}
                                onClick={() => { this.deleteFile(item) }}
                                alt="view"
                            />
                        </td>
                    );
                } else {
                    return (
                        <td className="listing-table-column">
                            {item}
                        </td>
                    );
                }
            });
            return (
                <tr className="listing-table-rows">
                    {item}
                </tr>
            );
        });
    };

    render() {
        const activityId = this.props.location.state ? this.props.location.state.activityId : '';
        const activePage = this.props.location.state ? this.props.location.state.activePage : '';
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col"
                     style={{
                         minHeight: '145vh',
                     }}>
                    <Sidebar activePage={activePage} />
                </div>
                <TopNavbar />
                <div className="manage-activity-right-col">
                    <div className="activity-row">
                        <div className="page-title">
                            {activityId &&
                                <h2 className="heading-custom">Edit Activity</h2>
                            }
                            {!activityId &&
                                <h2 className="heading-custom">Add Activity</h2>
                            }
                        </div>
                        <div className="col-md-12 mrgn-btm10">
                            <label>Patient <span className="required">*</span></label>
                            { !this.state.showAddPatient && (
                                <AsyncPaginate
                                    placeholder="Select Patient..."
                                    className={this.hasErrors(REQUIRED_FIELDS.PATIENT) ? 'error' : ''}
                                    value={this.state.patient}
                                    loadOptions={this.loadPatient}
                                    onChange={this.onChangePatient}
                                />
                            )}
                            { !this.state.showAddPatient && (
                                <div style={{paddingTop: "10px"}}>
                                    <button
                                        onClick={() => this.toggleAddPatient(true)}
                                        className="filter-save-button add-new-button">
                                        Add New
                                    </button>
                                </div>
                            )}
                        </div>
                        { this.state.showAddPatient && (
                            <AddPatient
                                toggle={this.toggleAddPatient}
                                setPatient={this.setPatient}
                            />
                        )}
                        <div className="col-md-12 mrgn-btm10">
                            <label>Event Type <span className="required">*</span></label>
                            <AsyncPaginate
                                placeholder="Select Event Type..."
                                className={this.hasErrors(REQUIRED_FIELDS.EVENT_TYPE) ? 'error' : ''}
                                value={this.state.eventType}
                                loadOptions={this.loadEventTypes}
                                onChange={this.onChangeEventType}
                            />
                        </div>
                        <div className="col-md-12 mrgn-btm10" />
                        <div className="col-md-12 mrgn-btm10" />
                        <div className="col-md-3 mrgn-btm10">
                            <label>Upload File</label><br/>
                            <input
                                type="file"
                                name="file"
                                className="file-upload"
                                onChange={this.onFileChangeHandler}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>File Type</label>
                            <select
                                className='code-input'
                                onChange={event => this.setState({ fileType: event.target.value })}
                                value={this.state.fileType}>
                                <option value=""> Select Type</option>
                                <option value="appeal"> Appeal</option>
                                <option value="authorization"> Authorization</option>
                                <option value="eob"> EOB</option>
                                <option value="facesheet"> Facesheet</option>
                                <option value="op"> OP Report</option>
                                <option value="pn"> PN</option>
                            </select>
                        </div>
                        <div className="top-padding">
                            <div className="table-responsive">
                                <table className="listing-table">
                                    <thead className="listing-table-head">
                                    <tr>
                                        {this.renderHeadColumn()}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.renderSheetList()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-12 mrgn-btm10">
                            <label>Procedure <span className="required">*</span></label>
                            { !this.state.showAddProcedure && (
                                <AsyncPaginate
                                    ref="procedure"
                                    className={this.hasErrors(REQUIRED_FIELDS.SELECTED_PROCEDURES) ? 'error' : ''}
                                    placeholder="Select Procedure..."
                                    value={this.state.selectedProcedures}
                                    loadOptions={this.loadProcedures}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    onChange={this.onChangeProcedure}
                                />
                            )}
                            { !this.state.showAddProcedure && (
                                <div style={{paddingTop: "10px"}}>
                                    <button
                                        onClick={() => this.toggleAddProcedure(true)}
                                        className="filter-save-button add-new-button">
                                        Add New
                                    </button>
                                </div>
                            )}
                        </div>
                        { this.state.showAddProcedure && (
                            <AddProcedure
                                toggle={this.toggleAddProcedure}
                                setProcedure={this.setProcedure}
                            />
                        )}
                        <div className="col-md-4 mrgn-btm10">
                            <label>Surgeon <span className="required">*</span></label>
                            <AsyncPaginate
                                placeholder="Select Surgeon..."
                                value={this.state.surgeon}
                                loadOptions={this.loadSurgeon}
                                onChange={this.onChangeSurgeon}
                                className={this.hasErrors(REQUIRED_FIELDS.SURGEON) ? 'error' : ''}
                            />
                        </div>
                        <div className="col-md-4 mrgn-btm10">
                            <label>Facility <span className="required">*</span></label>
                            <AsyncPaginate
                                placeholder="Select Facility..."
                                value={this.state.facility}
                                loadOptions={this.loadFacility}
                                onChange={this.onChangeFacility}
                                className={this.hasErrors(REQUIRED_FIELDS.FACILITY) ? 'error' : ''}
                            />
                        </div>
                        <div className="col-md-4 mrgn-btm10">
                            <label>Assistant <span className="required">*</span></label>
                            <AsyncPaginate
                                placeholder="Select Assistant..."
                                value={this.state.assistant}
                                loadOptions={this.loadAssistant}
                                onChange={this.onChangeAssistant}
                                className={this.hasErrors(REQUIRED_FIELDS.ASSISTANT) ? 'error' : ''}
                            />
                        </div>

                        <div className="col-md-3 mrgn-btm10">
                            <label>Start Date <span className="required">*</span></label>
                            <br/>
                            <DatePicker
                                isClearable
                                selected={this.state.startDate}
                                onChange={date => this.setStartDate(date)}
                                className="date-picker"
                            />
                        </div>

                        <div className="col-md-3 mrgn-btm10">
                            <label>Start Time <span className="required">*</span></label>
                            {/*<br/>
                            <TimePicker
                                className="date-picker"
                                onChange={time => this.setStartTime(time)}
                                value={this.state.startTime}
                            />*/}
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.startTime}
                                onChange={event => this.setState({ startTime: event.target.value })}
                            />
                        </div>

                        <div className="col-md-3 mrgn-btm10">
                            <label>End Date</label>
                            <br/>
                            <DatePicker
                                isClearable
                                selected={this.state.endDate}
                                onChange={date => this.setEndDate(date)}
                                className="date-picker"
                            />
                        </div>

                        <div className="col-md-3 mrgn-btm10">
                            <label>End Time</label>
                            {/*<br/>
                            <TimePicker
                                className="date-picker"
                                onChange={time => this.setEndTime(time)}
                                value={this.state.endTime}
                            />*/}
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.endTime}
                                onChange={event => this.setState({ endTime: event.target.value })}
                            />
                        </div>


                        <div className="col-md-6 mrgn-btm10">
                            <label>Diagnosis Codes</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.diagnosisCodes}
                                onChange={event => this.setState({ diagnosisCodes: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>CPT Codes</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.cptCodes}
                                onChange={event => this.setState({ cptCodes: event.target.value })}
                            />
                        </div>
                        <div className="col-md-12 mrgn-btm10">
                            <label>Event Flags</label>
                            <AsyncPaginate
                                ref="procedure"
                                placeholder="Select Event Flags..."
                                isMulti
                                value={this.state.eventFlags}
                                loadOptions={this.loadEventFlags}
                                onChange={this.onChangeEventFlags}
                            />
                        </div>

                        { !activityId && (
                            <div className="col-md-12">
                                <label>Add Notes</label>
                                <input
                                    type="text"
                                    value={this.state.notes}
                                    className="code-input"
                                    onChange={event => this.setState({ notes: event.target.value })}
                                />
                            </div>
                        )}
                        { activityId && (
                        <div className="col-md-6">
                            <label>Status</label>
                            <AsyncPaginate
                                placeholder="Select Status..."
                                value={this.state.status}
                                loadOptions={this.loadStatus}
                                onChange={this.onChangeStatus}
                            />
                        </div>
                        )}
                        { activityId && (
                            <div className="col-md-6">
                                <label>Add Notes</label>
                                <input
                                    type="text"
                                    value={this.state.notes}
                                    className="code-input"
                                    onChange={event => this.setState({ notes: event.target.value })}
                                />
                            </div>
                        )}
                        <div className="col-md-12">
                            <input
                                type="checkbox"
                                checked={this.state.endDate === ''}
                            />
                            <label style={{ paddingLeft: "5px" }}>End Time not confirmed (Click checkbox only if you don't know the end time)</label>
                        </div>
                        <div className="filter-footer">
                            {activityId &&
                                <button
                                    onClick={this.submitActivity}
                                    className="filter-save-button">
                                    Update
                                </button>
                            }
                            {!activityId &&
                                <button
                                    onClick={this.submitActivity}
                                    className="filter-save-button">
                                    Submit
                                </button>
                            }
                            <button
                                onClick={this.cancel}
                                className="filter-cancel-button">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            submitActivity,
            updateActivity,
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(ManageActivity));
