import {
    faAsterisk,
    faCalendar,
    faClipboardList,
    faFile,
    faLevelUpAlt,
    faLocationArrow,
    faLockOpen,
    faStopwatch,
    faTasks,
    faUserCircle,
    faUserCog,
    faUserMd
} from "@fortawesome/free-solid-svg-icons";

export const RECORDS_PER_PAGE = 10;

export const BASE_URL = 'https://xcitesurgical.com/api/';
//export const BASE_URL = 'https://usp.coremaxcloud.com/portal/api/';

export const EMAIL_REGEX = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;

export const LOCAL_STORAGE_KEYS = {
    USER: 'user',
    ACCESS_TOKEN: 'accessToken',
    LOGGED_IN_USER: 'LOGGED_IN_USER',
    LOGGED_IN_TIME: 'LOGGED_IN_TIME',
    IMPERSONATE_EMAIL: 'IMPERSONATE_EMAIL',
    META_DATA: 'META_DATA',
    OFFICE_365: 'OFFICE_365',
};

export const APP_ROUTES = {
    LOGIN: '/login',
    LOGIN_MID: '/login-middleware',
    DASHBOARD: '/scheduling',
    ACTIVITY_DASHBOARD: '/activities',
    ADD_ACTIVITY: '/add-activity',
    EDIT_ACTIVITY: '/edit-activity',
    PATIENTS_DASHBOARD: '/patients',
    ADD_PATIENT: '/add-patient',
    EDIT_PATIENT: '/edit-patient',
    SURGEONS_DASHBOARD: '/surgeons',
    ADD_SURGEON: '/add-surgeon',
    EDIT_SURGEON: '/edit-surgeon',
    FACILITIES_DASHBOARD: '/facilities',
    ADD_FACILITY: '/add-facility',
    EDIT_FACILITY: '/edit-facility',
    USER_DASHBOARD: '/users',
    ADD_USER: '/add-user',
    EDIT_USER: '/edit-user',
    NOT_AUTHORIZED: '/not-authorized',
    SCAN_FLOW_DASHBOARD: '/scan-flow',
    EDIT_SCAN_FLOW: '/edit-scan-flow',
    CASELOG_DASHBOARD: '/pac-data',
    ADD_CASELOG: '/add-pac-data',
    EDIT_CASELOG: '/edit-pac-data',
    ADD_BILL: '/add-bill',
    EDIT_BILL: '/edit-bill',
    INSURANCE_DASHBOARD: '/insurance',
    ADD_INSURANCE: '/add-insurance',
    EDIT_INSURANCE: '/edit-insurance',
    CLAIMS_AND_COLLECTION: '/claims-and-collections',
    EDIT_CLAIM_AND_COLLECTION: '/edit-claims-and-collections',
    INVOICE_CLAIM_AND_COLLECTION: '/invoice-claims-and-collections',
    PRACTICE_DASHBOARD:'/practices',
    ADD_PRACTICE:'/add-practice',
    EDIT_PRACTICE:'/edit-practice',
    REGIONS: '/regions',
    ADD_REGION: '/add-region',
    UPDATE_REGION: '/edit-region',
    SURGICAL_ASSISTANT: '/surgical_assistants',
    SURGICAL_ASSISTANT_DASHBOARD: '/surgical_assistants-dashboard',
    ADD_ASSISTANT: '/add-assistant',
    EDIT_ASSISTANT: '/edit-assistant',
    PROCEDURE_DASHBOARD:'/procedures',
    ADD_PROCEDURE:'/add-procedure',
    EDIT_PROCEDURE:'/edit-procedure',
};

export const MENU_LIST = [
    {module_name: 'Scheduling', slug: 'scheduling', icon: faCalendar, padding: '15px'},
    {module_name:'Activities', slug: 'activities', icon: faTasks, padding: '15px'},
    {module_name:'Scan Flow', slug: 'scan-flow', icon: faFile, padding: '20px'},
    {module_name:'PA-C Data', slug: 'pac-data', icon: faUserMd, padding: '18px'},
    {module_name:'Patients', slug: 'patients', icon: faUserCircle, padding: '18px'},
    {module_name:'Procedures', slug: 'procedures', icon: faUserCircle, padding: '18px'},
    {
        'Revenue Cycle': [
            {module_name:'Claims and Collection', slug: 'claims-and-collections'},
            {module_name:'Revenue Scheduler', slug: 'revenue_scheduler'},
            {icon: faLevelUpAlt, padding: '24px'},
        ]
    },
    {
        'Setup': [
            {module_name:'Fee Structure', slug: 'fee_structure'},
            {module_name:'Manage Insurance', slug: 'insurance'},
            {module_name:'Manage Users', slug: 'users'},
            {module_name:'Practice', slug: 'practices'},
            {icon: faUserCog, padding: '15px'},
        ]
    },
    {module_name:'Reporting', slug: 'reporting', icon: faFile, padding: '24px'},
    {
        'Credentialing': [
            {module_name:'Surgical Assistants', slug: 'surgical_assistants'},
            {module_name:'Facilities', slug: 'facilities'},
            {module_name:'Surgeons', slug: 'surgeons'},
            {module_name:'Speciality', slug: 'speciality'},
            {icon: faLockOpen, padding: '15px'},
        ]
    },
    {module_name:'Regions', slug: 'regions', icon: faLocationArrow, padding: '19px'},
    {module_name:'Preference Card', slug: 'preference_card', icon: faAsterisk, padding: '21px'},
    {module_name:'Batches', slug: 'batches', icon: faStopwatch, padding: '23px'},
    {module_name:'Logs', slug: 'logs', icon: faClipboardList, padding: '24px'},
];

export const SUB_MENU = {
    claims_and_collections: 'Revenue Cycle',
    revenue_scheduler: 'Revenue Cycle',
    fee_structure: 'Setup',
    insurance: 'Setup',
    users: 'Setup',
    practices: 'Setup',
    surgical_assistants: 'Credentialing',
    facilities: 'Credentialing',
    surgeons: 'Credentialing',
    speciality: 'Credentialing',
};

export const NOTIFICATION_TYPES = {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error',
};

export const ACTIVITY_LISTING_COLUMNS = [
    'Assistant',
    'Patient/Ins',
    'Surgeon',
    'Facility',
    'Procedure',
    'Event Type',
    'Date/Time',
];

export const PATIENT_LISTING_COLUMNS = [
    'Name',
    'DOB',
    'Age',
    'Gender',
];

export const SURGEON_LISTING_COLUMNS = [
    'ID',
    'Name',
    'NPI',
    'Address',
    'Phone',
];

export const FACILITY_LISTING_COLUMNS = [
    'ID',
    'Facility',
    'Address',
    'Phone',
    'Fax',
    'NPI',
];

export const BILLING_LISTING_COLUMNS = [
    'Facesheet',
    'Service Date',
    'Report List',
    'Correspondance',
    'Patient Name',
    'Assistant',
    'Facility',
    'Assign Sorting',
    'Bill Status',
    'Insurance',
    'Practice',
    'Patient Chart No:',
    'Assign Reg',
    'Reg Status',
    'Assign Coder',
    'Coding Status',
    'Assign Charge',
    'Charge Status',
    'Assign Claim',
    'Claim Status',
    'Assistant Location',
    'Hosp Contract',
    'Created',
];

export const USER_LISTING_COLUMNS = [
    'ID',
    'Name',
    'Email',
    'Type',
]

export const CASELOG_LISTING_COLUMNS = [
    'Patient Name',
    'DOB',
    'Gender',
    'Insurance',
    'Surgeon',
    'Facility',
    'DOS',
]

export const INSURANCE_LISTING_COLUMNS = [
    'Name',
    'Payor Code',
]

export const PRACTICE_LISTING_COLUMNS = [
    'NPI',
    'NAME',
    'Regions/Markets'
]

export const CLAIMS_LISTING_COLUMNS = [
    'Bill ID',
    'Event ID',
    'Patient ID',
    'Patient DOB',
    'Name',
    'DOS',
    'Charge Amount',
    'Payer Amount',
    'Adjustment',
    'Balance',
    'Provider',
    'Bill CPT Codes',
    'Insurance Claim',
    'Primary Insurance',
    'Secondary Insurance',
    'Surgical Assistant License Type',
    'Surgeon',
    'Facility',
    'Pay to Name',
];

export const REGIONS = [
    'Region Id',
    'Region Name',
]

export const ASSISTANTS = [
    'Id',
    'Name',
    'Email',
    'Roles',
    'Active/Inactive'
]

export const PROCEDURE_LISTING_COLUMNS = [
    'PROCEDURE ID',
    'Name',
]