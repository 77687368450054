import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';

import '../../../sass/listingtable.scss';
import editIcon from "../../../assets/images/edit.png";
import deleteIcon from "../../../assets/images/delete.png";
import {APP_ROUTES, LOCAL_STORAGE_KEYS, RECORDS_PER_PAGE} from "../../constants/common";

import { billingService } from "../../services/billing";
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {formatDate} from "../../utils/date";
import {withRouter} from "react-router-dom";
import Loader from "./Loader";

class ListingTable extends React.Component {
    static propTypes = {
        currentPage: PropTypes.number.isRequired,
        goToPage: PropTypes.func.isRequired,
        edit: PropTypes.func.isRequired,
        delete: PropTypes.func.isRequired,
        headColumn: PropTypes.array.isRequired,
        listing: PropTypes.array.isRequired,
        totalRecords: PropTypes.number.isRequired,
    };

    state = {
        billingId: '',
        patient: {},
        bill: {},
        loading: false,
    };

    renderHeadColumn = () => {
        const { headColumn } = this.props;

        const header = headColumn.map((column, index) => {
            if (index === 0) {
                return (
                    <td style={{
                        textAlign: 'left',
                    }}
                        className="listing-table-head-column">
                        {column}
                    </td>
                );
            } else {
                return (
                    <th className="listing-table-head-column">
                        {column}
                    </th>
                );
            }
        });

        const actionElement = (
            <th className="listing-table-head-column">
                {'Action'}
            </th>
        );

        header.splice(headColumn.length + 1, 0, actionElement);

        return header;
    };

    edit = item => this.props.edit(item);

    delete = item => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.props.delete(item);
            }
        })
    }

    getStatusColor = status => {
        switch (status) {
            case 'new':
                return '#60A6D3';
            case 'started':
                return '#9C5194';
            case 'cancelled':
                return '#26A797';
            case 'completed':
                return '#B4B14E';
            default:
                return '';
        }
    };

    toggleBillTable = (value) => {
        const { billingId } = this.state;
        this.setState({loading: true});
        if (billingId === value) {
            this.setState({
                billingId: '',
                loading: false,
            });
        } else {
            this.setState({ billingId: value},() => {
                const { billingId } = this.state;
                billingService
                    .getEventBill(billingId)
                    .then(response => {
                        this.setState({
                            bill: response.data.bill,
                            patient: response.data.patient,
                            loading: false,
                        });
                    });
            });
        }
    };

    editBill = (billId, eventId) => this.props.history.push({
        pathname: APP_ROUTES.EDIT_BILL,
        state: {
            activePage: 'activities',
            billId,
            eventId,
        }
    });

    createBill = (eventId) => this.props.history.push({
        pathname: APP_ROUTES.ADD_BILL,
        state: {
            activePage: 'activities',
            eventId,
        }
    });

    renderBillList = () => {
        const {
            billingId,
            bill,
            patient,
        } = this.state;
        if (bill) {
            return (
                <>
                    <td style={{
                        backgroundColor: '#475f87',
                    }}
                        className="listing-table-column">
                        <span style={{
                            color: 'white',
                        }}>Event Bill</span>
                    </td>
                    <td className="listing-table-column">
                        {bill ? bill.old_bill_id : ''}
                    </td>
                    <td className="listing-table-column">
                        {billingId}
                    </td>
                    <td className="listing-table-column">
                        {patient ? patient.id : ''}
                    </td>
                    <td className="listing-table-column">
                        {patient ? patient.first_name : ''} {patient ? patient.last_name : ''}
                    </td>
                    <td className="listing-table-column">
                        {bill ? formatDate(bill.service_date) : ''}
                    </td>
                    <td colSpan="2"
                        className="listing-table-column">
                        <button
                            onClick={() => this.editBill(bill.old_bill_id, billingId)}
                            className="filter-save-button">
                            Update Bill
                        </button>
                    </td>
                </>
            );
        } else {
            return (
                <td colSpan="8"
                    className="listing-table-column">
                    <button
                        style={{
                            width: '200px',
                        }}
                        onClick={() => this.createBill(billingId)}
                        className="filter-save-button">
                        Create a Bill
                    </button>
                </td>
            );
        }
    };

    renderBillingTable = () => (
        <>
            <td className="listing-table-head-column" />
            <td style={{
                color: 'white',
                }}
                className="listing-table-head-column">Bill Id</td>
            <td style={{
                color: 'white',
                }}
                className="listing-table-head-column">Event Id</td>
            <td style={{
                color: 'white',
            }}
                className="listing-table-head-column">Patient Id</td>
            <td style={{
                color: 'white',
            }}
                className="listing-table-head-column">Patient</td>
            <td style={{
                color: 'white',
            }}
                className="listing-table-head-column">DOS</td>
            <td style={{
                color: 'white',
            }}
                className="listing-table-head-column"
                colSpan="2">
                Action
            </td>
        </>
    );

    renderList = () => {
        const { listing = [] } = this.props;
        const loggedInUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER));
        return listing.map(listRow => {
            const item = listRow.map((listItem, index) => {
                if (index < listRow.length - 2) {
                    return (
                        <td
                            className="listing-table-column textUpper"
                            style={{
                                borderLeft: index === 0
                                    ? `10px solid ${this.getStatusColor(listRow[listRow.length -1])}`
                                    : '',
                                textAlign: index === 0 ? 'left': 'center',
                            }}>
                            <p data-tip={listItem}>
                                <span
                                    id={`TooltipExample`} >
                                    {index === 0 || listItem.length < 10 ? listItem : listItem.substring(0, 10) + '...'}
                                </span>
                            </p>
                            <ReactTooltip
                                place="bottom"
                                type="light"
                                effect="float"
                            />
                        </td>
                    );
                } else if (index < listRow.length - 1)  {
                    return (
                        <td className="listing-table-column">
                            { loggedInUser.isUpdateAccess && (
                                <img
                                    src={editIcon}
                                    onClick={() => {
                                        this.edit(listItem)
                                    }}
                                    alt="edit"
                                />
                            )}
                            { loggedInUser.isDeleteAccess && (
                                <img
                                    src={deleteIcon}
                                    onClick={() => {
                                        this.delete(listItem)
                                    }}
                                    alt="delete"
                                />
                            )}
                            { loggedInUser.type !== 'assistant' && (
                                <div style={{
                                    paddingTop: '2px',
                                    display: 'flex',
                                    float: "left",
                                }}
                                 onClick={() => this.toggleBillTable(listItem)}>
                                    <FontAwesomeIcon
                                        icon={faFileInvoiceDollar}
                                        size='lg'
                                        color='#475f87'
                                    />
                                </div>
                            )}
                        </td>
                    );
                }
            });

            return (
                <>
                    <tr className="listing-table-rows">
                        {item}
                    </tr>
                    {this.state.billingId === listRow[listRow.length - 2] && (
                        <tr style={{
                            backgroundColor: '#475f87',
                        }}
                            className="listing-table-rows">
                            {this.renderBillingTable()}
                        </tr>
                    )}
                    {this.state.billingId === listRow[listRow.length - 2] && (
                        <tr style={{
                            backgroundColor: '#ffffff',
                        }}
                            className="listing-table-rows">
                            {this.renderBillList()}
                        </tr>
                    )}
                </>
            );
        });
    };

    renderPagination = () => {
        const { totalRecords, currentPage } = this.props;

        const totalPages = Math.ceil(totalRecords / RECORDS_PER_PAGE);

        const visiblePaginationPages = 6;

        const pageIndexes = [];

        const visiblePages = visiblePaginationPages / 2;
        if (totalPages > visiblePaginationPages + 2) {
            const firstPage = Math.min(currentPage, Math.max(totalPages - (visiblePages * 2), 0));
            for (let i = firstPage; i < firstPage + visiblePages; i++) {
                pageIndexes.push(i);
            }

            if (currentPage + visiblePages < totalPages - visiblePages) {
                pageIndexes.push(-1);
            }

            for (let i = totalPages - visiblePages; i < totalPages; i++) {
                pageIndexes.push(i);
            }
        } else {
            for (let i = 0; i < totalPages; i++) {
                pageIndexes.push(i);
            }
        }

        const pageLinks = pageIndexes.map((i, index) => {
            const button = <button
                disabled={currentPage === i}
                key={`pagination-button-${index}`}
                onClick={() => { return this.props.goToPage(i); }}
                className="listing-pagination-button">
                {i}
            </button>;

            const ellipsis = <div className="listing-pagination-button" key={`pagination-ellipsis-${index}`}>...</div>;
            return (
                -1 === i ? ellipsis : button
            );
        });
        return (
            <div className="listing-pagination">
                <div className="inner">
                    <button
                        onClick={() => { return this.props.goToPage(1); }}
                        disabled={1 === currentPage}
                        className="listing-pagination-button">
                        &lt;&lt; First
                    </button>
                    <button
                        onClick={() => { return this.props.goToPage(currentPage - 1); }}
                        disabled={1 === currentPage}
                        className="listing-pagination-button">
                        &lt; Previous
                    </button>
                    {pageLinks}
                    <button
                        onClick={() => { return this.props.goToPage(currentPage + 1); }}
                        disabled={currentPage === totalPages - 1}
                        className="listing-pagination-button">
                        Next &gt;
                    </button>
                    <button
                        onClick={() => { return this.props.goToPage(totalPages - 1); }}
                        disabled={currentPage === totalPages - 1}
                        className="listing-pagination-button">
                        Last &gt;&gt;
                    </button>
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className="x_panel">
                <div className="x_content">
                    <div className="table-responsive">
                        <table className="listing-table">
                            <thead className="listing-table-head">
                            <tr>
                                {this.renderHeadColumn()}
                            </tr>
                            </thead>
                            <tbody>
                                {this.state.loading && (
                                    <tr className="listing-table-rows">
                                        <td colSpan="8"
                                            className="listing-table-column">
                                            <Loader width='100%' />
                                        </td>

                                    </tr>
                                )}
                                {!this.state.loading && this.renderList()}
                            </tbody>
                        </table>
                    </div>
                    {this.props.totalRecords > 10 && this.props.listing.length > 0
                        ? this.renderPagination()
                        : <div style={{
                            width: '100%',
                            textAlign: 'center',
                            paddingTop: '2px',
                        }}>
                            { !this.props.isLoading && this.props.listing.length === 0 && (
                                <span className="noRecord">No Records Found</span>
                            )}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(ListingTable);