import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { API_CONSTANTS } from "../constants/api";

function getActivities(options) {
    return apiClient()
        .get(API_CONSTANTS.LIST_ACTIVITIES, options)
        .then(handleResponse)
        .catch(handleError);
}

function submitActivity(payload) {
    return apiClient()
        .post(API_CONSTANTS.SUBMIT_ACTIVITIES, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateActivity(payload) {
    return apiClient()
        .post(API_CONSTANTS.UPDATE_ACTIVITY, payload)
        .then(handleResponse)
        .catch(handleError);
}

function getActivityDetail(activity) {
    return apiClient()
        .get(API_CONSTANTS.ACTIVITY_DETAIL + activity)
        .then(handleResponse)
        .catch(handleError);
}

function deleteActivity(activity) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_ACTIVITY + activity)
        .then(handleResponse)
        .catch(handleError);
}

function deleteActivityFaceSheet(faceSheetId) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_ACTIVITY_FACESHEET + faceSheetId)
        .then(handleResponse)
        .catch(handleError);
}

export const activityService = {
    getActivities,
    submitActivity,
    updateActivity,
    getActivityDetail,
    deleteActivity,
    deleteActivityFaceSheet,
}