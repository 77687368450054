import React from "react";
import {bindActionCreators} from "redux";
import {createAssistant, updateAssistant} from "../../actions/assistant";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import {APP_ROUTES, NOTIFICATION_TYPES} from "../../constants/common";
import {createNotification} from "../../utils/notificationManager";

const REQUIRED_FIELDS = {
    NAME: 'name',
    LAST_NAME: 'lastName',
    EMAIL: 'email',
};

class ManageAssistant extends React.Component {

    state = {
        name: '',
        lastName: '',
        email: '',
        personalEmail: '',
        phone: '',
        personalPhone: '',
        active: '',
        homeAddress: '',
        homeAddress2: '',
        state: '',
        city: '',
        zip: '',
        errors: [],
    };

    componentDidMount() {
        const assistantId = this.props.location.state ? this.props.location.state.assistantId : '';
        if (assistantId) {

        }
    }

    hasErrors = (field) => {
        return this.state.errors.includes(field);
    };

    resetErrors = (field) => {
        this.setState({
            errors: this.state.errors.filter(fieldName => fieldName !== field)
        });
    }

    validateAssistant = () => {
        const {
            email,
            name,
            lastName,
            errors,
        } = this.state;

        let isValid = true;

        if (name.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.NAME] });
            isValid = false;
        } else if (lastName.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.LAST_NAME] });
            isValid = false;
        } else if (email.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.EMAIL] });
            isValid = false;
        }

        if (!isValid) {
            createNotification(NOTIFICATION_TYPES.ERROR, 'Please select required values');
        }

        return isValid;
    };

    submitAssistant = () => {
        if (!this.validateAssistant()) {
            return;
        }

        const {
            name,
            lastName,
            email,
            personalEmail,
            phone,
            personalPhone,
            active,
            homeAddress,
            homeAddress2,
            city,
            state,
            zip,
        } = this.state;

        const request = {
            first_name: name,
            last_name: lastName,
            email,
            phone,
            home_phone: personalPhone,
            address1: homeAddress,
            address2: homeAddress2,
            city,
            state,
            zip,
        };
    };

    formatPhone = (str) => {
        if (str) {
            let phone = '';
            str = str.replace(/\D/g, '').substring(0,10);
            if (str.length < 3) {
                phone += str;
            } else if (str.length < 6) {
                phone += str.substring(0, 3) + '-';
                if (str.length > 3) {
                    phone += str.substring(3, str.length);
                }
            } else {
                phone += str.substring(0, 3) + '-' + str.substring(3, 6) + '-' + str.substring(6, 10);
            }
            return phone;
        }
        return str;
    };

    render() {
        const activePage = this.props.location.state ? this.props.location.state.activePage : '';
        const assistantId = this.props.location.state ? this.props.location.state.assistantId : '';
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col"
                     style={{
                         minHeight: '125vh',
                     }}>
                    <Sidebar activePage={activePage}/>
                </div>
                <TopNavbar />
                <div className="manage-activity-right-col">
                    <div className="activity-row">
                        <div className="page-title">
                            {assistantId &&
                            <h2 className="heading-custom">Edit Assistant</h2>
                            }
                            {!assistantId &&
                            <h2 className="heading-custom">Add Assistant</h2>
                            }
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>First Name <span className="required">*</span></label>
                            <input
                                className={this.hasErrors(REQUIRED_FIELDS.NAME) ? 'error code-input' : 'code-input'}
                                type="text"
                                value={this.state.name}
                                onChange={event => {
                                    this.resetErrors(REQUIRED_FIELDS.NAME);
                                    this.setState({ name: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Last Name <span className="required">*</span></label>
                            <input
                                className={this.hasErrors(REQUIRED_FIELDS.LAST_NAME) ? 'error code-input' : 'code-input'}
                                type="text"
                                value={this.state.lastName}
                                onChange={event => {
                                    this.resetErrors(REQUIRED_FIELDS.LAST_NAME);
                                    this.setState({ lastName: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Work Email <span className="required">*</span></label>
                            <input
                                className={this.hasErrors(REQUIRED_FIELDS.EMAIL) ? 'error code-input' : 'code-input'}
                                type="text"
                                value={this.state.email}
                                onChange={event => {
                                    this.resetErrors(REQUIRED_FIELDS.EMAIL);
                                    this.setState({ email: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Personal Email</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.state.personalEmail}
                                onChange={event => this.setState({ personalEmail: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Work Cell Phone</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.formatPhone(this.state.phone)}
                                onChange={event => this.setState({ phone: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Personal Cell Phone</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.formatPhone(this.state.personalPhone)}
                                onChange={event => this.setState({ personalPhone: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Active</label>
                            <select
                                className="filter-input"
                                onChange={event => this.setState({ active: event.target.value })}>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </select>
                        </div>
                        <div className="page-title">
                            <h2 className="heading-custom">Home Address</h2>
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Home Address</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.state.homeAddress}
                                onChange={event => this.setState({ homeAddress: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Home Address 2</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.state.homeAddress2}
                                onChange={event => this.setState({ homeAddress2: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>State</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.state.state}
                                onChange={event => this.setState({ state: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>City</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.state.city}
                                onChange={event => this.setState({ city: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Zip</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.state.zip}
                                onChange={event => this.setState({ zip: event.target.value })}
                            />
                        </div>
                        <div className="filter-footer">
                            <button
                                onClick={this.submitAssistant}
                                className="filter-save-button">
                                Submit
                            </button>
                            <button
                                onClick={() => { this.props.history.push({
                                    pathname: APP_ROUTES.SURGICAL_ASSISTANT_DASHBOARD,
                                    state: {
                                        activePage: 'surgical_assistants',
                                        regionId: this.props.regionId || this.props.location.state.regionId,
                                    }
                                })}}
                                className="filter-cancel-button">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createAssistant,
            updateAssistant,
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(ManageAssistant));