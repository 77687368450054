import {PATIENT, SURGEON} from '../constants/actions';
import { surgeonService } from '../services/surgeons';


export const fetchSurgeons = options => dispatch => {
    return new Promise((resolve, reject) => {
        surgeonService
            .getSurgeons(options)
            .then(response => {
                dispatch({
                    type: SURGEON.FETCH_SURGEON_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {});
    });
};

export const createSurgeon = payload => dispatch => {
    return new Promise((resolve, reject) => {
        surgeonService
            .createSurgeon(payload)
            .then(response => {
                dispatch({
                    type: SURGEON.CREATE_SURGEON_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {});
    });
};

export const updateSurgeon = payload => dispatch => {
    return new Promise((resolve, reject) => {
        surgeonService
            .updateSurgeon(payload)
            .then(response => {
                dispatch({
                    type: SURGEON.UPDATE_SURGEON_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {});
    });
};
