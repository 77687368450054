import React from "react";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import {APP_ROUTES, NOTIFICATION_TYPES} from "../../constants/common";
import {claimsService} from "../../services/claims";

import logo from "../../../assets/images/universal-logo.png";
import {formatDate} from "../../utils/date";
import {createNotification} from "../../utils/notificationManager";

class ClaimInvoice extends React.Component {

    send = () => {
        const facilityId = this.props.facilityId || this.props.location.state.facilityId;
        const practice = this.props.practice || this.props.location.state.practice;
        const patient = this.props.patient || this.props.location.state.patient;
        const startDate = this.props.startDate || this.props.location.state.startDate;
        const endDate = this.props.endDate || this.props.location.state.endDate;

        const request = {
            claim_start_date: startDate,
            claim_end_date: endDate,
            practice,
            facility: facilityId,
            patient,
        };

        claimsService
            .sendInvoice(request)
            .then(res => {
                if (res.response.data.success) {
                    this.props.history.push(APP_ROUTES.CLAIMS_AND_COLLECTION);
                } else {
                    createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                    this.props.history.push(APP_ROUTES.CLAIMS_AND_COLLECTION);
                }
            })
            .catch(err => {
                createNotification(NOTIFICATION_TYPES.ERROR, `Unable to send Invoice ${err.message}`);
            });
    };

    cancel = () => {
        this.props.history.push({
            pathname: APP_ROUTES.CLAIMS_AND_COLLECTION,
            state: {
                activePage: 'claims_and_collections',
            }
        })
    };

    renderClaims = () => {
        const claims = this.props.claims || this.props.location.state.claims;
        return claims.map(claim => (
           <tr>
               <td style={{
                   color: '#6f6f6f',
                   fontFamily: 'Segoe, Segoe UI, Verdana',
                   fontSize: '12px',
                   height: '30px',
                   verticalAlign: 'middle',
                   textAlign: 'left',
                   width: '12%',
                   borderBottom: '1px solid #cfcfcf',
               }}>
                   {claim.event_patient && claim.event_patient.patient ? claim.event_patient.patient.account_number : ''}
               </td>
               <td style={{
                   color: '#6f6f6f',
                   fontFamily: 'Segoe, Segoe UI, Verdana',
                   fontSize: '12px',
                   height: '30px',
                   verticalAlign: 'middle',
                   width: '9%',
                   borderBottom: '1px solid #cfcfcf',
               }}>
                   {claim.service_date ? formatDate(claim.service_date) : ''}
               </td>
               <td style={{
                   color: '#6f6f6f',
                   fontFamily: 'Segoe, Segoe UI, Verdana',
                   fontSize: '12px',
                   height: '30px',
                   verticalAlign: 'middle',
                   textAlign: 'center',
                   width: '15%',
                   borderBottom: '1px solid #cfcfcf',
               }}>
                   {`${claim.event_patient && claim.event_patient.patient ? claim.event_patient.patient.first_name : ''} ${claim.event_patient && claim.event_patient.patient ? claim.event_patient.patient.last_name : ''}`}
               </td>
               <td style={{
                   color: '#6f6f6f',
                   fontFamily: 'Segoe, Segoe UI, Verdana',
                   fontSize: '12px',
                   height: '30px',
                   verticalAlign: 'middle',
                   width: '9%',
                   borderBottom: '1px solid #cfcfcf',
               }}>
                   {claim.event_patient && claim.event_patient.patient ? formatDate(claim.event_patient.patient.date_of_birth) : ''}
               </td>
               <td style={{
                   color: '#6f6f6f',
                   fontFamily: 'Segoe, Segoe UI, Verdana',
                   fontSize: '12px',
                   height: '30px',
                   verticalAlign: 'middle',
                   textAlign: 'left',
                   width: '12%',
                   borderBottom: '1px solid #cfcfcf',
               }}>
                   {claim.event_patient && claim.event_patient.procedures ? claim.event_patient.procedures.map(procedure => procedure.procedure_name).join(',') : ''}
               </td>
               <td style={{
                   color: '#6f6f6f',
                   fontFamily: 'Segoe, Segoe UI, Verdana',
                   fontSize: '12px',
                   height: '30px',
                   verticalAlign: 'middle',
                   textAlign: 'center',
                   borderBottom: '1px solid #cfcfcf',
               }}>
                   {claim.event_patient
                   && claim.event_patient.patient
                   && claim.event_patient.patient.patient_insurance_data
                   && claim.event_patient.patient.patient_insurance_data.primary ? claim.event_patient.patient.patient_insurance_data.primary.description : ''}
               </td>
               <td style={{
                   color: '#6f6f6f',
                   fontFamily: 'Segoe, Segoe UI, Verdana',
                   fontSize: '12px',
                   height: '30px',
                   verticalAlign: 'middle',
                   textAlign: 'center',
                   width: '10%',
                   borderBottom: '1px solid #cfcfcf',
               }}>
                   {`${claim.event_patient && claim.event_patient.assistant ? claim.event_patient.assistant.name : ''} ${claim.event_patient && claim.event_patient.assistant ? claim.event_patient.assistant.last_name : ''}`}
               </td>
               <td style={{
                   color: '#6f6f6f',
                   fontFamily: 'Segoe, Segoe UI, Verdana',
                   fontSize: '12px',
                   height: '30px',
                   verticalAlign: 'middle',
                   textAlign: 'center',
                   borderBottom: '1px solid #cfcfcf',
               }}>
                   {`${claim.event_patient && claim.event_patient.surgeon ? claim.event_patient.surgeon.first_name : ''} ${claim.event_patient && claim.event_patient.surgeon ? claim.event_patient.surgeon.last_name : ''}`}
               </td>
               <td style={{
                   color: '#6f6f6f',
                   fontFamily: 'Segoe, Segoe UI, Verdana',
                   fontSize: '12px',
                   height: '30px',
                   verticalAlign: 'middle',
                   textAlign: 'center',
                   borderBottom: '1px solid #cfcfcf',
               }}>
                   {claim.event_patient && claim.event_patient.facility ? claim.event_patient.facility.facility_name : ''}
               </td>
               <td style={{
                   color: '#6f6f6f',
                   fontFamily: 'Segoe, Segoe UI, Verdana',
                   fontSize: '12px',
                   height: '30px',
                   verticalAlign: 'middle',
                   textAlign: 'center',
                   borderBottom: '1px solid #cfcfcf',
               }}>
                   {claim.total_bill ? claim.total_bill : ''}
               </td>
           </tr>
        ));
    };

    render() {
        const activePage = this.props.activePage || this.props.location.state.activePage;
        const facility = this.props.facility || this.props.location.state.facility;
        const claims = this.props.claims || this.props.location.state.claims;
        let sum = 0;
        claims.map(claim => sum += claim.total_bill);
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col"
                     style={{
                         minHeight: '100vh',
                     }}>
                    <Sidebar activePage={activePage} />
                </div>
                <TopNavbar />
                <div
                    style={{
                        minHeight: '830px'
                    }}
                    className="right-col">
                    <div style={{
                        borderBottom: '3px solid #4c638c',
                    }}
                        className="filter-footer">
                        <button
                            type="submit"
                            onClick={this.send}
                            className="filter-footer-button">
                            Send Invoice
                        </button>
                        <button
                            type="submit"
                            onClick={this.cancel}
                            className="filter-footer-button">
                            Cancel
                        </button>
                    </div>
                    <table style={{
                        width: '900px',
                        border: '0',
                        margin: 'margin: 0 auto',
                    }}>
                        <tr>
                            <td style={{
                                height: '90px',
                                textAlign: "center",
                                verticalAlign: 'middle',
                            }}>
                                <img
                                    src={logo}
                                    width="200"
                                    height="68"
                                    alt=""
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table style={{
                                    width: "900px",
                                    border: '1px solid #cfcfcf',
                                    margin: '0 auto',
                                }}>
                                    <tbody>
                                        <tr>
                                            <td style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '15px',
                                                fontWeight: '500',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'left',
                                                width: '18%',
                                            }}>
                                                <strong>Address:</strong>
                                            </td>
                                            <td style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '14px',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'left',
                                                width: '39.5%',
                                            }}>
                                                1 Sugar Creek Center Blvd. Suite 618 Sugar Land, Texas 77478
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table style={{
                                    width: "900px",
                                    border: '1px solid #cfcfcf',
                                    margin: '0 auto',
                                }}>
                                    <tbody>
                                        <tr>
                                            <td style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '15px',
                                                fontWeight: '500',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'left',
                                            }}>
                                                <strong>Bill To:</strong>
                                            </td>
                                            <td style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '14px',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'left',
                                            }}>
                                                {facility}
                                            </td>
                                            <td style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '15px',
                                                fontWeight: '500',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'center',
                                            }}>
                                                <strong>Invoice #:</strong>
                                            </td>
                                            <td style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '14px',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'center',
                                            }}>
                                                1234
                                            </td>
                                            <td style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '15px',
                                                fontWeight: '500',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'right',
                                            }}>
                                                <strong>Date:</strong>
                                            </td>
                                            <td style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '14px',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'center',
                                            }}>
                                                {formatDate(new Date())}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table style={{
                                    width: "900px",
                                    border: '1px solid #cfcfcf',
                                    margin: '0 auto',
                                }}>
                                    <tbody>
                                        <tr>
                                            <td style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '15px',
                                                fontWeight: '500',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'left',
                                            }}>
                                                <strong>Payable to:</strong>
                                            </td>
                                            <td style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '14px',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'left',
                                            }}>
                                                Universal Surgical Assistants
                                            </td>
                                            <td style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '15px',
                                                fontWeight: '500',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'center',
                                            }}>
                                                <strong>Email:</strong>
                                            </td>
                                            <td style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '14px',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'left',
                                            }}>
                                                accounting@universalsa.com
                                            </td>
                                            <td style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '15px',
                                                fontWeight: '500',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'right',
                                            }}>
                                                <strong>Phone:</strong>
                                            </td>
                                            <td style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '14px',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'center',
                                            }}>
                                                832-655-4141
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table style={{
                                    width: "900px",
                                    border: '1px solid #cfcfcf',
                                    margin: '0 auto',
                                }}>
                                    <tbody>
                                        <tr>
                                            <td colSpan="5"
                                                style={{
                                                    background: '#4c638c',
                                                    color: '#fff',
                                                    fontFamily: 'Segoe, Segoe UI, Verdana',
                                                    fontSize: '22px',
                                                    fontWeight: '300',
                                                    height: '45px',
                                                    verticalAlign: 'middle'
                                                }}>&nbsp;&nbsp;&nbsp;Invoice
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table style={{
                                    width: "900px",
                                    border: '1px solid #cfcfcf',
                                    margin: '0 auto',
                                }}>
                                    <tbody>
                                        <tr>
                                            <th style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '15px',
                                                fontWeight: '500',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'left',
                                                borderBottom: '1px solid #cfcfcf',
                                            }}>
                                                <strong>Account #</strong>
                                            </th>
                                            <th style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '15px',
                                                fontWeight: '500',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'left',
                                                borderBottom: '1px solid #cfcfcf',
                                            }}>
                                                <strong>DOS</strong>
                                            </th>
                                            <th style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '15px',
                                                fontWeight: '500',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'center',
                                                borderBottom: '1px solid #cfcfcf',
                                                width: '80px',
                                            }}>
                                                <strong>Patient</strong>
                                            </th>
                                            <th style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '15px',
                                                fontWeight: '500',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'center',
                                                borderBottom: '1px solid #cfcfcf',
                                                width: '80px',
                                            }}>
                                                <strong>DOB</strong>
                                            </th>
                                            <th style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '15px',
                                                fontWeight: '500',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'left',
                                                borderBottom: '1px solid #cfcfcf',
                                            }}>
                                                <strong>Procedure</strong>
                                            </th>
                                            <th style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '15px',
                                                fontWeight: '500',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'center',
                                                borderBottom: '1px solid #cfcfcf',
                                                width: '80px',
                                            }}>
                                                <strong>Insurance</strong>
                                            </th>
                                            <th style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '15px',
                                                fontWeight: '500',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'center',
                                                borderBottom: '1px solid #cfcfcf',
                                                width: '80px',
                                            }}>
                                                <strong>Assistant</strong>
                                            </th>
                                            <th style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '15px',
                                                fontWeight: '500',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'center',
                                                borderBottom: '1px solid #cfcfcf',
                                                width: '80px',
                                            }}>
                                                <strong>Surgeon</strong>
                                            </th>
                                            <th style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '15px',
                                                fontWeight: '500',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'center',
                                                borderBottom: '1px solid #cfcfcf',
                                                width: '80px',
                                            }}>
                                                <strong>Facility</strong>
                                            </th>
                                            <th style={{
                                                color: '#6f6f6f',
                                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                                fontSize: '15px',
                                                fontWeight: '500',
                                                height: '30px',
                                                verticalAlign: 'middle',
                                                textAlign: 'center',
                                                borderBottom: '1px solid #cfcfcf',
                                                width: '80px',
                                            }}>
                                                <strong>Total Bill</strong>
                                            </th>
                                        </tr>
                                        {claims.length > 0 ? this.renderClaims() : <td colSpan="9">Record not Found</td>}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table style={{
                                    width: "900px",
                                    margin: '0 auto',
                                }}>
                                    <tbody>
                                    <tr>
                                        <td style={{
                                            color: '#4c638c',
                                            fontFamily: 'Segoe, Segoe UI, Verdana',
                                            fontSize: '30px',
                                            fontWeight: '700',
                                            height: '50px',
                                            verticalAlign: 'middle',
                                            textAlign: 'right',
                                        }}>
                                            Total : ${parseInt(sum)}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{
                                color: '#4c638c',
                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                fontSize: '20px',
                                fontWeight: '700',
                                height: '50px',
                                verticalAlign: 'bottom',
                                textAlign: 'center',
                            }}>
                                Thank you for your Business
                            </td>
                        </tr>
                        <tr>
                            <td style={{
                                height: '100px',
                                textAlign: "center",
                                verticalAlign: 'bottom',
                            }}>
                                <img
                                    src={logo}
                                    width="200"
                                    height="68"
                                    alt=""
                                />
                            </td>
                        </tr>
                        <tr>
                            <td style={{
                                color: '#6f6f6f',
                                fontFamily: 'Segoe, Segoe UI, Verdana',
                                fontSize: '13px',
                                fontWeight: '400',
                                height: '50px',
                                textAlign: 'center',
                            }}>
                                support@coremaxsurgical.com <br />© Coremax Cloud
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        );
    }
}

export default withRouter(ClaimInvoice);