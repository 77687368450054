import { PATIENT } from '../constants/actions';
import { patientService } from '../services/patients';

export const fetchPatients = options => dispatch => {
    return new Promise((resolve, reject) => {
        patientService
            .getPatients(options)
            .then(response => {
                dispatch({
                    type: PATIENT.FETCH_PATIENT_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {});
    });
};

export const createPatient = payload => dispatch => {
    return new Promise((resolve, reject) => {
        patientService
            .createPatient(payload)
            .then(response => {
                dispatch({
                    type: PATIENT.CREATE_PATIENT_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {});
    });
};

export const updatePatient = payload => dispatch => {
    return new Promise((resolve, reject) => {
        patientService
            .updatePatient(payload)
            .then(response => {
                dispatch({
                    type: PATIENT.UPDATE_PATIENT_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {});
    });
};
