import React from "react";
import PropTypes from "prop-types";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {APP_ROUTES, NOTIFICATION_TYPES, RECORDS_PER_PAGE} from "../../constants/common";
import DatePicker from "react-datepicker";
import viewIcon from "../../../assets/images/view.png";
import deleteIcon from "../../../assets/images/delete.png";

import { createPatient, updatePatient } from "../../actions/patients";

import '../../../sass/managepatients.scss';
import {AsyncPaginate} from "react-select-async-paginate";
import {insuranceService} from "../../services/insurance";
import {createNotification} from "../../utils/notificationManager";
import {patientService} from "../../services/patients";
import {formatDate} from "../../utils/date";
import editIcon from "../../../assets/images/edit.png";
import Swal from "sweetalert2";
import {activityService} from "../../services/activities";

const REQUIRED_FIELDS = {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    DOB: 'dob',
    PHONE_NUMBER: 'phoneNumber',
};

class ManagePatient extends React.Component {

    static propTypes = {
        createPatient: PropTypes.func.isRequired,
        updatePatient: PropTypes.func.isRequired,
    };

    state = {
        errors: [],
        activities: [],
        firstName: '',
        lastName: '',
        middleName: '',
        dob: '',
        addressLine1: '',
        addressLine2: '',
        state: '',
        city: '',
        zipCode: '',
        phoneNumber: '',
        additionalNumber: '',
        fax: '',
        email: '',
        gender: 'male',
        country: 'usa',
        notes: '',
        toggleFirstInsurance: false,
        billable: '',
        primaryPayerId: '',
        primaryMasterPayerId: '',
        primaryInsurance: {},
        primaryTypeCode: '',
        primaryPhone: '',
        primaryFax: '',
        primaryAddress: '',
        primaryAdditionalInformation: '',
        primarySubscriberFirstName: '',
        primarySubscriberLastName: '',
        primaryMemberNumber: '',
        primaryGroupNumber: '',
        primaryDob: '',
        toggleGuarantor: false,
        guarantorId: '',
        guarantorFirstName: '',
        guarantorLastName: '',
        guarantorAddress1: '',
        guarantorAddress2: '',
        guarantorZip: '',
        guarantorCity: '',
        guarantorState: '',
        guarantorSpouseName: '',
        guarantorSpousePhone: '',
        guarantorRelationship: '',
        toggleSecondaryInsurance: false,
        secondaryInsurance: {},
        secondaryPayerId: '',
        secondaryMasterPayerId: '',
        secondaryTypeCode: '',
        secondaryPhone: '',
        secondaryFax: '',
        secondaryAddress: '',
        secondaryAdditionalInformation: '',
        secondarySubscriberFirstName: '',
        secondarySubscriberLastName: '',
        secondaryMemberNumber: '',
        secondaryGroupNumber: '',
        secondaryDob: '',
        toggleTertiaryInsurance: false,
        tertiaryInsurance: {},
        tertiaryPayerId: '',
        tertiaryMasterPayerId: '',
        tertiaryTypeCode: '',
        tertiaryPhone: '',
        tertiaryFax: '',
        tertiaryAddress: '',
        tertiaryAdditionalInformation: '',
        tertiarySubscriberFirstName: '',
        tertiarySubscriberLastName: '',
        tertiaryMemberNumber: '',
        tertiaryGroupNumber: '',
        tertiaryDob: '',
        toggleWorker: false,
        workerPatientCondition: '',
        employerName: '',
        dateOfInjury: '',
        illnessIndicator: '',
    };

    componentDidMount() {
        const patientId = this.props.location.state ? this.props.location.state.patientId : '';
        if (patientId) {
            patientService
                .getPatientDetail(patientId)
                .then(response => {
                    this.setState({
                        firstName: response.data.first_name,
                        lastName: response.data.last_name,
                        middleName: response.data.middle_name,
                        dob: Date.parse(response.data.date_of_birth),
                        addressLine1: response.data.address1,
                        addressLine2: response.data.address2,
                        state: response.data.state,
                        city: response.data.city,
                        zipCode: response.data.zip,
                        phoneNumber: this.formatPhone(response.data.phone_number),
                        additionalNumber: response.data.additional_number,
                        fax: response.data.fax,
                        email: response.data.email,
                        gender: response.data.gender,
                        country: response.data.country,
                        notes: response.data.notes,
                        primaryInsurance: {
                            label: response.data.patient_insurance_data && response.data.patient_insurance_data.primary ? `${response.data.patient_insurance_data.primary.description} (${response.data.patient_insurance_data.primary.address1})` : '',
                            value: response.data.patient_insurance_data && response.data.patient_insurance_data.primary ? parseInt(response.data.patient_insurance_data.primary.id) : '',
                        },
                        primarySubscriberFirstName: response.data.patient_insurance_data && response.data.patient_insurance_data.primary_subscriber ? response.data.patient_insurance_data.primary_subscriber.subscriber_first_name: '',
                        primarySubscriberLastName: response.data.patient_insurance_data && response.data.patient_insurance_data.primary_subscriber? response.data.patient_insurance_data.primary_subscriber.subscriber_last_name: '',
                        primaryMemberNumber: response.data.patient_insurance_data && response.data.patient_insurance_data.primary_subscriber ? response.data.patient_insurance_data.primary_subscriber.subscriber_member_number: '',
                        primaryGroupNumber: response.data.patient_insurance_data && response.data.patient_insurance_data.primary_subscriber ? response.data.patient_insurance_data.primary_subscriber.subscriber_group_number: '',
                        primaryDob: response.data.patient_insurance_data && response.data.patient_insurance_data.primary_subscriber ? Date.parse(response.data.patient_insurance_data.primary_subscriber.subscriber_dob): '',
                        guarantorId: response.data.patient_guarantor_data ? response.data.patient_guarantor_data.id : '',
                        guarantorFirstName: response.data.patient_guarantor_data ? response.data.patient_guarantor_data.guarantor_first_name : '',
                        guarantorLastName: response.data.patient_guarantor_data ? response.data.patient_guarantor_data.guarantor_last_name : '',
                        guarantorAddress1: response.data.patient_guarantor_data ? response.data.patient_guarantor_data.guarantor_address_1 : '',
                        guarantorAddress2: response.data.patient_guarantor_data ? response.data.patient_guarantor_data.guarantor_address_2 : '',
                        guarantorZip: response.data.patient_guarantor_data ? response.data.patient_guarantor_data.guarantor_zip : '',
                        guarantorCity: response.data.patient_guarantor_data ? response.data.patient_guarantor_data.guarantor_city : '',
                        guarantorState: response.data.patient_guarantor_data ? response.data.patient_guarantor_data.guarantor_state : '',
                        guarantorSpouseName: response.data.patient_guarantor_data ? response.data.patient_guarantor_data.guarantor_spouse_name : '',
                        guarantorSpousePhone: this.formatPhone(response.data.patient_guarantor_data ? response.data.patient_guarantor_data.guarantor_spouse_phone : ''),
                        guarantorRelationship: response.data.patient_guarantor_data ? response.data.patient_guarantor_data.guarantor_relationship : '',
                        secondaryInsurance: {
                            label: response.data.patient_insurance_data && response.data.patient_insurance_data.secondary ? `${response.data.patient_insurance_data.secondary.description} (${response.data.patient_insurance_data.secondary.address1})` : '',
                            value: response.data.patient_insurance_data && response.data.patient_insurance_data.secondary ? parseInt(response.data.patient_insurance_data.secondary.id) : '',
                        },
                        secondarySubscriberFirstName: response.data.patient_insurance_data && response.data.patient_insurance_data.secondary_subscriber ? response.data.patient_insurance_data.secondary_subscriber.subscriber_first_name: '',
                        secondarySubscriberLastName: response.data.patient_insurance_data && response.data.patient_insurance_data.secondary_subscriber ? response.data.patient_insurance_data.secondary_subscriber.subscriber_last_name: '',
                        secondaryMemberNumber: response.data.patient_insurance_data && response.data.patient_insurance_data.secondary_subscriber ? response.data.patient_insurance_data.secondary_subscriber.subscriber_member_number: '',
                        secondaryGroupNumber: response.data.patient_insurance_data && response.data.patient_insurance_data.secondary_subscriber ? response.data.patient_insurance_data.secondary_subscriber.subscriber_group_number: '',
                        secondaryDob: response.data.patient_insurance_data && response.data.patient_insurance_data.secondary_subscriber ? Date.parse(response.data.patient_insurance_data.secondary_subscriber.subscriber_dob): '',
                        tertiaryInsurance: {
                            label: response.data.patient_insurance_data && response.data.patient_insurance_data.tertiary ? `${response.data.patient_insurance_data.tertiary.description} (${response.data.patient_insurance_data.tertiary.address1})` : '',
                            value: response.data.patient_insurance_data && response.data.patient_insurance_data.tertiary ? parseInt(response.data.patient_insurance_data.tertiary.id) : '',
                        },
                        tertiarySubscriberFirstName: response.data.patient_insurance_data && response.data.patient_insurance_data.tertiary_subscriber ? response.data.patient_insurance_data.tertiary_subscriber.subscriber_first_name: '',
                        tertiarySubscriberLastName: response.data.patient_insurance_data && response.data.patient_insurance_data.tertiary_subscriber ? response.data.patient_insurance_data.tertiary_subscriber.subscriber_last_name: '',
                        tertiaryMemberNumber: response.data.patient_insurance_data && response.data.patient_insurance_data.tertiary_subscriber ? response.data.patient_insurance_data.tertiary_subscriber.subscriber_member_number: '',
                        tertiaryGroupNumber: response.data.patient_insurance_data && response.data.patient_insurance_data.tertiary_subscriber ? response.data.patient_insurance_data.tertiary_subscriber.subscriber_group_number: '',
                        tertiaryDob: response.data.patient_insurance_data && response.data.patient_insurance_data.tertiary_subscriber ? Date.parse(response.data.patient_insurance_data.tertiary_subscriber.subscriber_dob): '',
                        workerPatientCondition: response.data.patient_insurance_data && response.data.patient_insurance_data.workers_comp ? response.data.patient_insurance_data.workers_comp.patient_condition : '',
                        employerName: response.data.patient_insurance_data && response.data.patient_insurance_data.workers_comp ? response.data.patient_insurance_data.workers_comp.employer_name : '',
                        dateOfInjury: response.data.patient_insurance_data && response.data.patient_insurance_data.workers_comp ? response.data.patient_insurance_data.workers_comp.date_of_injury : '',
                        illnessIndicator: response.data.patient_insurance_data && response.data.patient_insurance_data.workers_comp ? response.data.patient_insurance_data.workers_comp.illness_indicator : '',
                    }, () => {
                       this.loadPrimaryInsuranceDetails();
                       this.loadSecondaryInsuranceDetails();
                       this.loadTertiaryInsuranceDetails();
                       this.loadActivities();
                    });
                });
        }
    }

    loadActivities = () => {
        const patientId = this.props.location.state ? this.props.location.state.patientId : '';
        patientService
            .getPatientActivity(patientId)
            .then(response => {
                const activitiesList = response.data.map(activity => {
                    const faceSheetFile = activity.facesheets.length > 0 ? activity.facesheets[0].facesheet_file : '';
                    return [
                        activity.old_event_id,
                        faceSheetFile,
                        activity.facesheets.length > 0 ? activity.facesheets[0].type : '',
                        formatDate(activity.event_start_date),
                        formatDate(activity.event_end_date),
                        activity.facesheets.length > 0 ? activity.facesheets[0].id : '',
                    ];
                });
                this.setState({activities: activitiesList});
            });
    };

    submitPatient = () => {
        if (!this.validateActivity()) {
            return;
        }

        const { createPatient, updatePatient } = this.props;
        const {
            firstName,
            lastName,
            middleName,
            dob,
            addressLine1,
            addressLine2,
            state,
            city,
            zipCode,
            phoneNumber,
            additionalNumber,
            fax,
            email,
            gender,
            country,
            notes,
            billable,
            primaryInsurance,
            primarySubscriberFirstName,
            primarySubscriberLastName,
            primaryMemberNumber,
            primaryGroupNumber,
            primaryDob,
            guarantorId,
            guarantorFirstName,
            guarantorLastName,
            guarantorAddress1,
            guarantorAddress2,
            guarantorZip,
            guarantorCity,
            guarantorState,
            guarantorSpouseName,
            guarantorSpousePhone,
            guarantorRelationship,
            secondaryInsurance,
            secondarySubscriberFirstName,
            secondarySubscriberLastName,
            secondaryMemberNumber,
            secondaryGroupNumber,
            secondaryDob,
            tertiaryInsurance,
            tertiarySubscriberFirstName,
            tertiarySubscriberLastName,
            tertiaryMemberNumber,
            tertiaryGroupNumber,
            tertiaryDob,
            workerPatientCondition,
            employerName,
            dateOfInjury,
            illnessIndicator,
        } = this.state;

        const request = {
            first_name: firstName,
            last_name: lastName,
            middle_name: middleName,
            date_of_birth: formatDate(dob),
            email: email,
            gender: gender,
            phone_number: phoneNumber,
            fax,
            state,
            city,
            country,
            zip: zipCode,
            notes,
            address1: addressLine1,
            address2: addressLine2,
            additional_number: additionalNumber,
            responsible_first_name: guarantorFirstName,
            responsible_last_name: guarantorLastName,
            responsible_phone: guarantorSpousePhone,
            primary_insurance_id: primaryInsurance.value,
            primary: {
                subscriber_first_name: primarySubscriberFirstName,
                subscriber_last_name: primarySubscriberLastName,
                subscriber_dob: formatDate(primaryDob),
                subscriber_member_number: primaryMemberNumber,
                subscriber_group_number: primaryGroupNumber,
            },
            secondary_insurance_id: secondaryInsurance.value,
            secondary: {
                subscriber_first_name: secondarySubscriberFirstName,
                subscriber_last_name: secondarySubscriberLastName,
                subscriber_dob: formatDate(secondaryDob),
                subscriber_member_number: secondaryMemberNumber,
                subscriber_group_number: secondaryGroupNumber,
            },
            tertiary_insurance_id: tertiaryInsurance.value,
            tertiary: {
                subscriber_first_name: tertiarySubscriberFirstName,
                subscriber_last_name: tertiarySubscriberLastName,
                subscriber_dob: formatDate(tertiaryDob),
                subscriber_member_number: tertiaryMemberNumber,
                subscriber_group_number: tertiaryGroupNumber,
            },
            guarantor: {
                guarantor_id: guarantorId,
                guarantor_first_name: guarantorFirstName,
                guarantor_last_name: guarantorLastName,
                guarantor_address1: guarantorAddress1,
                guarantor_address2: guarantorAddress2,
                guarantor_zip: guarantorZip,
                guarantor_city: guarantorCity,
                guarantor_state: guarantorState,
                guarantor_relatioship: guarantorRelationship,
                guarantor_spouse: guarantorSpouseName,
                guarantor_phone: guarantorSpousePhone,
            },
            worker_comp: {
                patient_condition: workerPatientCondition,
                employer_name: employerName,
                date_of_injury: formatDate(dateOfInjury),
                illness_indicator: illnessIndicator,
            }
        };

        const patientId = this.props.location.state ? this.props.location.state.patientId : '';
        if (patientId) {
            const payload = {...request, 'id': patientId};
            updatePatient(payload)
                .then(res => {
                    if (res.response.data.success) {
                        this.props.history.push(APP_ROUTES.PATIENTS_DASHBOARD);
                    } else {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                        const screen = this.props.location.state ? this.props.location.state.screen : '';
                        if(screen) {
                            this.props.history.push(screen);
                        } else {
                            this.props.history.push(APP_ROUTES.PATIENTS_DASHBOARD);
                        }
                    }
                })
                .catch(err => {
                    createNotification(NOTIFICATION_TYPES.ERROR, `Unable to update patient ${err.message}`);
                });
        } else {
            createPatient(request)
                .then(res => {
                    if (res.response.data.success) {
                        this.props.history.push(APP_ROUTES.PATIENTS_DASHBOARD);
                    } else {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                        const screen = this.props.location.state ? this.props.location.state.screen : '';
                        if(screen) {
                            this.props.history.push(screen);
                        } else {
                            this.props.history.push(APP_ROUTES.PATIENTS_DASHBOARD);
                        }
                    }
                })
                .catch(err => {
                    createNotification(NOTIFICATION_TYPES.ERROR, `Unable to create patient ${err.message}`);
                });
        }
    };

    toggleFirstInsurance = () => {
        this.setState(prevState =>
            ({
                toggleFirstInsurance: !prevState.toggleFirstInsurance
            }));
    };

    toggleGuarantorInsurance = () => {
        this.setState(prevState =>
            ({
                toggleGuarantor: !prevState.toggleGuarantor
            }));
    };

    toggleSecondaryInsurance = () => {
        this.setState(prevState =>
            ({
                toggleSecondaryInsurance: !prevState.toggleSecondaryInsurance
            }));
    };

    toggleTertiaryInsurance = () => {
        this.setState(prevState =>
            ({
                toggleTertiaryInsurance: !prevState.toggleTertiaryInsurance
            }));
    };

    toggleWorkerInsurance = () => {
        this.setState(prevState =>
            ({
                toggleWorker: !prevState.toggleWorker
            }));
    };

    loadInsurance = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }
        options = {...options, api_type: 'react' };
        return insuranceService
            .getInsurances({ params: options })
            .then(response => {
                const options = response.data.map(insurance => (
                    {
                        value: insurance.id,
                        label: `${insurance.description} (${insurance.address1})`,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    handlePhoneChange = (str) => {
        this.setState({phoneNumber: this.formatPhone(str)});
    };

    handlePrimaryPhoneChange = (str) => {
        this.setState({primaryPhone: this.formatPhone(str)});
    };

    handleGuarantorPhoneChange = (str) => {
        this.setState({guarantorSpousePhone: this.formatPhone(str)});
    };

    handleSecondaryPhoneChange = (str) => {
        this.setState({secondaryPhone: this.formatPhone(str)});
    };

    handleTertiaryPhoneChange = (str) => {
        this.setState({tertiaryPhone: this.formatPhone(str)});
    };

    formatPhone = (str) => {
        if (str) {
            let phone = '';
            str = str.replace(/\D/g, '').substring(0,10);
            if (str.length < 3) {
                phone += str;
            } else if (str.length < 6) {
                phone += str.substring(0, 3) + '-';
                if (str.length > 3) {
                    phone += str.substring(3, str.length);
                }
            } else {
                phone += str.substring(0, 3) + '-' + str.substring(3, 6) + '-' + str.substring(6, 10);
            }
            return phone;
        }
        return str;
    };

    validateActivity = () => {
        const {
            firstName,
            lastName,
            dob,
            phoneNumber,
            errors,
        } = this.state;
        let isValid = true;

        if (firstName.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.FIRST_NAME] });
            isValid = false;
        } else if (lastName.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.LAST_NAME] });
            isValid = false;
        } else if (dob.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.DOB] });
            isValid = false;
        } else if (phoneNumber.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.PHONE_NUMBER] });
            isValid = false;
        }

        if (!isValid) {
            createNotification(NOTIFICATION_TYPES.ERROR, 'Please select required values');
        }

        return isValid;
    };

    resetErrors = (field) => {
        this.setState({
            errors: this.state.errors.filter(fieldName => fieldName !== field)
        });
    }

    hasErrors = (field) => {
        return this.state.errors.includes(field);
    };

    primarySubscriber = () => {
        this.setState({
            primarySubscriberFirstName: this.state.firstName,
            primarySubscriberLastName: this.state.lastName,
            primaryDob: this.state.dob,
        });
    };

    secondarySubscriber = () => {
        this.setState({
            secondarySubscriberFirstName: this.state.firstName,
            secondarySubscriberLastName: this.state.lastName,
            secondaryDob: this.state.dob,
        });
    };

    tertiarySubscriber = () => {
        this.setState({
            tertiarySubscriberFirstName: this.state.firstName,
            tertiarySubscriberLastName: this.state.lastName,
            tertiaryDob: this.state.dob,
        });
    };

    guarantorDetails = () => {
        this.setState({
            guarantorFirstName: this.state.firstName,
            guarantorLastName: this.state.lastName,
            guarantorAddress1: this.state.addressLine1,
            guarantorAddress2: this.state.addressLine2,
            guarantorZip: this.state.zipCode,
            guarantorState: this.state.state,
            guarantorCity: this.state.city,
        });
    };

    loadPrimaryInsuranceDetails = () => {
        return insuranceService
            .getInsuranceDetail(this.state.primaryInsurance.value)
            .then(response => {
                this.setState({
                    primaryPayerId: response.data.payor_code,
                    primaryMasterPayerId: response.data.id,
                    primaryTypeCode: response.data.insurance_type,
                    primaryPhone: response.data.phone,
                    primaryFax: response.data.fax,
                    primaryAddress:
                        `${response.data.address1 ? response.data.address1 : ''} ${response.data.address2 ? response.data.address2 : ''} ${response.data.city ? response.data.city : ''} ${response.data.state ? response.data.state : ''} ${response.data.zip ? response.data.zip : ''}`
                });
            });
    };

    loadSecondaryInsuranceDetails = () => {
        return insuranceService
            .getInsuranceDetail(this.state.secondaryInsurance.value)
            .then(response => {
                this.setState({
                    secondaryPayerId: response.data.payor_code,
                    secondaryMasterPayerId: response.data.id,
                    secondaryTypeCode: response.data.insurance_type,
                    secondaryPhone: response.data.phone,
                    secondaryFax: response.data.fax,
                    secondaryAddress:
                        `${response.data.address1 ? response.data.address1 : ''} ${response.data.address2 ? response.data.address2 : ''} ${response.data.city ? response.data.city : ''} ${response.data.state ? response.data.state : ''} ${response.data.zip ? response.data.zip : ''}`
                });
            });
    };

    loadTertiaryInsuranceDetails = () => {
        return insuranceService
            .getInsuranceDetail(this.state.tertiaryInsurance.value)
            .then(response => {
                this.setState({
                    tertiaryPayerId: response.data.payor_code,
                    tertiaryMasterPayerId: response.data.id,
                    tertiaryTypeCode: response.data.insurance_type,
                    tertiaryPhone: response.data.phone,
                    tertiaryFax: response.data.fax,
                    tertiaryAddress:
                        `${response.data.address1 ? response.data.address1 : ''} ${response.data.address2 ? response.data.address2 : ''} ${response.data.city ? response.data.city : ''} ${response.data.state ? response.data.state : ''} ${response.data.zip ? response.data.zip : ''}`
                });
            });
    };

    resetPrimary = () => {
        this.setState({
            billable: '',
            primaryPayerId: '',
            primaryMasterPayerId: '',
            primaryInsurance: {},
            primaryTypeCode: '',
            primaryPhone: '',
            primaryFax: '',
            primaryAddress: '',
            primaryAdditionalInformation: '',
            primarySubscriberFirstName: '',
            primarySubscriberLastName: '',
            primaryMemberNumber: '',
            primaryGroupNumber: '',
            primaryDob: '',
        });
    };

    resetSecondary = () => {
        this.setState({
            secondaryInsurance: {},
            secondaryPayerId: '',
            secondaryMasterPayerId: '',
            secondaryTypeCode: '',
            secondaryPhone: '',
            secondaryFax: '',
            secondaryAddress: '',
            secondaryAdditionalInformation: '',
            secondarySubscriberFirstName: '',
            secondarySubscriberLastName: '',
            secondaryMemberNumber: '',
            secondaryGroupNumber: '',
            secondaryDob: '',
        });
    };

    resetTertiary = () => {
        this.setState({
            tertiaryInsurance: {},
            tertiaryPayerId: '',
            tertiaryMasterPayerId: '',
            tertiaryTypeCode: '',
            tertiaryPhone: '',
            tertiaryFax: '',
            tertiaryAddress: '',
            tertiaryAdditionalInformation: '',
            tertiarySubscriberFirstName: '',
            tertiarySubscriberLastName: '',
            tertiaryMemberNumber: '',
            tertiaryGroupNumber: '',
            tertiaryDob: '',
        });
    };

    renderHeadColumn = () => {
        const headColumn = [
            'Event Id',
            'File Name',
            'Type',
            'Start Date',
            'End Date',
            'Action',
        ];

        return headColumn.map(column => (
            <th className="listing-table-head-column">
                {column}
            </th>
        ));
    };

    edit = activityId => {
        this.props.history.push({
            pathname: APP_ROUTES.EDIT_ACTIVITY,
            state: {
                activityId,
                activePage: 'activities',
            }
        });
    }

    deleteFile = (faceSheetId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                activityService
                    .deleteActivityFaceSheet(faceSheetId)
                    .then(response => {
                        this.loadActivities();
                    })
            }
        })
    };

    renderActivityList = () => {
        const { activities } = this.state;

        return activities.map(listRow => {
            const item = listRow.map((listItem, index) => {
                if (index < listRow.length - 1)  {
                    let faceSheetFile, fileName;
                    if (index === 1) {
                        faceSheetFile = listItem.length > 0 ? listItem.split('/') : [];
                        fileName = faceSheetFile.length > 0 ? faceSheetFile[faceSheetFile.length - 1] : '';
                    }
                    return (
                        <td className="listing-table-column">
                            {`${index === 1 ? fileName : listItem}  `}
                            {index === 1 && listItem && (
                                <a
                                    href={listItem}
                                    target = "_blank">
                                    <img
                                        src={viewIcon}
                                        alt="view"
                                    />
                                </a>

                            )}
                            {index === 1 && listItem && (
                                <img
                                    src={deleteIcon}
                                    onClick={() => { this.deleteFile(listRow[listRow.length - 1]) }}
                                    alt="view"
                                />
                            )}
                        </td>
                    );
                } else {
                    return (
                        <td className="listing-table-column">
                            <img
                                src={editIcon}
                                onClick={() => {this.edit(listRow[0])}}
                                alt="edit"
                            />
                        </td>
                    );
                }
            });

            return (
                <tr className="listing-table-rows">
                    {item}
                </tr>
            );
        });
    };

    cancel = () => {
        const screen = this.props.location.state ? this.props.location.state.screen : '';
        if(screen) {
            this.props.history.push(screen);
        } else {
            this.props.history.push(APP_ROUTES.PATIENTS_DASHBOARD);
        }
    };

    render() {
        const patientId = this.props.location.state ? this.props.location.state.patientId : '';
        const activePage = this.props.location.state ? this.props.location.state.activePage : '';
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col"
                     style={{
                         minHeight: '190vh',
                     }}>
                    <Sidebar activePage={activePage}/>
                </div>
                <TopNavbar />
                <div className="manage-activity-right-col">
                    <div className="activity-row">
                        <div className="page-title">
                            {patientId &&
                            <h2 className="heading-custom">Edit Patient</h2>
                            }
                            {!patientId &&
                            <h2 className="heading-custom">Add Patient</h2>
                            }
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>First Name <span className="required">*</span></label>
                            <input
                                type="text"
                                className={this.hasErrors(REQUIRED_FIELDS.FIRST_NAME) ? 'error code-input' : 'code-input'}
                                value={this.state.firstName}
                                onChange={event => {
                                    this.resetErrors(REQUIRED_FIELDS.FIRST_NAME);
                                    this.setState({ firstName: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Last Name <span className="required">*</span></label>
                            <input
                                type="text"
                                className={this.hasErrors(REQUIRED_FIELDS.LAST_NAME) ? 'error code-input' : 'code-input'}
                                value={this.state.lastName}
                                onChange={event => {
                                    this.resetErrors(REQUIRED_FIELDS.LAST_NAME);
                                    this.setState({ lastName: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Middle Name</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.middleName}
                                onChange={event => this.setState({ middleName: event.target.value })}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>DOB(MM/DD/YYYY) <span className="required">*</span></label>
                            <br/>
                            <DatePicker
                                isClearable
                                className={this.hasErrors(REQUIRED_FIELDS.DOB) ? 'error filter-input' : 'filter-input'}
                                selected={this.state.dob}
                                onChange={date => {
                                    this.resetErrors(REQUIRED_FIELDS.DOB);
                                    this.setState({ dob: date })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Gender</label>
                            <select
                                className="filter-input"
                                onChange={event => this.setState({ gender: event.target.value })}>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Address Line 1</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.addressLine1}
                                onChange={event => this.setState({ addressLine1: event.target.value })}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Address Line 2</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.addressLine2}
                                onChange={event => this.setState({ addressLine2: event.target.value })}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>State</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.state}
                                onChange={event => this.setState({ state: event.target.value })}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>City</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.city}
                                onChange={event => this.setState({ city: event.target.value })}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Zip Code</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.zipCode}
                                onChange={event => this.setState({ zipCode: event.target.value })}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Phone Number <span className="required">*</span></label>
                            <input
                                type="text"
                                className={this.hasErrors(REQUIRED_FIELDS.PHONE_NUMBER) ? 'error code-input' : 'code-input'}
                                value={this.state.phoneNumber}
                                onChange={event => {
                                    this.resetErrors(REQUIRED_FIELDS.PHONE_NUMBER);
                                    this.handlePhoneChange(event.target.value)
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Additional Number</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.additionalNumber}
                                onChange={event => this.setState({ additionalNumber: this.formatPhone(event.target.value) })}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Fax</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.fax}
                                onChange={event => this.setState({ fax: this.formatPhone(event.target.value) })}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Email Address</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.email}
                                onChange={event => this.setState({ email: event.target.value })}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Country</label>
                            <select
                                className="filter-input"
                                onChange={event => this.setState({ country: event.target.value })}>
                                <option value="usa">United States</option>
                            </select>
                        </div>
                        <div className="col-md-12 mrgn-btm10">
                            <label>Notes</label>
                            <textarea
                                className="code-input"
                                value={this.state.notes}
                                onChange={event => this.setState({ notes: event.target.value })}
                            />
                        </div>
                        <div className="top-padding">
                            <div className="page-title" onClick={this.toggleFirstInsurance}>
                                <h2 className="heading-custom">+/- Primary Insurance Information</h2>
                            </div>
                            { this.state.toggleFirstInsurance && (
                                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    <div className="col-md-12 mrgn-btm10">
                                        <button
                                            onClick={this.resetPrimary}
                                            className="filter-save-button">
                                            Remove Payer
                                        </button>
                                    </div>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>Billable / Non-Billable / Non-Billable Commercial</label>
                                        <select
                                            className="filter-input"
                                            onChange={event => this.setState({ billable: event.target.value })}>
                                            <option value="billable">Billable</option>
                                            <option value="non-billable">Non-Billable</option>
                                            <option value="non-billable-commercial">Non-Billable Commercial</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>Primary Electronic Payer ID</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.primaryPayerId}
                                            onChange={event => this.setState({ primaryPayerId: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-12 mrgn-btm10">
                                        <label>Primary Insurance Name</label>
                                        <AsyncPaginate
                                            placeholder="-- Select Primary Insurance --"
                                            value={this.state.primaryInsurance}
                                            loadOptions={this.loadInsurance}
                                            onChange={value => {
                                                this.setState({primaryInsurance: value },
                                                    () => this.loadPrimaryInsuranceDetails()
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-3 mrgn-btm10">
                                        <label>Master Payer ID</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.primaryMasterPayerId}
                                            onChange={event => this.setState({ primaryMasterPayerId: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-3 mrgn-btm10">
                                        <label>Insurance Type Code</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.primaryTypeCode}
                                            onChange={event => this.setState({ primaryTypeCode: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-3 mrgn-btm10">
                                        <label>Primary Insurance Phone</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.primaryPhone}
                                            onChange={event => {
                                                this.handlePrimaryPhoneChange(event.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-3 mrgn-btm10">
                                        <label>Primary Insurance Fax</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.primaryFax}
                                            onChange={event => this.setState({ primaryFax: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-12 mrgn-btm10">
                                        <label>Primary Insurance Address</label>
                                        <textarea
                                            className="code-input"
                                            value={this.state.primaryAddress}
                                            onChange={event => this.setState({ primaryAddress: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-12 mrgn-btm10">
                                        <button
                                            onClick={this.primarySubscriber}
                                            className="filter-save-button">
                                            Fill Form With Patient Info
                                        </button>
                                    </div>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>Subscriber First Name</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.primarySubscriberFirstName}
                                            onChange={event => this.setState({ primarySubscriberFirstName: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>Subscriber Last Name</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.primarySubscriberLastName}
                                            onChange={event => this.setState({ primarySubscriberLastName: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Member Number</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.primaryMemberNumber}
                                            onChange={event => this.setState({ primaryMemberNumber: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Group Number</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.primaryGroupNumber}
                                            onChange={event => this.setState({ primaryGroupNumber: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Subscriber DOB</label>
                                        <br/>
                                        <DatePicker
                                            isClearable
                                            className="filter-input"
                                            selected={this.state.primaryDob}
                                            onChange={date => this.setState({ primaryDob: date })}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="top-padding">
                            <div className="page-title" onClick={this.toggleGuarantorInsurance}>
                                <h2 className="heading-custom">+/- Guarantor Information</h2>
                            </div>
                                { this.state.toggleGuarantor && (
                                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    <div className="col-md-12 mrgn-btm10">
                                        <button
                                            onClick={this.guarantorDetails}
                                            className="filter-save-button">
                                            Same as Patient
                                        </button>
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Guarantor First Name</label>
                                        <input
                                            type="text"
                                            className='code-input'
                                            value={this.state.guarantorFirstName}
                                            onChange={event => this.setState({ guarantorFirstName: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Guarantor Last Name</label>
                                        <input
                                            type="text"
                                            className='code-input'
                                            value={this.state.guarantorLastName}
                                            onChange={event => this.setState({ guarantorLastName: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Address Line 1</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.guarantorAddress1}
                                            onChange={event => this.setState({ guarantorAddress1: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Address Line 2</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.guarantorAddress2}
                                            onChange={event => this.setState({ guarantorAddress2: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Zip Code</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.guarantorZip}
                                            onChange={event => this.setState({ guarantorZip: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>City</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.guarantorCity}
                                            onChange={event => this.setState({ guarantorCity: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>State</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.guarantorState}
                                            onChange={event => this.setState({ guarantorState: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>Spouse or Partner's Name/Parents Name (if patient is a minor)</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.guarantorSpouseName}
                                            onChange={event => this.setState({ guarantorSpouseName: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>Spouse, Partner or Partners Phone</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.guarantorSpousePhone}
                                            onChange={event => this.handleGuarantorPhoneChange(event.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>Relationship To Patient</label>
                                        <select
                                            className="filter-input"
                                            value={this.state.guarantorRelationship}
                                            onChange={event => this.setState({ guarantorRelationship: event.target.value })}>
                                            <option value="parent">Parent</option>
                                            <option value="child">Child</option>
                                            <option value="spouse">Spouse</option>
                                            <option value="brother">Brother</option>
                                            <option value="sister">Sister</option>
                                            <option value="unknown">Unknown</option>
                                        </select>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="top-padding">
                            <div className="page-title" onClick={this.toggleSecondaryInsurance}>
                                <h2 className="heading-custom">+/- Secondary Insurance Information</h2>
                            </div>
                            { this.state.toggleSecondaryInsurance && (
                                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    <div className="col-md-12 mrgn-btm10">
                                        <button
                                            onClick={this.resetSecondary}
                                            className="filter-save-button">
                                            Remove Payer
                                        </button>
                                    </div>
                                    <div className="col-md-12 mrgn-btm10">
                                        <label>Secondary Insurance Name</label>
                                        <AsyncPaginate
                                            placeholder="-- Select Secondary Insurance --"
                                            value={this.state.secondaryInsurance}
                                            loadOptions={this.loadInsurance}
                                            onChange={value => {
                                                this.setState({secondaryInsurance: value },
                                                    () => this.loadSecondaryInsuranceDetails()
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Secondary Payer ID</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.secondaryPayerId}
                                            onChange={event => this.setState({ secondaryPayerId: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Master Payer ID</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.secondaryMasterPayerId}
                                            onChange={event => this.setState({ secondaryMasterPayerId: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Insurance Type Code</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.secondaryTypeCode}
                                            onChange={event => this.setState({ secondaryTypeCode: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>Secondary Insurance Phone</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.secondaryPhone}
                                            onChange={event => {
                                                this.handleSecondaryPhoneChange(event.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>Secondary Insurance Fax</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.secondaryFax}
                                            onChange={event => this.setState({ secondaryFax: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-12 mrgn-btm10">
                                        <label>Secondary Insurance Address</label>
                                        <textarea
                                            className="code-input"
                                            value={this.state.secondaryAddress}
                                            onChange={event => this.setState({ secondaryAddress: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-12 mrgn-btm10">
                                        <button
                                            onClick={this.secondarySubscriber}
                                            className="filter-save-button">
                                            Fill Form With Patient Info
                                        </button>
                                    </div>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>Subscriber First Name</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.secondarySubscriberFirstName}
                                            onChange={event => this.setState({ secondarySubscriberFirstName: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>Subscriber Last Name</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.secondarySubscriberLastName}
                                            onChange={event => this.setState({ secondarySubscriberLastName: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Member Number</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.secondaryMemberNumber}
                                            onChange={event => this.setState({ secondaryMemberNumber: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Group Number</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.secondaryGroupNumber}
                                            onChange={event => this.setState({ secondaryGroupNumber: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Subscriber DOB</label>
                                        <br/>
                                        <DatePicker
                                            isClearable
                                            className="filter-input"
                                            selected={this.state.secondaryDob}
                                            onChange={date => this.setState({ secondaryDob: date })}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="top-padding">
                            <div className="page-title" onClick={this.toggleTertiaryInsurance}>
                                <h2 className="heading-custom">+/- Tertiary Insurance Information</h2>
                            </div>
                            { this.state.toggleTertiaryInsurance && (
                                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    <div className="col-md-12 mrgn-btm10">
                                        <button
                                            onClick={this.resetTertiary}
                                            className="filter-save-button">
                                            Remove Payer
                                        </button>
                                    </div>
                                    <div className="col-md-12 mrgn-btm10">
                                        <label>Tertiary Insurance Name</label>
                                        <AsyncPaginate
                                            placeholder="-- Select Secondary Insurance --"
                                            value={this.state.tertiaryInsurance}
                                            loadOptions={this.loadInsurance}
                                            onChange={value => {
                                                this.setState({tertiaryInsurance: value },
                                                    () => this.loadTertiaryInsuranceDetails()
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Tertiary Payer ID</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.tertiaryPayerId}
                                            onChange={event => this.setState({ tertiaryPayerId: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Master Payer ID</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.tertiaryMasterPayerId}
                                            onChange={event => this.setState({ tertiaryMasterPayerId: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Insurance Type Code</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.tertiaryTypeCode}
                                            onChange={event => this.setState({ tertiaryTypeCode: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>Tertiary Insurance Phone</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.tertiaryPhone}
                                            onChange={event => {
                                                this.handleTertiaryPhoneChange(event.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>Tertiary Insurance Fax</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.tertiaryFax}
                                            onChange={event => this.setState({ tertiaryFax: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-12 mrgn-btm10">
                                        <label>Tertiary Insurance Address</label>
                                        <textarea
                                            className="code-input"
                                            value={this.state.tertiaryAddress}
                                            onChange={event => this.setState({ tertiaryAddress: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-12 mrgn-btm10">
                                        <button
                                            onClick={this.tertiarySubscriber}
                                            className="filter-save-button">
                                            Fill Form With Patient Info
                                        </button>
                                    </div>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>Subscriber First Name</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.tertiarySubscriberFirstName}
                                            onChange={event => this.setState({ tertiarySubscriberFirstName: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>Subscriber Last Name</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.tertiarySubscriberLastName}
                                            onChange={event => this.setState({ tertiarySubscriberLastName: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Member Number</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.tertiaryMemberNumber}
                                            onChange={event => this.setState({ tertiaryMemberNumber: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Group Number</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.tertiaryGroupNumber}
                                            onChange={event => this.setState({ tertiaryGroupNumber: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Subscriber DOB</label>
                                        <br/>
                                        <DatePicker
                                            isClearable
                                            className="filter-input"
                                            selected={this.state.tertiaryDob}
                                            onChange={date => this.setState({ tertiaryDob: date })}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="top-padding">
                            <div className="page-title" onClick={this.toggleWorkerInsurance}>
                                <h2 className="heading-custom">
                                    +/- Worker's Comp or Other Property & Casualty Claim Details (For Event ID)
                                </h2>
                            </div>
                            { this.state.toggleWorker && (
                                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>Patient's Condition Related To</label>
                                        <select
                                            value={this.state.workerPatientCondition}
                                            className="filter-input"
                                            onChange={event => this.setState({ workerPatientCondition: event.target.value })}>
                                            <option value="accident">Auto Accident</option>
                                            <option value="employment">Employment</option>
                                            <option value="other">Other Reason</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>Employer Name</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.employerName}
                                            onChange={event => this.setState({ employerName: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>Date of Injury</label>
                                        <br/>
                                        <DatePicker
                                            isClearable
                                            className="filter-input"
                                            selected={Date.parse(this.state.dateOfInjury)}
                                            onChange={date => this.setState({ dateOfInjury: date })}
                                        />
                                    </div>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>Illness Indicator</label>
                                        <select
                                            className="filter-input"
                                            value={this.state.illnessIndicator}
                                            onChange={event => this.setState({ illnessIndicator: event.target.value })}>
                                            <option value="illness">illness</option>
                                            <option value="indicator">indicator</option>
                                            <option value="injury">injury</option>
                                        </select>
                                    </div>
                                </div>
                            )}
                        </div>

                        {patientId && (
                            <div className="top-padding">
                                <div className="table-responsive">
                                    <table className="listing-table">
                                        <thead className="listing-table-head">
                                        <tr>
                                            {this.renderHeadColumn()}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.renderActivityList()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}

                        <div className="filter-footer">
                            {patientId &&
                            <button
                                onClick={this.submitPatient}
                                className="filter-save-button">
                                Update
                            </button>
                            }
                            {!patientId &&
                            <button
                                onClick={this.submitPatient}
                                className="filter-save-button">
                                Submit
                            </button>
                            }
                            <button
                                onClick={this.cancel}
                                className="filter-cancel-button">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createPatient,
            updatePatient,
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(ManagePatient));