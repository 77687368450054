import React from 'react';

import '../../../sass/dashboard.scss';
import '../../../sass/patients.scss';

import { formatDate } from "../../utils/date";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import { fetchPatients } from '../../actions/patients';
import Sidebar from '../organisms/Sidebar';
import TopNavbar from "../organisms/TopNavbar";
import PatientListingTable from "../atom/PatientListingTable";
import {APP_ROUTES, LOCAL_STORAGE_KEYS, PATIENT_LISTING_COLUMNS} from "../../constants/common";
import PropTypes from "prop-types";
import { patientService } from "../../services/patients";

class PatientsDashboard extends React.Component {
    static propTypes = {
        totalRecords: PropTypes.number.isRequired,
    };

    state = {
        loading: false,
        currentPage: 1,
        searchByName: '',
        searchByDob: '',
        searched: false,
        searchedPatients: [],
        searchedTotalRecords: 0,
    };

    componentWillMount() {
        this.setState({loading: true});
        const options = {
            page: this.state.currentPage,
        };
        this.props.fetchPatients({ params: options })
            .then(res => {
                this.setState({loading: false});
            });
    }

    showManagePatient = patientId => this.props.history.push({
        pathname: APP_ROUTES.EDIT_PATIENT,
        state: {
            activePage: this.props.activePage || this.props.location.state.activePage,
            patientId,
        }
    });

    deletePatient = (patient) => {
        patientService
            .deletePatient(patient)
            .then(response => {
                this.goToPage(1);
            });
    };

    resetPatientSearch = () => {
        this.setState({
            searchByName: '',
            searchByDob: '',
            searchedPatients: [],
            searched: false,
            searchedTotalRecords: 0,
        });
    }

    searchPatient = () => {
        const {
            searchByName,
            searchByDob,
        } = this.state;
        let options = {};
        if (searchByName) {
            options = {...options, name: searchByName };
        }
        if (searchByDob) {
            options = {...options, dob: formatDate(searchByDob) };
        }
        options = {...options, api_type: 'react' };
        this.setState({
            searched: true,
            loading: true,
        });
        this.setCurrentPage(1);
        patientService
            .searchPatient({params: options})
            .then(response => {
                this.setState({
                    searchedTotalRecords: parseInt(response.records),
                    searchedPatients: response.data.map(patient => {
                        return [
                            `${patient.first_name ? patient.first_name : ''} ${patient.last_name ? patient.last_name : ''}`.toUpperCase(),
                            formatDate(patient.date_of_birth),
                            new Date().getFullYear() - parseInt(patient.date_of_birth.split('-')[0]),
                            patient.gender ? patient.gender : '',
                            patient.old_id,
                        ];
                    })
                });
                this.setState({loading: false});
            });
    }

    setLoading = (flag) => {
        this.setState({loading: flag});
    };

    setCurrentPage = (num) => {
        this.setState({currentPage: num});
    };

    goToPage = (page) => {
        this.setLoading(true);
        this.setCurrentPage(page);

        const options = {
            page: page,
        };
        this.props.fetchPatients({ params: options })
            .then(res => {
                this.setLoading(false);
            });
    };

    list = () => {
        const { patients = [] } = this.props;
        return patients.map(patient => {
            return [
                `${patient.first_name ? patient.first_name : ''} ${patient.last_name ? patient.last_name : ''}`.toUpperCase(),
                formatDate(patient.date_of_birth),
                new Date().getFullYear() - parseInt(patient.date_of_birth.split('-')[0]),
                patient.gender ? patient.gender : '',
                patient.old_id,
            ];
        });
    };

    showAdd = () => this.props.history.push(APP_ROUTES.ADD_PATIENT);

    render() {
        const {
            searched,
            searchedPatients,
            searchedTotalRecords,
        } = this.state;
        const activePage = this.props.activePage || this.props.location.state.activePage;
        const loggedInUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER));
        return(
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar activePage={activePage} />
                </div>
                <TopNavbar />
                <div
                    style={{
                        minHeight: '760px'
                    }}
                    className="right-col">
                    <div className="page-title">
                        <div className="title_left">
                            <h3 style={{
                                fontFamily: 'Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif',
                                fontSize: '24px',
                                fontWeight: '400',
                                lineHeight: '1.471',
                            }}>Patients</h3>
                        </div>
                    </div>
                    { loggedInUser.isAddAccess && (
                        <div className="filter-footer-header">
                            <button
                                onClick={() => { this.showAdd(true) }}
                                className="patients-filter-button">
                                Add New Patient
                            </button>
                        </div>
                    )}
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: '100%',
                    }}>
                        <div style={{
                            paddingLeft: '0',
                        }} className="col-md-3 mrgn-btm10">
                            <label>Patient Name</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.searchByName}
                                onChange={event => {
                                    this.setState({ searchByName: event.target.value },
                                        () => {
                                            if (this.state.searchByName.length >= 3) {
                                                this.searchPatient();
                                            } else if (this.state.searchByName.length === 0) {
                                                this.resetPatientSearch();
                                            }
                                        }
                                    )
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>DOB</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.searchByDob}
                                onChange={event => this.setState({ searchByDob: event.target.value },
                                    () => {
                                        if (this.state.searchByDob.length === 10) {
                                            this.searchPatient();
                                        } else if (this.state.searchByDob.length === 0) {
                                            this.resetPatientSearch();
                                        }

                                    }
                                )}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <button
                                style={{
                                    marginTop: '32px',
                                    width: '100px',
                                    height: '38px',
                                }}
                                onClick={this.resetPatientSearch}
                                className="filter-save-button">
                                Reset
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="activities-header">
                            <PatientListingTable
                                isLoading={this.state.loading}
                                currentPage={this.state.currentPage}
                                goToPage={this.goToPage}
                                edit={this.showManagePatient}
                                delete={this.deletePatient}
                                headColumn={PATIENT_LISTING_COLUMNS}
                                listing={searched && searchedPatients.length > 0 ? searchedPatients : this.list()}
                                totalRecords={searched && searchedTotalRecords > 0 ? searchedTotalRecords : this.props.totalRecords}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        patients: state.patientReducer.patients,
        totalRecords: state.patientReducer.totalPatients,
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchPatients,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(PatientsDashboard));