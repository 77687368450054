import React from 'react';
import {withRouter} from "react-router-dom";
import {APP_ROUTES, EMAIL_REGEX, LOCAL_STORAGE_KEYS, NOTIFICATION_TYPES} from "../../constants/common";
import {createNotification} from "../../utils/notificationManager";
import {bindActionCreators} from "redux";
import {authenticate} from "../../actions/authentication";
import {connect} from "react-redux";

class LoginMiddleware extends React.Component {
    componentDidMount() {
        const { authenticate } = this.props;
        const user = localStorage.getItem(LOCAL_STORAGE_KEYS.USER);
        const request = {"email": user};

        if (!this.validate(user)) {
            createNotification(NOTIFICATION_TYPES.ERROR, 'Invalid Email');
            return;
        }

        authenticate(request)
            .then(res => {
                if (res.response.data.token) {
                    this.props.history.push(APP_ROUTES.DASHBOARD);
                } else {
                    createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
                }
            })
            .catch(err => { });
    }

    validate = (email) => {
        const pattern = new RegExp(EMAIL_REGEX);
        return pattern.test(email);
    };

    render() {
        return (
            <></>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            authenticate,
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(LoginMiddleware));