import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import {API_CONSTANTS} from "../constants/api";

function getModules(options) {
    return apiClient()
        .get(API_CONSTANTS.LIST_MODULES, options)
        .then(handleResponse)
        .catch(handleError);
}

export const moduleService = {
    getModules,
}