import { INSURANCE } from '../constants/actions';
import { insuranceService } from '../services/insurance';


export const fetchInsurance = options => dispatch => {
    return new Promise((resolve, reject) => {
        insuranceService
            .getInsurances(options)
            .then(response => {
                dispatch({
                    type: INSURANCE.FETCH_INSURANCE_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {});
    });
};

export const createInsurance = payload => dispatch => {
    return new Promise((resolve, reject) => {
        insuranceService
            .createInsurance(payload)
            .then(response => {
                dispatch({
                    type: INSURANCE.CREATE_INSURANCE_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {});
    });
};

export const updateInsurance = payload => dispatch => {
    return new Promise((resolve, reject) => {
        insuranceService
            .updateInsurance(payload)
            .then(response => {
                dispatch({
                    type: INSURANCE.UPDATE_INSURANCE_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {});
    });
};
