import React from "react";
import { bindActionCreators } from "redux";
import { createFacility, updateFacility } from "../../actions/facility";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createNotification } from "../../utils/notificationManager";
import {APP_ROUTES, LOCAL_STORAGE_KEYS, NOTIFICATION_TYPES} from "../../constants/common";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { facilityService } from '../../services/facilities';

const REQUIRED_FIELDS = {
    FACILITY_NAME: 'facilityName',
};
class ManageFacility extends React.Component {

    state = {
        errors: [],
        facilityName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        contactName: '',
        contactPhone: '',
        contactFax: '',
        contactEmail: '',
        gpo: '',
        idn: '',
        npi: '',
        internalIdentifier: '',
        salesTax: '',
        caseCapacity: '',
        confirmationEmailAddresses: '',
        type: '',
        financialClearance: 'no',
        emrAccess: 'NA',
        placeOfService: 'No Default POS',
        additionalContacts: '',
    }

    componentDidMount() {
        const facilityId = this.props.location.state ? this.props.location.state.facilityId : '';
        if (facilityId) {
            facilityService
                .getFacilityDetail(facilityId)
                .then(response => {
                    this.setState({
                        facilityName: response.data.facility_name,
                        address1: response.data.address,
                        address2: response.data.address2,
                        city: response.data.city,
                        state: response.data.state,
                        zip: response.data.zip_code,
                        contactName: response.data.contact_name,
                        contactEmail: response.data.contact_email,
                        contactPhone: response.data.contact_phone,
                        contactFax: response.data.contact_fax,
                        gpo: response.data.gpo,
                        idn: response.data.idn,
                        npi: response.data.npi,
                        internalIdentifier: response.data.Internal_Identifier,
                        salesTax: response.data.children ? response.data.children.tax_percent : '',
                        caseCapacity: response.data.children ? response.data.children.case_capacity : '',
                        confirmationEmailAddresses: response.data.children ? response.data.children.day_confirm_email : '',
                        type: response.data.children ? response.data.children.facility_type : '',
                        financialClearance: response.data.children ? response.data.children.financial_clearance_facility : '',
                        emrAccess: response.data.children ? response.data.children.facility_emr_access : '',
                        placeOfService: response.data.children ? response.data.children.facility_place_of_Service : '',
                        additionalContacts: response.data.children ? response.data.children.facility_additional_contact : '',
                    });
                })
        }
    }

    hasErrors = (field) => {
        return this.state.errors.includes(field);
    };

    resetErrors = (field) => {
        this.setState({
            errors: this.state.errors.filter(fieldName => fieldName !== field)
        });
    }

    formatPhone = (str) => {
        if (str) {
            let phone = '';
            str = str.replace(/\D/g, '').substring(0,10);
            if (str.length < 3) {
                phone += str;
            } else if (str.length < 6) {
                phone += str.substring(0, 3) + '-';
                if (str.length > 3) {
                    phone += str.substring(3, str.length);
                }
            } else {
                phone += str.substring(0, 3) + '-' + str.substring(3, 6) + '-' + str.substring(6, 10);
            }
            return phone;
        }
        return str;
    };

    validateActivity = () => {
        const {
            facilityName,
            errors,
        } = this.state;

        let isValid = true;

        if (facilityName.length === 0) {
            this.setState({errors: [...errors, REQUIRED_FIELDS.FACILITY_NAME]});
            isValid = false;
        }

        if (!isValid) {
            createNotification(NOTIFICATION_TYPES.ERROR, 'Please select required values');
        }

        return isValid;
    };

    submitFacility = () => {
        if (!this.validateActivity()) {
            return;
        }

        const { createFacility, updateFacility } = this.props;
        const facilityId = this.props.location.state ? this.props.location.state.facilityId : '';

        const {
            facilityName,
            address1,
            address2,
            city,
            state,
            zip,
            contactName,
            contactPhone,
            contactFax,
            contactEmail,
            gpo,
            idn,
            npi,
            internalIdentifier,
            salesTax,
            caseCapacity,
            confirmationEmailAddresses,
            type,
            financialClearance,
            emrAccess,
            placeOfService,
            additionalContacts,
        } = this.state;

        const request = {
            facility_name: facilityName,
            address: address1,
            address2: address2,
            city: city,
            state: state,
            zip_code: zip,
            contact_name: contactName,
            contact_email: contactEmail,
            contact_phone: contactPhone,
            contact_fax: contactFax,
            gpo: gpo,
            idn: idn,
            npi: npi,
            tax_percent: salesTax,
            case_capacity: caseCapacity,
            day_confirm_email: confirmationEmailAddresses,
            facility_type: type,
            financial_clearance_facility: financialClearance,
            facility_emr_access: emrAccess,
            facility_place_of_Service: placeOfService,
            facility_additional_contact: additionalContacts,
            Internal_Identifier: internalIdentifier,
        };
        console.log(request);
        if (facilityId) {
            const payload = {...request, 'id': facilityId,'api_type':'react'};
            updateFacility(payload)
                .then(res => {
                    if (res.response.data.success) {
                        this.props.history.push(APP_ROUTES.FACILITIES_DASHBOARD);
                    } else {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                        this.props.history.push(APP_ROUTES.FACILITIES_DASHBOARD);
                    }
                })
                .catch(err => {
                    createNotification(NOTIFICATION_TYPES.ERROR, `Unable to update facility ${err.message}`);
                });
        } else {
            createFacility(request)
                .then(res => {
                    if (res.response.data.success) {
                        this.props.history.push(APP_ROUTES.FACILITIES_DASHBOARD);
                    } else {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                        this.props.history.push(APP_ROUTES.FACILITIES_DASHBOARD);
                    }
                })
                .catch(err => {
                    createNotification(NOTIFICATION_TYPES.ERROR, `Unable to create facility ${err.message}`);
                });
        }
    };

    render () {
        const facilityId = this.props.location.state ? this.props.location.state.facilityId : '';
        const activePage = this.props.location.state ? this.props.location.state.activePage : '';
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar activePage={activePage}/>
                </div>
                <TopNavbar />
                <div className="manage-activity-right-col">
                    <div className="activity-row">
                        <div className="page-title">
                            {facilityId &&
                            <h2 className="heading-custom">Edit Facility</h2>
                            }
                            {!facilityId &&
                            <h2 className="heading-custom">Add Facility</h2>
                            }
                        </div>
                        <div className="col-md-4 mrgn-btm10">
                            <label>Facility Name <span className="required">*</span></label>
                            <input
                                type="text"
                                className={this.hasErrors(REQUIRED_FIELDS.FACILITY_NAME) ? 'error code-input' : 'code-input'}
                                value={this.state.facilityName}
                                onChange={event => {
                                    this.resetErrors(REQUIRED_FIELDS.FACILITY_NAME);
                                    this.setState({ facilityName: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-4 mrgn-btm10">
                            <label>Address Line 1</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.address1}
                                onChange={event => {
                                    this.setState({ address1: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-4 mrgn-btm10">
                            <label>Address Line 2</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.address2}
                                onChange={event => {
                                    this.setState({ address2: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-4 mrgn-btm10">
                            <label>City</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.city}
                                onChange={event => {
                                    this.setState({ city: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-4 mrgn-btm10">
                            <label>State (2 character)</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.state}
                                onChange={event => {
                                    this.setState({ state: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-4 mrgn-btm10">
                            <label>Zip Code(9 digit - No spaces)</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.zip}
                                onChange={event => {
                                    this.setState({ zip: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Main Contact Name</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.contactName}
                                onChange={event => {
                                    this.setState({ contactName: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Main Contact Phone</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.formatPhone(this.state.contactPhone)}
                                onChange={event => {
                                    this.setState({ contactPhone: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Main Contact Fax</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.formatPhone(this.state.contactFax)}
                                onChange={event => {
                                    this.setState({ contactFax: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Main Contact Email</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.contactEmail}
                                onChange={event => {
                                    this.setState({ contactEmail: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>GPO</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.gpo}
                                onChange={event => {
                                    this.setState({ gpo: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>IDN</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.idn}
                                onChange={event => {
                                    this.setState({ idn: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Facility NPI</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.npi}
                                onChange={event => {
                                    this.setState({ npi: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Internal Identifier</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.internalIdentifier}
                                onChange={event => {
                                    this.setState({ internalIdentifier: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Sales Tax Percent</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.salesTax}
                                onChange={event => {
                                    this.setState({ salesTax: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Case Capacity</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.caseCapacity}
                                onChange={event => {
                                    this.setState({ caseCapacity: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Day Confirmation Email Addresses (Comma Separated)</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.confirmationEmailAddresses}
                                onChange={event => {
                                    this.setState({ confirmationEmailAddresses: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Facility Type</label>
                            <select
                                className="filter-input"
                                value={this.state.type}
                                onChange={event => this.setState({ type: event.target.value })}>
                                <option value="">Facility Type</option>
                                <option value="assistedliving">Assisted Living</option>
                                <option value="homehealth">Home Health</option>
                                <option value="hospice">Hospice</option>
                                <option value="hospital">Hospital</option>
                                <option value="nursinghome">Nursing Home</option>
                                <option value="office">Office</option>
                            </select>
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Financial Clearance</label>
                            <select
                                value={this.state.financialClearance}
                                className="filter-input"
                                onChange={event => this.setState({ financialClearance: event.target.value })}>
                                <option value="no">No</option>
                                <option value="yes">Yes</option>
                            </select>
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>EMR Access</label>
                            <select
                                value={this.state.emrAccess}
                                className="filter-input"
                                onChange={event => this.setState({ emrAccess: event.target.value })}>
                                <option value="na">NA</option>
                                <option value="noemr">NO EMR ACCESS</option>
                                <option value="emr">EMR ACCESS</option>
                                <option value="noemrfax">NO EMR ACCESS FAX REQ</option>
                                <option value="noaccess">NO ACCESS</option>
                                <option value="hl7data">HL7 DATA FEED</option>
                            </select>
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Place of Service</label>
                            <select
                                value={this.state.placeOfService}
                                className="filter-input"
                                onChange={event => this.setState({ placeOfService: event.target.value })}>
                                <option value="">Place of Service</option>
                                <option value="nopos">No Default POS</option>
                                <option value="11office">11 Office</option>
                                <option value="21inpatienthospital">21 Inpatient Hospital</option>
                                <option value="22outpatienthospital">22 Outpatient Hospital</option>
                                <option value="23emergencyroom">23 Emergency Room - Hospital</option>
                                <option value="24ambulatorysurgical">24 Ambulatory Surgical Center</option>
                            </select>
                        </div>
                        <div className="col-md-12 mrgn-btm10">
                            <label>Additional Contact(s)</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.additionalContacts}
                                onChange={event => {
                                    this.setState({ additionalContacts: event.target.value })
                                }}
                            />
                        </div>
                    </div>
                    <div className="filter-footer">
                        {facilityId &&
                        <button
                            onClick={this.submitFacility}
                            className="filter-save-button">
                            Update
                        </button>
                        }
                        {!facilityId &&
                        <button
                            onClick={this.submitFacility}
                            className="filter-save-button">
                            Submit
                        </button>
                        }
                        <button
                            onClick={() => { this.props.history.push(APP_ROUTES.FACILITIES_DASHBOARD); }}
                            className="filter-cancel-button">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createFacility,
            updateFacility,
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(ManageFacility));