import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";

import { specialityService } from '../../services/speciality';
import {APP_ROUTES, NOTIFICATION_TYPES, RECORDS_PER_PAGE} from "../../constants/common";
import {AsyncPaginate} from "react-select-async-paginate";
import {createNotification} from "../../utils/notificationManager";

import { createSurgeon, updateSurgeon } from '../../actions/surgeons';
import {surgeonService} from "../../services/surgeons";

const REQUIRED_FIELDS = {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    NPI: 'npi',
};

class ManageSurgeons extends React.Component {

    state = {
        errors: [],
        firstName: '',
        lastName: '',
        middleName: '',
        suffix: '',
        npi: '',
        designation: '',
        phone: '',
        city: '',
        address: '',
        state: '',
        zip: '',
        fax: '',
        additionalContactDetails: '',
        primaryTaxonomy: '',
        secondaryTaxonomy: '',
        financialClearance: '',
        license: '',
        primarySpeciality: {},
        secondarySpeciality: {},
        billingNotes: '',
    };

    componentDidMount() {
        const surgeonId = this.props.location.state ? this.props.location.state.surgeonId : '';
        if (surgeonId) {
            surgeonService
                .getSurgeonDetail(surgeonId)
                .then(response => {
                    this.setState({
                        firstName: response.data.first_name,
                        lastName: response.data.last_name,
                        middleName: response.data.mi,
                        suffix: response.data.suffix,
                        designation: response.data.designation,
                        npi: response.data.npi,
                        phone: response.data.phone,
                        city: response.data.city,
                        address: response.data.address,
                        state: response.data.state,
                        zip: response.data.zip,
                        fax: response.data.fax,
                        additionalContactDetails: response.data.additional_contact,
                        primaryTaxonomy: response.data.primary_taxonomy,
                        secondaryTaxonomy: response.data.secondry_taxonomy,
                        financialClearance: response.data.financial_clearance,
                        license: response.data.license,
                        primarySpeciality: {
                            label: response.data.primary_speciality_name ,
                            value: parseInt(response.data.primary_specialty) ,
                        },
                        secondarySpeciality: {
                            label: response.data.sub_speciality ,
                            value: parseInt(response.data.sub_specialty) ,
                        },
                        billingNotes: response.data.billing_notes,
                    });
                })
        }
    }

    hasErrors = (field) => {
        return this.state.errors.includes(field);
    };

    resetErrors = (field) => {
        this.setState({
            errors: this.state.errors.filter(fieldName => fieldName !== field)
        });
    }

    formatPhone = (str) => {
        if (str) {
            let phone = '';
            str = str.replace(/\D/g, '').substring(0,10);
            if (str.length < 3) {
                phone += str;
            } else if (str.length < 6) {
                phone += str.substring(0, 3) + '-';
                if (str.length > 3) {
                    phone += str.substring(3, str.length);
                }
            } else {
                phone += str.substring(0, 3) + '-' + str.substring(3, 6) + '-' + str.substring(6, 10);
            }
            return phone;
        }
        return str;
    };

    loadSpeciality = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        return specialityService
            .getSpecialities({ params: options })
            .then(response => {
                const options = response.data.map(speciality => (
                    {
                        value: speciality.id,
                        label: speciality.name,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    validateActivity = () => {
        const {
            firstName,
            lastName,
            npi,
            errors,
        } = this.state;

        let isValid = true;

        if (firstName.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.FIRST_NAME] });
            isValid = false;
        } else if (lastName.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.LAST_NAME] });
            isValid = false;
        } else if (npi.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.NPI] });
            isValid = false;
        }

        if (!isValid) {
            createNotification(NOTIFICATION_TYPES.ERROR, 'Please select required values');
        }

        return isValid;
    };

    submitSurgeon = () => {
        if (!this.validateActivity()) {
            return;
        }

        const { createSurgeon, updateSurgeon } = this.props;
        const {
            firstName,
            lastName,
            middleName,
            suffix,
            primarySpeciality,
            secondarySpeciality,
            additionalContactDetails,
            address,
            city,
            state,
            zip,
            phone,
            fax,
            npi,
            primaryTaxonomy,
            secondaryTaxonomy,
            billingNotes,
            designation,
            license,
            financialClearance,
        } = this.state;

        const request = {
            first_name: firstName,
            last_name: lastName,
            mi: middleName,
            suffix,
            primary_specialty: primarySpeciality.value,
            address,
            city,
            state,
            zip,
            phone,
            fax,
            npi,
            primary_taxonomy: primaryTaxonomy,
            secondry_taxonomy: secondaryTaxonomy,
            sub_specialty: secondarySpeciality.value,
            billing_notes: billingNotes,
            designation,
            license,
            financial_clearance: financialClearance,
            additional_contact: additionalContactDetails,
        };

        const surgeonId = this.props.location.state ? this.props.location.state.surgeonId : '';
        if (surgeonId) {
            const payload = {...request, 'id': surgeonId};
            updateSurgeon(payload)
                .then(res => {
                    if (res.response.success) {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                        this.props.history.push(APP_ROUTES.SURGEONS_DASHBOARD);
                    } else {
                        createNotification(NOTIFICATION_TYPES.ERROR, `Unable to update surgeon ${res.response.message}`);
                    }
                })
                .catch(err => {
                    createNotification(NOTIFICATION_TYPES.ERROR, `Unable to update surgeon ${err.message}`);
                });
        } else {
            createSurgeon(request)
                .then(res => {
                    if (res.response.success) {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                        this.props.history.push(APP_ROUTES.SURGEONS_DASHBOARD);
                    } else {
                        createNotification(NOTIFICATION_TYPES.ERROR, `Unable to create surgeon ${res.response.message}`);
                    }
                })
                .catch(err => {
                    createNotification(NOTIFICATION_TYPES.ERROR, `Unable to create surgeon ${err.message}`);
                });
        }
    };

    render() {
        const surgeonId = this.props.location.state ? this.props.location.state.surgeonId : '';
        const activePage = this.props.location.state ? this.props.location.state.activePage : '';
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar activePage={activePage}/>
                </div>
                <TopNavbar />
                <div className="manage-activity-right-col">
                    <div className="activity-row">
                        <div className="page-title">
                            {surgeonId &&
                                <h2 className="heading-custom">Edit Surgeon</h2>
                            }
                            {!surgeonId &&
                                <h2 className="heading-custom">Add Surgeon</h2>
                            }
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>First Name <span className="required">*</span></label>
                            <input
                                type="text"
                                className={this.hasErrors(REQUIRED_FIELDS.FIRST_NAME) ? 'error code-input' : 'code-input'}
                                value={this.state.firstName}
                                onChange={event => {
                                    this.resetErrors(REQUIRED_FIELDS.FIRST_NAME);
                                    this.setState({ firstName: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Middle Name</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.middleName}
                                onChange={event => {
                                    this.setState({ middleName: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Last Name <span className="required">*</span></label>
                            <input
                                type="text"
                                className={this.hasErrors(REQUIRED_FIELDS.LAST_NAME) ? 'error code-input' : 'code-input'}
                                value={this.state.lastName}
                                onChange={event => {
                                    this.resetErrors(REQUIRED_FIELDS.LAST_NAME);
                                    this.setState({ lastName: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Suffix</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.suffix}
                                onChange={event => {
                                    this.setState({ suffix: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Designation</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.designation}
                                onChange={event => {
                                    this.setState({ designation: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>NPI <span className="required">*</span></label>
                            <input
                                type="text"
                                className={this.hasErrors(REQUIRED_FIELDS.NPI) ? 'error code-input' : 'code-input'}
                                value={this.state.npi}
                                onChange={event => {
                                    this.resetErrors(REQUIRED_FIELDS.NPI);
                                    this.setState({ npi: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Phone</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.formatPhone(this.state.phone)}
                                onChange={event => {
                                    this.setState({phone: event.target.value})
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>City</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.city}
                                onChange={event => {
                                    this.setState({ city: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Address</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.address}
                                onChange={event => {
                                    this.setState({ address: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>State</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.state}
                                onChange={event => {
                                    this.setState({ state: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Zip</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.zip}
                                onChange={event => {
                                    this.setState({ zip: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <label>Fax</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.formatPhone(this.state.fax)}
                                onChange={event => {
                                    this.setState({ fax: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-9 mrgn-btm10">
                            <label>Additional Contact Details</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.additionalContactDetails}
                                onChange={event => {
                                    this.setState({ additionalContactDetails: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-4 mrgn-btm10">
                            <label>Primary Taxonomy</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.primaryTaxonomy}
                                onChange={event => {
                                    this.setState({ primaryTaxonomy: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-4 mrgn-btm10">
                            <label>Secondary Taxonomy</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.secondaryTaxonomy}
                                onChange={event => {
                                    this.setState({ secondaryTaxonomy: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-4 mrgn-btm10">
                            <label>License</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.license}
                                onChange={event => {
                                    this.setState({ license: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-4 mrgn-btm10">
                            <label>Financial Clearance</label>
                            <select
                                value={this.state.financialClearance}
                                className="filter-input"
                                onChange={event => this.setState({ financialClearance: event.target.value })}>
                                <option value="no">No</option>
                                <option value="yes">Yes</option>
                            </select>
                        </div>
                        <div className="col-md-4 mrgn-btm10">
                            <label>Primary Speciality</label>
                            <AsyncPaginate
                                placeholder="-- Select Primary Speciality --"
                                value={this.state.primarySpeciality}
                                loadOptions={this.loadSpeciality}
                                onChange={value => { this.setState({primarySpeciality: value }) }}
                            />
                        </div>
                        <div className="col-md-4 mrgn-btm10">
                            <label>Sub Speciality</label>
                            <AsyncPaginate
                                placeholder="-- Select Sub-Speciality --"
                                value={this.state.secondarySpeciality}
                                loadOptions={this.loadSpeciality}
                                onChange={value => { this.setState({secondarySpeciality: value }) }}
                            />
                        </div>
                        <div className="col-md-12 mrgn-btm10">
                            <label>Special Billing Notes</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.billingNotes}
                                onChange={event => {
                                    this.setState({ billingNotes: event.target.value })
                                }}
                            />
                        </div>
                    </div>
                    <div className="filter-footer">
                        {surgeonId &&
                        <button
                            onClick={this.submitSurgeon}
                            className="filter-save-button">
                            Update
                        </button>
                        }
                        {!surgeonId &&
                        <button
                            onClick={this.submitSurgeon}
                            className="filter-save-button">
                            Submit
                        </button>
                        }
                        <button
                            onClick={() => { this.props.history.push(APP_ROUTES.SURGEONS_DASHBOARD); }}
                            className="filter-cancel-button">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createSurgeon,
            updateSurgeon,
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(ManageSurgeons));